import { useTheme } from "@mui/styles";
import React, { useCallback, useRef } from "react";
import AsyncSelect from "react-select/async";
import hexToRGB from "utils/hexToRGB";

export interface IAcxSelectMultiAsync<M> {
    id: string;
    placeholder?: string;
    defaultValue: M[];
    value: M[];
    valueField: string;
    labelField: string | ((option: M) => string);
    isClearable?: boolean;
    disabled?: boolean;
    noOptionMessage?: string;

    loadOptions: (inputValue: string) => Promise<M[]>;
    onChange: (value?: any) => void;
}

const AcxSelectMultiAsync = <M,>(props: IAcxSelectMultiAsync<M>) => {
    const theme = useTheme();
    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected
                ? theme.palette.primary[500]
                : theme.palette.text.primary,
            backgroundColor: state.isSelected
                ? theme.palette.primary[50]
                : theme.palette.white.main,
            "&:hover": {
                backgroundColor: theme.palette.primary[50],
                color: theme.palette.neutral[600],
            },
            padding: theme.spacing(1),
            borderRadius: "2px",
        }),
        groupHeading: (provided, state) => ({
            // ...provided,
            color: hexToRGB(theme.palette.black.main, 0.7),
            font: theme.typography.fontFamily,
            fontSize: "13px",
            fontWeight: theme.typography.fontWeightBold,
            cursor: "pointer",
        }),
        menu: (provided, state) => ({
            ...provided,
            // width: "300px",
            zIndex: 10,
            padding: "8px",
        }),
        menuPortal: (provided) => ({
            ...provided,
            zIndex: 9999,
        }),
        control: () => ({
            display: "flex",
            cursor: "pointer",
        }),
        singleValue: (provided, state) => {
            const opacity = props.disabled ? 0.5 : 1;
            const transition = "opacity 300ms";
            return { ...provided, opacity, transition };
        },
        dropdownIndicator: (provided, state) => ({
            ...provided,
            color: theme.palette.text.primary,
        }),
        indicatorContainer: (provided, state) => ({
            ...provided,
            padding: "6px",
        }),
        multiValue: (styles, { data }) => ({
            ...styles,
            backgroundColor: hexToRGB(theme.palette.info.main, 0.18),
            // border: `2px dotted ${theme.palette.secondary.main}`,
            fontSize: "13px",
            color: theme.palette.info.main,
        }),
        multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: theme.palette.info.main,
            fontWeight: "bold",
        }),
        container: (provided, state) => ({
            ...provided,
            fontSize: "13px",
            minWidth: "100px",
            width: "100%",
            // maxWidth: props.fullWidth ? "100%" : "336px",
            border: props.disabled ? "none" : "1px solid",
            borderColor: state.isFocused
                ? hexToRGB(theme.palette.primary.main, 0.7)
                : theme.palette.lightgrayBorder.main,
            borderRadius: theme.shape.borderRadius,
            // minHeight: props.containerHeight ?? "32px",
            // height: props.containerHeight ?? "32px",
            backgroundColor: props.disabled
                ? hexToRGB(theme.palette.black.main, 0.1)
                : theme.palette.white.main,
        }),
    };

    const debounceTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

    const loadOptionsDebounced = useCallback(
        (inputValue: string): Promise<M[]> => {
            return new Promise((resolve) => {
                // No need to fetch anything if input is only 1 character
                if (inputValue.length <= 1) {
                    resolve([]); // Resolve immediately with an empty array
                    return;
                }

                // Clear previous debounce timeout
                if (debounceTimeout.current) {
                    clearTimeout(debounceTimeout.current);
                }

                // Set a new debounce timeout with 1-second delay
                debounceTimeout.current = setTimeout(async () => {
                    const loadedOptions = await props.loadOptions(inputValue);
                    resolve(loadedOptions);
                }, 1000);
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props.loadOptions],
    );

    return (
        <AsyncSelect
            isMulti={true}
            allowCreateWhileLoading={false}
            loadOptions={loadOptionsDebounced}
            cacheOptions={true}
            isClearable={true}
            width={"100%"}
            aria-labelledby={props.id + "-label"}
            onChange={props.onChange}
            placeholder={props.placeholder ?? "Search..."}
            defaultValue={props.defaultValue}
            value={props.value}
            styles={{ ...customStyles }}
            menuShouldScrollIntoView
            menuPlacement={"auto"}
            inputId={props.id}
            getOptionValue={(option) => option[props.valueField!]}
            getOptionLabel={(option) => {
                if (typeof props.labelField === "function") {
                    return props.labelField(option);
                } else {
                    return option[props.labelField];
                }
            }}
            isDisabled={props.disabled}
            noOptionsMessage={(inputval) => {
                return props.noOptionMessage ?? "No options";
            }}
        />
    );
};

export default AcxSelectMultiAsync;
