import React, { useState } from "react";
import { Grid, useMediaQuery } from "@mui/material";
import { useStore } from "utils/useStore";
import { observer } from "mobx-react";
import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import SignalsCards from "./SignalsCards";
import AcxDialog from "components/UI/Dialog/AcxDialog";
import AcxDataGrid from "components/UI/AcxDataGrid/AcxDataGrid";
import AcxButton from "components/UI/AcxButton";
import { stringToAllotment } from "components/Admin/AdminUIComponents/AudioFileSamplerV2/Views/RecommendationStep/StartSamplingDrawerContent";
import AudioMetadataModel from "models/AudioMetadataModel";
import SignalsReportStore, {
    LoadDashboards,
    LoadInitSignalsConfigData,
} from "../Store/SignalsReportStore";
import { SignalsDomain } from "../Models/SignalsModels";
import SignalsStore from "../Store/SignalsStore";
import SignalsPageDescription from "./SignalsPageDescription";
import SignalsChartWrapper from "./SignalsChartWrapper";
import AcxSelect from "components/UI/Select/BaseSelectComponents/AcxSelect";
import AcxMainTextField from "components/UI/AcxMainTextField";
import SignalsDrilldownStore from "../Store/SignalsDrilldownStore";
import SignalsHeader from "./SignalsHeader";
import SignalsEmptyDashboard from "./SignalsEmptyDashboard";
import SignalsReportSelectorStore from "../Store/SignalsReportSelectorStore";
import AddReportsDialogContent from "./AddReportsDialogContent";
import AcxLoadingIndicator from "components/UI/AcxLoadingIndicator";
import { useNavigate } from "react-router-dom";
import { toJS } from "mobx";
import AcxToggleButton from "components/UI/AcxToggleButton";
import { usePDF } from "react-to-pdf";
import theme from "Theme/AppTheme";
import TopicsOnTheRise from "./TopicsOnTheRise";

const useStyles = makeStyles((theme: Theme) => ({
    chartsContainer: {
        padding: theme.spacing(2),
        minHeight: "70vh", // prevents brief header stretching visual glitch when no chart data
    },
    deleteButton: {
        color: theme.palette.warning.contrastText,
        backgroundColor: theme.palette.warning.main,
    },
}));

type Props = { domainName: SignalsDomain };

const SignalsBody: React.FC<Props> = observer((props: Props) => {
    const signalsStore = useStore(SignalsStore);
    const signalsReportStore = useStore(SignalsReportStore);
    const signalsDrilldownStore = useStore(SignalsDrilldownStore);
    const signalsReportSelectorStore = useStore(SignalsReportSelectorStore);
    const classes = useStyles(props);

    const isWide = useMediaQuery("(min-width: 1000px)");
    const isThin = useMediaQuery("(max-width: 800px)");

    const { toPDF, targetRef } = usePDF({
        filename:
            signalsReportSelectorStore.savePDFName.split(" ").join("_") +
            ".pdf",
    });

    const navigate = useNavigate();

    const [isCreateAndPin, setIsCreateAndPin] = useState<boolean>(false);

    const handleDrilldownSampling = () => {
        const selectedDrillEddyData =
            signalsDrilldownStore.selectedDataDgStore.selectedRows.map(
                (row) => {
                    const splitFilePath = row.fileName.split("/");
                    const eddyInteractionFilePath =
                        splitFilePath[splitFilePath.length - 1];

                    return {
                        fileName: eddyInteractionFilePath,

                        id: row.audioMetadataId,
                    } as AudioMetadataModel;
                },
            );

        signalsDrilldownStore.recommendationStepStore.tableStore.setItems(
            selectedDrillEddyData,
        );
        signalsDrilldownStore.recommendationStepStore.tableStore.setSelectedItems(
            selectedDrillEddyData.map((row) => row.id),
        );

        const existing = {
            selections: [],
            count: "0",
        };
        existing.count = stringToAllotment(
            signalsDrilldownStore.recommendationStepStore.tableStore.selectedItems.length.toString(),
        );

        signalsDrilldownStore.recommendationStepStore.hierarchyAssignmentDistribution.set(
            0,
            existing,
        );
        signalsDrilldownStore.selectedDataDgStore.setLoading(true);
        signalsDrilldownStore.recommendationStepStore.useSample(false, () => {
            signalsDrilldownStore.onSelect(
                signalsDrilldownStore.currentOnSelectPayload.series,
                signalsDrilldownStore.currentOnSelectPayload.x,
                signalsDrilldownStore.currentOnSelectPayload.y,
                signalsDrilldownStore.currentOnSelectPayload.chartId,
            );
        });

        signalsDrilldownStore.selectedDataDgStore.clearSelected();
        signalsDrilldownStore.recommendationStepStore.tableStore.clearSelectedItems();
    };

    // prevent user from being able to see page if they manually type into the address bar
    if (
        (props.domainName === "trends" &&
            !signalsReportStore.authStore.canUserView("Trends")) ||
        (props.domainName === "topics" &&
            !signalsReportStore.authStore.canUserView("Topics")) ||
        (props.domainName === "contacts" &&
            !signalsReportStore.authStore.canUserView("Contacts"))
    )
        return;

    const handleEmptyRenderData =
        props.domainName === "dashboard" &&
        !signalsReportStore.getTaskLoading(LoadDashboards) &&
        !signalsReportStore.getTaskLoading(LoadInitSignalsConfigData) ? (
            <SignalsEmptyDashboard />
        ) : (
            <AcxLoadingIndicator size={75} alternate="PuffLoader" />
        );

    const newDashVisItems = [
        { value: "User", element: "Only Me" },
        {
            value: "Organization",
            element: "My Organization",
        },
    ];

    return (
        <Grid
            container
            direction="column"
            padding={1}
            gap={1}
            wrap="nowrap"
            style={
                signalsReportStore.isPresentationMode
                    ? {
                          backgroundColor: theme.palette.lightgray.background,
                          position: "absolute",
                          top: 0,
                          paddingLeft: isWide
                              ? theme.spacing(25)
                              : isThin
                              ? theme.spacing(5)
                              : theme.spacing(12.5),
                          paddingRight: isWide
                              ? theme.spacing(25)
                              : isThin
                              ? theme.spacing(5)
                              : theme.spacing(12.5),
                      }
                    : { backgroundColor: theme.palette.lightgray.background }
            }
            ref={targetRef}
        >
            <SignalsHeader
                domainName={props.domainName}
                showFilters={
                    !!signalsReportStore.renderedChartData.length &&
                    !signalsReportStore.isPresentationMode
                }
                toPDF={toPDF}
            />
            <SignalsPageDescription domainName={props.domainName} />

            {!!signalsReportStore.renderedChartData.length &&
                (props.domainName !== "topics" ? (
                    <SignalsCards domainName={props.domainName} />
                ) : (
                    <TopicsOnTheRise />
                ))}

            <Grid
                container
                item
                justifyContent="center"
                alignItems="flex-start"
                className={classes.chartsContainer}
            >
                {signalsReportStore.renderedChartData.length
                    ? signalsReportStore.renderedChartData
                          .filter((chartData) =>
                              signalsStore.canViewChart(chartData.id),
                          )
                          .map((item) => (
                              <SignalsChartWrapper
                                  key={item.id}
                                  {...item}
                                  domainName={props.domainName}
                              />
                          ))
                    : handleEmptyRenderData}
            </Grid>
            <AcxDialog
                title={signalsDrilldownStore.drilldownDialogTitle}
                subTitle={
                    signalsDrilldownStore.drilldownSubtitle
                        ? signalsDrilldownStore.drilldownSubtitle
                        : ""
                }
                dialogContentChildren={
                    <Grid style={{ height: "70vh" }}>
                        <AcxDataGrid
                            dataGridStore={
                                signalsDrilldownStore.selectedDataDgStore
                            }
                        />
                    </Grid>
                }
                isOpen={signalsDrilldownStore.showSelectedDataDialog}
                onClose={() => signalsDrilldownStore.closeDrilldownDialog()}
                contentWidth="80vw"
                maxWidth="xl"
                disableEnforceFocus
                children={
                    signalsDrilldownStore.isTopics ? undefined : (
                        <AcxButton
                            color="primary"
                            onClick={() => handleDrilldownSampling()}
                            disabled={
                                signalsDrilldownStore.selectedDataDgStore
                                    .selectedRows.length === 0 ||
                                signalsDrilldownStore.selectedDataDgStore
                                    .selectedRows.length > 10
                            }
                            containerStyle={{
                                width: "215px",
                            }}
                        >
                            Sample Selected Files
                        </AcxButton>
                    )
                }
                customZIndex={9999999}
                hideCancelButton={signalsDrilldownStore.isTopics ? true : false}
            />
            <AcxDialog
                title={"Add Report(s)"}
                subTitle={`Search to add an existing report to this dashboard.`}
                dialogContentChildren={<AddReportsDialogContent />}
                isOpen={signalsReportSelectorStore.showReportSelector}
                onClose={() => {
                    signalsReportSelectorStore.showReportSelector = false;
                    signalsReportSelectorStore.selectedVizDefs = [];
                }}
                maxWidth="xl"
                disableEnforceFocus
                children={
                    <Grid item container xs={4}>
                        <AcxButton
                            fullWidth
                            color="primary"
                            onClick={async () => {
                                try {
                                    const res =
                                        await signalsReportStore.signalsService.addVizDefinitionsToDashboard(
                                            signalsReportStore.currentDashboard
                                                ?.id ?? "",
                                            signalsReportSelectorStore.selectedVizDefs.map(
                                                (i) => {
                                                    const reqI = i as any;
                                                    reqI.dashboardId =
                                                        signalsReportStore.currentDashboard?.id;

                                                    return toJS(reqI);
                                                },
                                            ),
                                        );

                                    res.forEach((i) => {
                                        if (
                                            !signalsReportStore.renderedChartData
                                                .map((cd) => cd.id)
                                                .includes(i.id)
                                        ) {
                                            const newI =
                                                signalsReportStore.setVizDefDefaults(
                                                    i,
                                                );
                                            signalsReportStore.renderedChartData.push(
                                                newI,
                                            );
                                        }
                                    });

                                    signalsReportStore.messageStore.logMessage(
                                        "Added report(s) to dashboard.",
                                        "success",
                                    );
                                    signalsReportSelectorStore.showReportSelector =
                                        false;
                                } catch (e) {
                                    console.error(JSON.stringify(e));
                                    signalsReportStore.messageStore.logMessage(
                                        "Failed to add reports to dashboard.",
                                        "error",
                                    );
                                } finally {
                                    signalsReportSelectorStore.selectedVizDefs =
                                        [];
                                    await signalsReportStore.refreshReports();
                                }
                            }}
                            disabled={
                                !signalsReportSelectorStore.selectedVizDefs
                                    .length
                            }
                            containerStyle={{
                                width: "215px",
                            }}
                        >
                            Add Report(s) to Dashboard
                        </AcxButton>
                    </Grid>
                }
            />
            <AcxDialog
                title={
                    isCreateAndPin
                        ? "Pin to New Dashboard"
                        : "Create New Dashboard"
                }
                subTitle={
                    isCreateAndPin
                        ? "Create a new dashboard and pin report"
                        : "Create a new dashboard"
                }
                dialogContentChildren={
                    <Grid container style={{ width: "40vh" }} spacing={2}>
                        <Grid item xs={12}>
                            <AcxMainTextField
                                id="new-dashboard-title-input"
                                labelText="Dashboard Name"
                                value={signalsReportStore.newDashboardTitle}
                                onChange={(e) =>
                                    (signalsReportStore.newDashboardTitle =
                                        e.target.value)
                                }
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <AcxToggleButton
                                id="dashboard-visibility-toggle"
                                items={newDashVisItems}
                                defaultValue={
                                    newDashVisItems.find(
                                        (i) =>
                                            i.value ===
                                            signalsReportStore.newDashboardVis,
                                    )?.value
                                }
                                inputLabel="Visibility"
                                onChange={(item) => {
                                    if (!item) return;
                                    signalsReportStore.newDashboardVis = item;
                                }}
                                exclusive
                            />
                        </Grid>
                    </Grid>
                }
                isOpen={signalsReportStore.showCreateDashboard}
                onClose={() => {
                    signalsReportStore.showCreateDashboard = false;
                    setIsCreateAndPin(false);
                }}
                disableEnforceFocus
                hideCancelButton
                alternateActionBackgroundColor
                children={
                    <Grid container item xs={6}>
                        <AcxButton
                            fullWidth
                            color="primary"
                            onClick={async () => {
                                try {
                                    signalsReportStore.showCreateDashboard =
                                        false;
                                    const dashCreateRes =
                                        await signalsReportStore.signalsService.createDashboard(
                                            {
                                                title: signalsReportStore.newDashboardTitle,
                                                visibility:
                                                    signalsReportStore.newDashboardVis,
                                            },
                                        );
                                    if (isCreateAndPin) {
                                        signalsReportStore.dashboardForNewReport =
                                            dashCreateRes;
                                        await signalsReportStore.addToDashboard();
                                    }
                                    await signalsReportStore.refreshDashboards();

                                    signalsReportStore.messageStore.logMessage(
                                        isCreateAndPin
                                            ? "Dashboard created and report pinned to new dashboard."
                                            : "Dashboard created.",
                                        "success",
                                    );
                                    navigate(
                                        "/app/signals/dashboard/" +
                                            dashCreateRes.id,
                                    );
                                } catch (e) {
                                    signalsReportStore.messageStore.logMessage(
                                        "Failed to create dashboard",
                                        "error",
                                    );
                                } finally {
                                    setIsCreateAndPin(false);
                                }
                            }}
                            disabled={!signalsReportStore.newDashboardTitle}
                        >
                            {isCreateAndPin
                                ? "Create and Pin to Dashboard"
                                : "Create New Dashboard"}
                        </AcxButton>
                    </Grid>
                }
            />
            <AcxDialog
                title={"Pin to Dashboard"}
                subTitle={`A copy of this report will be pinned to the selected dashboard and reflect the current selections.`}
                dialogContentChildren={
                    <Grid>
                        <AcxSelect
                            id="dashboard-selector"
                            options={[
                                ...signalsReportStore.allDashboardsUserCanView.filter(
                                    (i) =>
                                        i.userId ===
                                        signalsReportStore.authStore._user
                                            .profile.sub,
                                ),
                                {
                                    title: "+ Add New Dashboard",
                                    id: "add-new-dash",
                                },
                            ]}
                            labelField="title"
                            valueField="id"
                            onChange={(input) => {
                                if (input.id === "add-new-dash") {
                                    setIsCreateAndPin(true);
                                    signalsReportStore.showSelectDashboard =
                                        false;
                                    signalsReportStore.showCreateDashboard =
                                        true;
                                } else {
                                    signalsReportStore.dashboardForNewReport =
                                        input;
                                }
                            }}
                            inputLabel="Dashboard"
                            fullWidth
                            defaultValue={signalsReportStore.allDashboardsUserCanView.find(
                                (i) =>
                                    i.id ===
                                    signalsReportStore.dashboardForNewReport
                                        ?.id,
                            )}
                        />
                    </Grid>
                }
                isOpen={signalsReportStore.showSelectDashboard}
                onClose={() => (signalsReportStore.showSelectDashboard = false)}
                disableEnforceFocus
                children={
                    <Grid container item xs={4}>
                        <AcxButton
                            fullWidth
                            color="primary"
                            onClick={async () => {
                                try {
                                    await signalsReportStore.addToDashboard();
                                    await signalsReportStore.refreshDashboards();
                                    signalsReportStore.showSelectDashboard =
                                        false;

                                    signalsReportStore.messageStore.logMessage(
                                        `Report added to dashboard ${signalsReportStore.dashboardForNewReport?.title}.`,
                                        "success",
                                    );
                                } catch (e) {
                                    signalsReportStore.messageStore.logMessage(
                                        "Failed to add report to dashboard",
                                        "error",
                                    );
                                }
                            }}
                            disabled={!signalsReportStore.dashboardForNewReport}
                            containerStyle={{
                                width: "215px",
                            }}
                        >
                            Pin to Dashboard
                        </AcxButton>
                    </Grid>
                }
                hideCancelButton
            />
        </Grid>
    );
});

export default SignalsBody;
