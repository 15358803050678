import { Grid } from "@mui/material";
import { Observer, observer } from "mobx-react";
import React from "react";
import hexToRGB from "utils/hexToRGB";
import AcxLoadingIndicator from "../AcxLoadingIndicator";
import AcxRecursiveTreeBranch, {
    BranchDataProps,
} from "./AcxRecursiveTreeBranch";
import AcxRecursiveTreeStore, {
    LOAD_TOP_LEVEL,
} from "./Stores/AcxRecursiveTreeStore";

export interface AcxRecursiveTreeProps {
    viewOnly?: boolean;
    store: AcxRecursiveTreeStore;
}

// const useStyles = makeStyles((theme: Theme) => ({
//     container: {
//         backgroundColor: hexToRGB(theme.palette.neutralGray.main, 1),
//         display: "flex",
//         flexDirection: "column",
//         paddingTop: theme.spacing(1),
//         width: "100%",
//     },
// }));

const AcxRecursiveTree: React.FC<AcxRecursiveTreeProps> = observer(
    ({ store, viewOnly }) => {
        const createTree = (branch: BranchDataProps, index: number) => (
            <Observer key={`${branch.id}_${index}`}>
                {() => (
                    <AcxRecursiveTreeBranch
                        viewOnly={viewOnly}
                        currentBranch={branch}
                        store={store}
                    />
                )}
            </Observer>
        );

        return (
            <Grid
                sx={(theme) => ({
                    backgroundColor: hexToRGB(
                        theme.palette.neutralGray.main,
                        1,
                    ),
                    display: "flex",
                    flexDirection: "column",
                    paddingTop: theme.spacing(1),
                    width: "100%",
                })}
            >
                {store.getTaskLoading(LOAD_TOP_LEVEL) && (
                    <AcxLoadingIndicator size={75} alternate="PuffLoader" />
                )}

                {store.topLevelBranches?.map(
                    (branch: BranchDataProps, index: number) => (
                        <Grid key={branch.id} item container>
                            {createTree(branch, index)}
                        </Grid>
                    ),
                )}
            </Grid>
        );
    },
);

export default AcxRecursiveTree;
