import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import ErrorIcon from "@mui/icons-material/Error";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
    Button,
    Card,
    CardContent,
    Collapse,
    Grid,
    Theme,
    Tooltip,
    Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
    EvalLicensedModule,
    EvalStore,
} from "components/Evaluation/Stores/EvalStore";
import ModuleCardFooter from "components/Evaluation/Views/Modules/ModuleCardFooter";
import ModuleCardHeader from "components/Evaluation/Views/Modules/ModuleCardHeader";
import ModuleDisputedMessage from "components/Evaluation/Views/Modules/ModuleDisputedMessage";
import QuestionAnswerList from "components/Evaluation/Views/Modules/QuestionAnswerList";
import { observer } from "mobx-react";
import { AssignedWorkflowInstance } from "models/Workflows/WorkflowInstance";
import React, { CSSProperties } from "react";
import theme from "Theme/AppTheme";
import { parseFromISO, standardDateFormat } from "utils/DateTimeUtils";
import { useStore } from "utils/useStore";
import AcxButton from "../AcxButton";
import AcxMainTextField from "../AcxMainTextField";

export interface NonBossLevelApproveRejectModuleCardProps {
    title: string;
    reviewed?: "approve" | "reject";
    level?: number;
    lineClamp?: number;
    reviewerName?: string;
    verifiedDate?: string;
    fullWidth?: boolean;
    subContent?: string;
    customSubTitle?: string;
    customCollapseContent?: any;
    onConfirm?: () => void;
    onReject?: () => void;
    onNoteChange?: (arg?: string) => void;
    confirmationText?: string;
    rejectionText?: string;
    reviewerNotes?: string;
    customRootStyles?: CSSProperties;
    isOpen?: boolean;
    module?: EvalLicensedModule;
}

const useStyles = makeStyles((theme: Theme) => ({
    cardWrapper: (props: NonBossLevelApproveRejectModuleCardProps) => ({
        width: props.fullWidth ? "100%" : "",
        height: "min-content",
        marginBottom: "1.25rem",
    }),
    cardContent: {
        padding: "1rem",
        paddingBottom: "0.75rem !important",
    },
    banner: (props: NonBossLevelApproveRejectModuleCardProps) => ({
        height: "0.75rem",
        backgroundColor:
            props.reviewed === "approve"
                ? theme.palette.green.main
                : props.reviewed === "reject"
                ? theme.palette.red.main
                : theme.palette.blue.main,
    }),
    collapseContainer: {},
    title: (props: NonBossLevelApproveRejectModuleCardProps) => ({
        color:
            props.reviewed === "approve"
                ? theme.palette.green.main
                : props.reviewed === "reject"
                ? theme.palette.red.main
                : theme.palette.blue.main,
    }),
    subTitle: (props: NonBossLevelApproveRejectModuleCardProps) => ({
        color:
            props.reviewed === "approve"
                ? theme.palette.green.main
                : props.reviewed === "reject"
                ? theme.palette.red.main
                : theme.palette.blue.main,
        marginTop: "0.25rem",
        marginBottom: "0.25rem",
    }),
    icon: {
        marginRight: "0.25rem",
        width: "20px",
    },
    toggleOpenButton: {
        borderRadius: "100%",
        height: "30px",
        width: "30px",
        minWidth: "30px",
    },
    verificationStatusText: {
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
        width: "calc(100% - 20px - 0.25rem)",
    },
    actionButtons: {
        textTransform: "none",
    },
    root: {
        paddingBottom: "16px",
        width: "100%",
        height: "auto",
        borderRadius: "8px",
        marginTop: "2.0rem",
        marginBottom: "2.0rem",
    },

    sectionTitle: {
        paddingLeft: theme.spacing(3),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(1),
        marginBottom: "16px",
    },
}));

const NonBossLevelApproveRejectModuleCard = observer(
    (props: NonBossLevelApproveRejectModuleCardProps) => {
        const classes = useStyles(props);
        const store = useStore(EvalStore);
        const [open, toggleOpen] = React.useState<boolean>(
            props.isOpen ?? false,
        );

        const userIsARecipient = store.currentEval?.workflowInstances?.some(
            (instance) =>
                (instance as AssignedWorkflowInstance).assignedToUser &&
                instance.isActive,
        );

        const userHasEditPerms = store.authStore.canUserEdit("Workflows");

        // This may be redundant but will work
        const userCanEdit = userHasEditPerms || userIsARecipient;

        var requiredQuestions = props.module
            ? store.isRequiredQuestionsForWorkflowModule(
                  props.module?.evaluationModuleId,
              )
            : false;
        function convertToLocalTimeZone(date: string) {
            return new Date(
                date.indexOf("Z") !== -1
                    ? date.replace("Z", "+00:00")
                    : date + "+00:00",
            )
                .toLocaleString(
                    Intl.DateTimeFormat().resolvedOptions().locale,
                    {
                        timeZone:
                            Intl.DateTimeFormat().resolvedOptions().timeZone,
                    },
                )
                .toString();
        }

        return (
            <Card
                className={classes.cardWrapper}
                style={props.customRootStyles}
            >
                <Grid container item xs={12} className={classes.banner}></Grid>
                <CardContent className={classes.cardContent}>
                    <Grid container item xs={12}>
                        <Grid item xs={12}>
                            <Grid
                                container
                                item
                                xs={12}
                                justifyContent="space-between"
                                alignItems="center"
                                alignContent="center"
                            >
                                <Typography
                                    variant="h2"
                                    component="span"
                                    className={classes.title}
                                >
                                    {props.title}
                                </Typography>

                                {!props.reviewed && (
                                    <>
                                        {open ? (
                                            <Button
                                                className={
                                                    classes.toggleOpenButton
                                                }
                                                onClick={(e) =>
                                                    toggleOpen(false)
                                                }
                                            >
                                                <ExpandLessIcon />
                                            </Button>
                                        ) : (
                                            <Button
                                                className={
                                                    classes.toggleOpenButton
                                                }
                                                onClick={(e) =>
                                                    toggleOpen(true)
                                                }
                                            >
                                                <ExpandMoreIcon />
                                            </Button>
                                        )}
                                    </>
                                )}
                            </Grid>

                            <Typography variant="h3" component="span">
                                {props.reviewed === "approve" ? (
                                    <Grid
                                        container
                                        item
                                        alignContent="center"
                                        alignItems="center"
                                        className={classes.subTitle}
                                    >
                                        <AssignmentTurnedInIcon
                                            className={classes.icon}
                                        />{" "}
                                        <span
                                            className={
                                                classes.verificationStatusText
                                            }
                                        >
                                            <Tooltip
                                                title={`Workflow Continued by ${
                                                    props.reviewerName ??
                                                    "Unknown"
                                                } 
                    ${
                        props.verifiedDate &&
                        `on ${standardDateFormat(
                            parseFromISO(
                                convertToLocalTimeZone(props.verifiedDate),
                                true,
                            ),
                        )}`
                    }`}
                                            >
                                                <span>
                                                    Workflow Continued by{" "}
                                                    {props.reviewerName ??
                                                        "Unknown"}{" "}
                                                    {props.verifiedDate &&
                                                        `on ${standardDateFormat(
                                                            parseFromISO(
                                                                convertToLocalTimeZone(
                                                                    props.verifiedDate,
                                                                ),
                                                                true,
                                                            ),
                                                        )}`}
                                                </span>
                                            </Tooltip>
                                        </span>
                                    </Grid>
                                ) : props.reviewed === "reject" ? (
                                    <Grid
                                        container
                                        item
                                        alignContent="center"
                                        alignItems="center"
                                        className={classes.subTitle}
                                    >
                                        <ErrorIcon className={classes.icon} />{" "}
                                        <span
                                            className={
                                                classes.verificationStatusText
                                            }
                                        >
                                            <Tooltip
                                                title={`Workflow Closed by ${
                                                    props.reviewerName ??
                                                    "Unknown"
                                                } 
                    ${
                        props.verifiedDate &&
                        `on ${standardDateFormat(
                            parseFromISO(
                                convertToLocalTimeZone(props.verifiedDate),
                                true,
                            ),
                        )}`
                    }`}
                                            >
                                                <span>
                                                    Workflow Closed by{" "}
                                                    {props.reviewerName ??
                                                        "Unknown"}{" "}
                                                    {props.verifiedDate &&
                                                        `on ${standardDateFormat(
                                                            parseFromISO(
                                                                convertToLocalTimeZone(
                                                                    props.verifiedDate,
                                                                ),
                                                                true,
                                                            ),
                                                        )}`}
                                                </span>
                                            </Tooltip>
                                        </span>
                                    </Grid>
                                ) : (
                                    <Grid
                                        container
                                        item
                                        alignContent="center"
                                        alignItems="center"
                                        className={classes.subTitle}
                                    >
                                        <span
                                            className={
                                                classes.verificationStatusText
                                            }
                                        >
                                            <Tooltip
                                                title={
                                                    props.customSubTitle ?? ""
                                                }
                                            >
                                                <span>
                                                    {props?.customSubTitle}
                                                </span>
                                            </Tooltip>
                                        </span>
                                    </Grid>
                                )}
                            </Typography>

                            {props.subContent && (
                                <Typography component="div">
                                    {props.subContent}
                                </Typography>
                            )}
                        </Grid>
                    </Grid>

                    {!props.reviewed ? (
                        <Grid
                            container
                            item
                            xs={12}
                            className={classes.collapseContainer}
                        >
                            <Collapse
                                in={open}
                                timeout={theme.transitions.duration.standard}
                                collapsedSize="0px"
                                style={{ width: "100%" }}
                            >
                                {props.customCollapseContent ? (
                                    props.customCollapseContent
                                ) : (
                                    <Grid container item xs={12}>
                                        <Grid
                                            item
                                            xs={12}
                                            style={{ marginBottom: "0.5rem" }}
                                        >
                                            {props.module ? (
                                                <Grid
                                                    container
                                                    direction="column"
                                                    justifyContent="flex-start"
                                                    alignItems="stretch"
                                                >
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        className={
                                                            classes.sectionTitle
                                                        }
                                                    >
                                                        <Typography
                                                            variant={"h1"}
                                                        >
                                                            {props.module?.name}
                                                        </Typography>
                                                    </Grid>
                                                    <ModuleCardHeader
                                                        module={props.module}
                                                    />
                                                    <ModuleDisputedMessage
                                                        hasDisputedAnswers={
                                                            props.module
                                                                .hasDisputedAnswers
                                                        }
                                                        evaluationStatus={
                                                            store.currentEval
                                                                ?.evaluationStatus
                                                        }
                                                        canChangeDisputedAnswers={
                                                            store.currentEval
                                                                ?.canChangeDisputedAnswers
                                                        }
                                                        isEvalEditable={
                                                            store.currentEval
                                                                ?.isEditable
                                                        }
                                                    />
                                                    <QuestionAnswerList
                                                        store={store}
                                                        module={props.module}
                                                        isEditable={userCanEdit}
                                                    />
                                                    <ModuleCardFooter
                                                        module={props.module}
                                                    />
                                                </Grid>
                                            ) : (
                                                <AcxMainTextField
                                                    id={"reviewer-notes"}
                                                    rows={3}
                                                    rowsMax={5}
                                                    labelText={"Add A Note"}
                                                    multiline
                                                    value={
                                                        props.reviewerNotes ??
                                                        ""
                                                    }
                                                    onChange={(evt) =>
                                                        props.onNoteChange?.(
                                                            evt.target.value,
                                                        ) as any
                                                    }
                                                    textareaRootStyles={{
                                                        marginRight: "0px",
                                                    }}
                                                />
                                            )}
                                        </Grid>

                                        <Grid
                                            container
                                            item
                                            xs={12}
                                            justifyContent="flex-end"
                                        >
                                            <AcxButton
                                                disabled={
                                                    requiredQuestions ||
                                                    !userCanEdit
                                                }
                                                tooltip={
                                                    requiredQuestions
                                                        ? "Required questions must be answered before module can be completed"
                                                        : undefined
                                                }
                                                color="primary"
                                                onClick={props.onConfirm}
                                            >
                                                {props.confirmationText ??
                                                    "Approve"}
                                            </AcxButton>

                                            <AcxButton
                                                disabled={
                                                    requiredQuestions ||
                                                    !userCanEdit
                                                }
                                                tooltip={
                                                    requiredQuestions
                                                        ? "Required questions must be answered before module can be completed"
                                                        : undefined
                                                }
                                                //
                                                onClick={props.onReject}
                                                style={{
                                                    marginLeft: "0.5rem",
                                                    backgroundColor: "#F55656",
                                                }}
                                            >
                                                {props.rejectionText ??
                                                    "Reject"}
                                            </AcxButton>
                                        </Grid>
                                    </Grid>
                                )}
                            </Collapse>
                        </Grid>
                    ) : (
                        props.module && (
                            <Grid
                                container
                                direction="column"
                                justifyContent="flex-start"
                                alignItems="stretch"
                            >
                                <Grid
                                    item
                                    xs={12}
                                    className={classes.sectionTitle}
                                >
                                    <Typography variant={"h1"}>
                                        {props.module?.name}
                                    </Typography>
                                </Grid>
                                <ModuleCardHeader module={props.module} />
                                <ModuleDisputedMessage
                                    hasDisputedAnswers={
                                        props.module.hasDisputedAnswers
                                    }
                                    evaluationStatus={
                                        store.currentEval?.evaluationStatus
                                    }
                                    canChangeDisputedAnswers={
                                        store.currentEval
                                            ?.canChangeDisputedAnswers
                                    }
                                    isEvalEditable={
                                        store.currentEval?.isEditable
                                    }
                                />
                                <QuestionAnswerList
                                    store={store}
                                    module={props.module}
                                    isEditable={false}
                                />
                                <ModuleCardFooter module={props.module} />
                            </Grid>
                        )
                    )}
                </CardContent>
            </Card>
        );
    },
);

export default NonBossLevelApproveRejectModuleCard;
