import {
    Checkbox,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    Theme,
    Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import AcxAnswerSelector from "components/UI/AcxAnswerSelector";
import AcxWideTooltip from "components/UI/AcxWideTooltip";
import { observer } from "mobx-react";
import { Answer } from "models/Answer";
import Question, { QuestionType } from "models/Question";
import { Tag } from "models/Tag";
import React from "react";
import sanitizeHtml from "sanitize-html";
import InfoSvg from "SvgIcons/InfoSvg";
import theme from "Theme/AppTheme";
import { useStore } from "utils/useStore";
import ConvoWorkflowStore from "../store/ConvoWorkflowStore";
import ConvoNote from "./ConvoNote";
import { ConvoModuleUIModel } from "./ConvoModuleUIModel";

const useStyles = makeStyles((theme: Theme) => ({
    root: {},

    padding3: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },

    questionText: (props: Props) => ({
        color: "#1F1F1F",
        fontSize: "14px",
        fontWeight: "bold",
        letterSpacing: "0",
        lineHeight: "20px",
    }),

    fullSize: {
        height: "100%",
        width: "100%",
    },
    questionHeader: (props: Props) => ({
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(2),
    }),
    infoSVG: {
        marginRight: theme.spacing(2.7),
        paddingTop: theme.spacing(1),
        marginBottom: theme.spacing(0.5),
    },
    sPContainer: {
        display: "flex",
        flexDirection: "row",
        wrap: "nowrap",
        alignItems: "center",
    },
}));

interface OwnProps {
    question: Question;
    // answer?: Answer;
    moduleUIModel?: ConvoModuleUIModel;
    leftBorderColor?: string;
    isDisabled?: boolean;
    // showDisputeCheckbox?: boolean;
    // answerDisputes?: AnswerDispute[];
}

type Props = OwnProps;

const ConvoScaledResponseAnswer = observer((props: Props) => {
    const classes = useStyles(props);
    const store = useStore(ConvoWorkflowStore);

    const answer = store.getAnswerForQuestion(
        props.question,
        props.moduleUIModel?.conversationModuleId,
    );

    const activeTag =
        (answer?.activeTags.length ?? 0) > 0
            ? answer?.activeTags[0]
            : undefined;

    // const isSmartPredicted = answer?.hasAccuratePredictions;

    // const [showNote, setShowNote] = useState<boolean>(Boolean(answer?.hasNote));
    // const [showQuote, setShowQuote] = useState<boolean>(
    //     Boolean(answer?.hasClips),
    // );
    // const [flagActive, setFlagActive] = useState<boolean>(
    //     answer?.answerAlert === AnswerAlert.PositiveAlert ||
    //         answer?.answerAlert === AnswerAlert.NegativeAlert,
    // );

    // const isPositiveSentimentAnswer = activeTag?.isAnswerPostiveSentiment;
    // const isNegativeSentimentAnswer = activeTag?.isAnswerNegativeSentiment;

    // const isNeedsAttn = answer?.isNeedsAttention;
    // const isExemplary = answer?.isExemplary;

    // function toggleNoteField() {
    //     setShowNote((prevState) => !prevState);
    // }

    // function toggleQuoteField() {
    //     setShowQuote((prevState) => !prevState);
    // }

    // const toggleAnswerAlert = () => {
    //     if (flagActive === true) {
    //         setFlagActive(false);
    //         if (activeTag && answer) answer.answerAlert = null;
    //     } else {
    //         if (activeTag && answer) {
    //             setFlagActive(true);
    //             if (
    //                 activeTag.sentiment === TagSentiment.Neutral ||
    //                 activeTag.sentiment === TagSentiment.Positive
    //             ) {
    //                 answer.answerAlert = AnswerAlert.PositiveAlert;
    //             } else {
    //                 answer.answerAlert = AnswerAlert.NegativeAlert;
    //             }
    //         }
    //     }
    // };

    // const bgColor = showNote || showQuote ? "unset" : "unset";

    // useEffect(() => {
    //     setShowQuote(!!answer?.hasClips);
    // }, [
    //     answer?.answerAlert,
    //     answer?.soundClipAnswers,
    //     answer?.hasClips,
    //     answer?.hasNote,
    // ]);

    // useEffect(() => {
    //     if (activeTag && answer && flagActive) {
    //         if (
    //             activeTag.sentiment === TagSentiment.Neutral ||
    //             activeTag.sentiment === TagSentiment.Positive
    //         ) {
    //             answer.answerAlert = AnswerAlert.PositiveAlert;
    //         } else {
    //             answer.answerAlert = AnswerAlert.NegativeAlert;
    //         }
    //     }
    // }, [activeTag, answer, flagActive]);

    // const [hasDispute, setHasDispute] = useState<boolean>(false);

    // let showAIExplanationCard = store.showAIExplanation[props.question.id];

    // useEffect(() => {
    //     props.moduleUIModel?.updateModuleScore(
    //         props.question,
    //         activeTag === undefined ? [] : [activeTag],
    //     );
    //     const checkDispute = !!props.answerDisputes?.length;
    //     setHasDispute(checkDispute);
    // }, [props.question, props.moduleUIModel, activeTag, props.answerDisputes]);

    return (
        <Grid
            id={`question-${props.question.id}`}
            item
            xs={12}
            style={{
                // backgroundColor: "rgba(254, 242, 242, 0.50)",
                border: "1px solid transparent",
                borderRadius: "4px",
                marginBottom: "24px",
                paddingLeft:
                    props.question.parentId || props.leftBorderColor
                        ? "16px"
                        : "0px",
                borderLeft: props.leftBorderColor
                    ? `2px solid ${props.leftBorderColor}`
                    : "none",
            }}
            className={classes.root}
            container
            direction={"column"}
            justifyContent={"flex-start"}
            alignItems="stretch"
        >
            <Grid
                item
                xs={12}
                container
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"center"}
                wrap={"nowrap"}
                className={clsx(classes.questionHeader, {
                    [classes.padding3]: true,
                })}
            >
                {/* {Boolean(answer && props.showDisputeCheckbox) && (
                    <Checkbox
                        id={"DisputeAnswer-" + props.question.id}
                        title="Check to Dispute this Answer."
                        checked={answer?.isDisputed ? true : false}
                        onClick={answer?.toggleIsDisputed}
                    />
                )} */}
                <AcxWideTooltip
                    title={
                        sanitizeHtml(props.question.helperText) ? (
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: sanitizeHtml(
                                        props.question.helperText,
                                        {
                                            allowedAttributes: {
                                                table: ["style"],
                                                th: ["style"],
                                                td: ["style"],
                                            },
                                            allowedStyles: {
                                                "*": {
                                                    // Match any number with px, em, or %
                                                    width: [/^\d+(?:px|em|%)$/],
                                                    border: [
                                                        /^1px solid rgba\(0, 0, 0, 1\)$/,
                                                    ],
                                                },
                                            },
                                        },
                                    ),
                                }}
                            />
                        ) : (
                            ""
                        )
                    }
                    placement={"top"}
                >
                    <Grid item className={classes.infoSVG}>
                        <InfoSvg width="24px" height="24px" />
                    </Grid>
                </AcxWideTooltip>
                <Grid item xs={10}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={1}
                    >
                        <Grid item>
                            <Typography
                                className={classes.questionText}
                                style={{
                                    color: answer?.isDisputed
                                        ? "theme.palette.error.main"
                                        : "theme.palette.text",
                                }}
                            >
                                {/* {answer?.isDisputed && (
                                    <span
                                        style={{
                                            color: theme.palette.error.main,
                                        }}
                                        title="This answer has been escalated or disputed."
                                    >
                                        {props.question?.questionText}{" "}
                                    </span>
                                )} */}
                                {
                                    // !answer?.isDisputed &&
                                    props.question?.questionText
                                }
                                {props.question.required &&
                                    !answer?.isAnswered && (
                                        <span
                                            style={{
                                                color: theme.palette.error.main,
                                            }}
                                        >
                                            *
                                        </span>
                                    )}
                            </Typography>
                        </Grid>
                        {/* <SmartPredictToggle
                            question={props.question}
                            moduleUIModel={props.moduleUIModel}
                        /> */}
                    </Grid>
                </Grid>
            </Grid>
            {props.question.type !== QuestionType.QuestionHeading && (
                <Grid
                    item
                    xs={12}
                    direction={"row"}
                    wrap={"nowrap"}
                    container
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                    className={clsx(
                        { [classes.padding3]: true },
                        classes.fullSize,
                    )}
                >
                    <Grid item xs={12}>
                        <AnswerSelectorComponent
                            question={props.question}
                            store={store}
                            answer={answer}
                            activeTag={activeTag}
                            moduleUIModel={props.moduleUIModel}
                            leftBorderColor={props.leftBorderColor}
                            isDisabled={props.isDisabled}
                        />
                    </Grid>
                    {/* {!props.standalone && (
                        <Grid
                            item
                            xs={2}
                            container
                            justifyContent="space-evenly"
                            wrap={"nowrap"}
                        >
                            {props.moduleUIModel?.isAlertableAnswer(
                                props.question.id,
                            ) &&
                                (isPositiveSentimentAnswer ||
                                    isNegativeSentimentAnswer) && (
                                    <Grid
                                        item
                                        xs={12}
                                        lg={3}
                                        style={{
                                            position: "relative",
                                            top: -8,
                                        }}
                                    >
                                        {isPositiveSentimentAnswer ? (
                                            <IconButton
                                                onClick={
                                                    answer?.togglePositiveAlert
                                                }
                                                size="large"
                                            >
                                                <ThumbsUpSvg
                                                    color={
                                                        isExemplary
                                                            ? theme.palette
                                                                  .success.main
                                                            : undefined
                                                    }
                                                    opacity={
                                                        isExemplary
                                                            ? 1
                                                            : undefined
                                                    }
                                                />
                                            </IconButton>
                                        ) : isNegativeSentimentAnswer ? (
                                            <IconButton
                                                onClick={
                                                    answer?.toggleNegativeAlert
                                                }
                                                size="large"
                                            >
                                                <AlertSvg
                                                    color={
                                                        isNeedsAttn
                                                            ? theme.palette.red
                                                                  .main
                                                            : undefined
                                                    }
                                                    opacity={
                                                        isNeedsAttn
                                                            ? 1
                                                            : undefined
                                                    }
                                                />
                                            </IconButton>
                                        ) : null}
                                    </Grid>
                                )}
                            <QuoteNoteButtonContainer
                                interactionType={
                                    store.currentEval?.interaction
                                        ?.interactionType
                                }
                                toggleNote={toggleNoteField}
                                showNote={showNote}
                                toggleQuote={toggleQuoteField}
                                showQuote={showQuote}
                                toggleAnswerAlert={toggleAnswerAlert}
                                flagActive={flagActive}
                                isDisabled={props.isDisabled}
                                restrictMedia={
                                    !store.authStore.isUserUltra() &&
                                    store.authStore.canUserView(
                                        "Restrict Media",
                                    )
                                }
                            />
                        </Grid>
                    )} */}
                </Grid>
            )}

            <ConvoNote
                question={props.question}
                answer={answer}
                showNote={true}
                showQuote={false}
                noteXs={12}
                columnDirection={"column"}
                isDisabled={props.isDisabled}
                conversationModuleId={props.moduleUIModel?.conversationModuleId}
            />

            {/* {hasDispute &&
                props.answerDisputes?.map((dispute) => {
                    return (
                        <DisputeNote
                            key={dispute.answerId}
                            disputeNote={dispute.disputeJustification}
                        />
                    );
                })} */}
            {/* {isSmartPredicted && showAIExplanationCard && activeTag && (
                <AIExplanationCards
                    key={answer.questionId}
                    activeTags={[activeTag]}
                    predictedTags={answer.predictedTags}
                    questionId={props.question.id}
                    answer={answer}
                />
            )} */}
        </Grid>
    );
});

const AnswerSelectorComponent = observer(
    (
        props: OwnProps & {
            children?: React.ReactNode;
            store: ConvoWorkflowStore;
            answer: Answer | undefined;
            activeTag: Tag | undefined;
        },
    ) => {
        // const messageStore = props.store.rootStore.getStore(MessageStore);
        const options =
            props.moduleUIModel?.getTagsFormattedForScaledResponse(
                props.question.id,
            ) ?? [];

        const conversationModuleId = props.moduleUIModel?.conversationModuleId;

        if (props.question.answerType.variation === "Checkbox") {
            function onCheckboxAnswerSelect(
                event: React.ChangeEvent<HTMLInputElement>,
            ) {
                const value = event.target.checked;
                const selectedTag = options.find(
                    (value1) =>
                        value1.data.value?.toLowerCase() ===
                        `${value}`.toLowerCase(),
                )?.data;

                if (selectedTag) {
                    props.store.setAnswerForQuestion(
                        props.question,
                        [selectedTag],
                        props.answer,
                        conversationModuleId,
                        // props.store.enableAutoBindClips,
                        // messageStore,
                    );
                } else {
                    props.store.setAnswerForQuestion(
                        props.question,
                        [],
                        props.answer,
                        conversationModuleId,
                        // props.store.enableAutoBindClips,
                        // messageStore,
                    );
                }
            }

            return (
                <>
                    <Checkbox
                        checked={
                            props.activeTag?.value?.toLocaleLowerCase() ===
                                "true" ||
                            props.activeTag?.value?.toLocaleLowerCase() ===
                                "yes"
                        }
                        disabled={props.isDisabled}
                        onChange={onCheckboxAnswerSelect}
                    />
                </>
            );
        } else if (props.question.answerType.variation === "Radio") {
            function onRadioAnswerSelect(
                event: React.ChangeEvent<HTMLInputElement>,
            ) {
                const value = event.target.value;
                const selectedTag = options.find(
                    (value1) =>
                        value1.data.value?.toLowerCase() ===
                        `${value}`.toLowerCase(),
                )?.data;

                if (selectedTag) {
                    props.store.setAnswerForQuestion(
                        props.question,
                        [selectedTag],
                        props.answer,
                        conversationModuleId,
                    );
                } else {
                    props.store.setAnswerForQuestion(
                        props.question,
                        [],
                        props.answer,
                        conversationModuleId,
                    );
                }
            }

            return (
                <RadioGroup
                    row
                    value={props.activeTag?.value ?? null}
                    onChange={onRadioAnswerSelect}
                >
                    {options.map((value) => {
                        return (
                            <FormControlLabel
                                key={value.data.id}
                                value={value.data.value}
                                control={<Radio color="secondary" />}
                                label={value.data.value}
                                labelPlacement="end"
                                disabled={props.isDisabled}
                            />
                        );
                    })}
                </RadioGroup>
            );
        } else {
            function onAnswerSelect(arg: Tag) {
                if (arg === undefined) {
                    props.store.setAnswerForQuestion(
                        props.question,
                        [],
                        props.answer,
                        conversationModuleId,
                    );
                } else {
                    props.store.setAnswerForQuestion(
                        props.question,
                        [arg],
                        props.answer,
                        conversationModuleId,
                    );
                }
            }

            return (
                <>
                    <AcxAnswerSelector
                        options={options}
                        onSelect={onAnswerSelect}
                        defaultValue={props.activeTag}
                        buttonRootStyles={{
                            minHeight: "34px",
                        }}
                        isDisabled={props.isDisabled}
                        isConvoWorkflow
                    />
                </>
            );
        }
    },
);

export default ConvoScaledResponseAnswer;
