import { Grid, Theme, alpha } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Typography } from "@mui/material";
import theme from "Theme/AppTheme";
import AcxCard from "components/UI/Cards/AcxCard";
import { observer } from "mobx-react";
import React from "react";
import { useStore } from "utils/useStore";
import SignalsReportStore, {
    LoadTopicsOnTheRise,
} from "../Store/SignalsReportStore";
import AcxChip from "components/UI/AcxChip";
import TrendingUpRoundedIcon from "@mui/icons-material/TrendingUpRounded";

const useStyles = makeStyles((theme: Theme) => ({
    cardTitleContainer: {
        paddingTop: theme.spacing(2),
    },
    cardsContainer: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(1),
        backgroundColor: theme.palette.primary[800],
        borderRadius: theme.shape.borderRadius,
    },
    topicsHeader: {
        color: theme.palette.white.main,
        paddingLeft: theme.spacing(1),
        fontWeight: 600,
        paddingTop: theme.spacing(1),
        fontSize: "16px",
    },
    trendingIcon: {
        color: theme.palette.success.dark,
        marginRight: theme.spacing(1),
    },
    growthSubtitle: {
        fontSize: "8px",
        fontWeight: 600,
    },
}));

const cardRootStyle: React.CSSProperties = {
    borderRadius: theme.spacing(1),
    boxShadow: `0px 1px 8px 0px ${alpha(theme.palette.black.main, 0.05)}`,
};

const TopicsOnTheRise = observer(() => {
    const store = useStore(SignalsReportStore);
    const classes = useStyles();

    const isLoading = store.getTaskLoading(LoadTopicsOnTheRise);

    return (
        <div
            style={{
                paddingLeft: theme.spacing(2),
                paddingRight: theme.spacing(2),
            }}
        >
            <Grid item container xs={12} className={classes.cardsContainer}>
                <Grid item xs={12} className={classes.topicsHeader}>
                    Topics on the Rise
                </Grid>
                <Grid container spacing={1} wrap="nowrap">
                    {store.topicsOnTheRise.map((i) => {
                        return (
                            <Grid
                                item
                                xs={3}
                                margin={theme.spacing(1)}
                                style={{ zIndex: 1000 }} // set this to avoid card loading indicators appearing on top of header
                            >
                                <AcxCard
                                    loading={isLoading}
                                    loadingSize={24}
                                    title={
                                        <Typography
                                            variant="h2"
                                            fontWeight="normal"
                                            color={theme.palette.gray[300]}
                                            className={
                                                classes.cardTitleContainer
                                            }
                                        >
                                            {isLoading ? " " : i.topicName}
                                        </Typography>
                                    }
                                    rootStyle={cardRootStyle}
                                    mainContentStyle={{
                                        justifyContent: "start",
                                    }}
                                    mainContent={
                                        <Grid
                                            container
                                            item
                                            xs={12}
                                            justifyContent={"space-between"}
                                        >
                                            <Typography
                                                variant="h1"
                                                fontWeight="normal"
                                            >
                                                {i.volume}
                                            </Typography>
                                            <AcxChip
                                                size="small"
                                                color="darkGreen"
                                                label={
                                                    <Grid
                                                        item
                                                        container
                                                        alignItems={"center"}
                                                    >
                                                        <TrendingUpRoundedIcon
                                                            className={
                                                                classes.trendingIcon
                                                            }
                                                        />

                                                        <Grid item>
                                                            {i.percentChange}%{" "}
                                                            <span
                                                                className={
                                                                    classes.growthSubtitle
                                                                }
                                                            >
                                                                GROWTH
                                                            </span>
                                                        </Grid>
                                                    </Grid>
                                                }
                                            />
                                        </Grid>
                                    }
                                />
                            </Grid>
                        );
                    })}
                </Grid>
            </Grid>
        </div>
    );
});

export default TopicsOnTheRise;
