import { Checkbox, Grid, IconButton, Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import ArrowForward from "@mui/icons-material/ArrowForward";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Tier } from "components/Admin/Organizations/types/Tier.type";
import { observer } from "mobx-react";
import React, { BaseSyntheticEvent } from "react";
import useStyles from "Styles/Styles";
import AcxLoadingIndicator from "../AcxLoadingIndicator";
import AcxRecursiveTreeStore from "./Stores/AcxRecursiveTreeStore";

export interface BranchDataProps {
    id: string;
    name: string;
    branchDepth: number;
    isLeaf?: boolean;
    childrenCount?: number;
    parentMemberId?: string; // does not exist on older implementations 10/13, look at getServiceHierarchyLeaf.
    children?: BranchDataProps[];
    hasPerms?: boolean;
}

export type HandleBranchProps = (
    child: BranchDataProps,
    action: "Add" | "Remove" | "Update",
) => void;

export type BranchContentProps = (
    currentBranch: BranchDataProps,
    handleAddBranch: HandleBranchProps,
) => React.ReactNode;

export interface AcxRecursiveTreeBranchProps {
    currentBranch: BranchDataProps;
    store: AcxRecursiveTreeStore;
    parentBranch?: BranchDataProps;
    parentChildHandler?: (children: BranchDataProps[]) => void;
    parentThisBranchHandler?: (branch: BranchDataProps) => void;
    viewOnly?: boolean;
}

const styles = (theme: Theme) =>
    createStyles({
        treeBranch: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            margin: theme.spacing(1),
            marginTop: 0,
            backgroundColor: theme.palette.white.main,
            border: "1px solid #c7c7c7",
            borderRadius: "6px",
            padding: theme.spacing(1),
        },
        children: {
            paddingLeft: "30px",
        },
        iconButton: { padding: 0 },
        checkbox: {
            alignSelf: "flex-end",
            padding: "0px",
        },
        childWarningContainer: {
            borderRadius: "4px",
            backgroundColor: "#DBEAFE",
            padding: "8px",
            maxHeight: "42px",
        },
        warningArrowIcon: {
            color: "#1E3A8A",
            fontSize: "11px",
            paddingRight: "8px",
            paddingTop: "3px",
        },
        childWarningText: {
            color: "#1E3A8A",
            fontSize: "13px",
        },
        childWarningSpacer: {
            margin: "8px",
            marginTop: "0px",
        },
    });

const AcxRecursiveTreeBranch: React.FC<AcxRecursiveTreeBranchProps> = observer(
    ({
        viewOnly,
        currentBranch,
        store,
        parentBranch,
        parentChildHandler,
        parentThisBranchHandler,
    }) => {
        const classes = useStyles(styles);

        const [children, setChildren] = React.useState<BranchDataProps[]>([]);

        const [thisBranch, setThisBranch] =
            React.useState<BranchDataProps>(currentBranch);

        const [isLoading, setIsLoading] = React.useState<boolean>(false);

        const isSelected: boolean = store.selectedBranchIds.includes(
            thisBranch.id,
        );
        const isExpanded: boolean = store.openedBranchIds.includes(
            thisBranch.id,
        );

        const isSelectAllChecked: boolean = store.selectAllCheckedIds.includes(
            thisBranch.id,
        );

        const handleOnCheck = async (event: BaseSyntheticEvent) => {
            if (event.target.checked) {
                store.addSelectedBranchId(thisBranch.id, thisBranch.name);
                if (store.displayChildSelection) {
                    store.setHeaderWarningHasRendered(true);
                    if (!thisBranch.isLeaf && !thisBranch.childrenCount) {
                        // update recursiveChildCount
                        const recursiveChildCount =
                            await store?.getRecursiveChildCount?.(
                                thisBranch.id,
                            );
                        thisBranch.childrenCount = recursiveChildCount;
                        setThisBranch({ ...thisBranch });
                    }
                }
            } else {
                store.removeSelectedBranchId(thisBranch.id, thisBranch.name);
            }
        };

        // "select all children" check
        const handleOnAllChildCheck = (
            event: React.ChangeEvent<HTMLInputElement>,
        ) => {
            let parentBranchId = event.target.id.replace("_checkbox", "");

            if (event.target.checked) {
                store.addSelectAllBranchId(thisBranch.id);

                store.onSelectAllChildren?.(parentBranchId, false);
            } else {
                store.removeSelectAllBranchId(thisBranch.id);

                store.onSelectAllChildren?.(parentBranchId, true);
            }
        };

        const handleOnExpand = async () => {
            if (children.length > 0) {
                store.openedBranchIds = store.openedBranchIds.filter(
                    (id) => id !== thisBranch.id,
                );

                setChildren([]);
            } else {
                setIsLoading(true);

                let branchChildrenRes = await store.getBranchChildren(
                    store.organizationId,
                    thisBranch.id,
                );

                let branchChildren = branchChildrenRes.children;
                thisBranch.childrenCount = branchChildrenRes.rowCount;

                // not used in AcxHierarchySelector
                if (isSelected && store.enableAutomaticChildSelection) {
                    branchChildren.forEach((child) =>
                        store.addSelectedBranchId(child.id, child.name),
                    );
                }

                // TODO: refactor when branch depth is handled on backend
                branchChildren = branchChildren.filter((child) => {
                    if (!child.branchDepth) {
                        child.branchDepth = thisBranch.branchDepth! + 1;
                    }
                    if (child.parentMemberId) {
                        return child.parentMemberId === thisBranch.id;
                    } else {
                        return true;
                    }
                });

                setThisBranch({ ...thisBranch });
                // not used in AcxHierarchySelector?
                if (store.topLevelDataKey) {
                    const updatedBranch = {
                        ...thisBranch,
                        [store.topLevelDataKey]: branchChildren,
                    };

                    setThisBranch(updatedBranch);
                }

                setChildren(branchChildren);
                setIsLoading(false);
            }
        };

        const handleBranch = async (
            item: BranchDataProps,
            action: "Add" | "Remove" | "Update",
        ) => {
            if (action === "Add" && typeof item === "object") {
                if (!store.createBranch) {
                    return;
                }

                const data = await store.createBranch(
                    store.organizationId,
                    thisBranch,
                    { name: item.name } as unknown as Tier,
                );

                if (data && data.parent) {
                    setThisBranch(data.parent);
                }

                if (data && data.child) {
                    setChildren([...children, data.child]);
                }
            } else if (action === "Remove" && typeof item === "object") {
                if (!store.deleteBranch) {
                    return;
                }

                await store.deleteBranch(
                    store.organizationId,
                    item.id,
                    parentBranch,
                );

                if (
                    parentBranch &&
                    parentThisBranchHandler &&
                    parentChildHandler
                ) {
                    if (store.topLevelDataKey) {
                        const childBranches =
                            parentBranch[store.topLevelDataKey];

                        if (childBranches) {
                            const filteredBranches = childBranches.filter(
                                (b) => b.id !== thisBranch.id,
                            );

                            parentChildHandler(filteredBranches);
                            parentThisBranchHandler({
                                ...parentBranch,
                                [store.topLevelDataKey]: filteredBranches,
                            });

                            if (filteredBranches.length === 0) {
                                parentThisBranchHandler({
                                    ...parentBranch,
                                    isLeaf: true,
                                });
                            }
                        }
                    }
                }

                if (!parentBranch) {
                    const updatedTopLevelBranches =
                        store.topLevelBranches?.filter(
                            (branch) => branch.id !== item.id,
                        );

                    if (updatedTopLevelBranches) {
                        store.setTopLevelBranches(updatedTopLevelBranches);
                    }
                }
            } else if (action === "Update" && typeof item === "object") {
                const updatedBranch = await store.updateBranch(
                    store.organizationId,
                    thisBranch.id,
                    item,
                );

                if (updatedBranch) {
                    setThisBranch(updatedBranch);
                }
            }
        };

        const expandButtonIcon =
            children.length !== 0 ? <ExpandMoreIcon /> : <ChevronRightIcon />;

        const arrowDirectionIcon =
            children.length !== 0 ? (
                <ArrowDownward color="primary" fontSize="small" />
            ) : (
                <ArrowForward color="primary" fontSize="small" />
            );

        const renderSelectAllChildren = (
            <Grid item xs={12} sx={{ margin: "8px", marginTop: "0px" }}>
                <Grid
                    item
                    container
                    direction="row"
                    wrap="nowrap"
                    justifyContent="flex-start"
                    alignContent="center"
                    alignItems="center"
                    sx={{
                        borderRadius: "4px",
                        backgroundColor: "#DBEAFE",
                        padding: "8px",
                        maxHeight: "42px",
                    }}
                >
                    <Grid
                        item
                        xs={1}
                        sx={{
                            color: "#1E3A8A",
                            fontSize: "11px",
                            paddingRight: "8px",
                            paddingTop: "3px",
                        }}
                    >
                        {arrowDirectionIcon}
                    </Grid>
                    <Grid container item xs={10} justifyContent="flex-start">
                        <h4 className={classes.childWarningText}>
                            Select All Children{" "}
                            {!!thisBranch.childrenCount &&
                                `( ${thisBranch.childrenCount} )`}
                        </h4>
                    </Grid>
                    <Grid
                        item
                        xs={1}
                        container
                        justifyContent="flex-end"
                        style={{ marginLeft: "auto" }}
                    >
                        <Checkbox
                            onChange={handleOnAllChildCheck}
                            id={`${thisBranch.id}_checkbox`}
                            checked={isSelectAllChecked}
                            className={classes.checkbox}
                        />
                    </Grid>
                </Grid>
            </Grid>
        );

        // This will react off of a cleared openedBranchId to alter the display of the chevron and empty the children for its next GET request.
        React.useEffect(() => {
            if (
                !store.openedBranchIds.includes(thisBranch.id) &&
                children.length > 0
            ) {
                setChildren([]);
            }
        }, [children.length, store.openedBranchIds, thisBranch.id]);

        React.useEffect(() => {
            if (!store.enableAutomaticChildSelection) {
                return;
            }
            if (store.selectedBranchIds.includes(thisBranch.id)) {
                children.forEach((child) => {
                    store.addSelectedBranchId(child.id, child.name);
                });
            } else {
                children.forEach((child) => {
                    store.removeSelectedBranchId(child.id, child.name);
                });
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [isSelected]);

        React.useEffect(() => {
            setChildren(currentBranch?.children ?? []);
        }, [currentBranch.children]);

        return (
            <>
                {thisBranch.id !== "" && (
                    <>
                        <Grid
                            item
                            container
                            xs={12}
                            sx={(theme) => ({
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                margin: theme.spacing(1),
                                marginTop: 0,
                                backgroundColor: theme.palette.white.main,
                                border: "1px solid #c7c7c7",
                                borderRadius: "6px",
                                padding: theme.spacing(1),
                            })}
                            style={
                                store.enableCheckboxSelection
                                    ? {}
                                    : { justifyContent: "space-between" }
                            }
                        >
                            <Grid item container xs={1} justifyContent="center">
                                {thisBranch?.isLeaf ? (
                                    <ExpandMoreIcon
                                        className={classes.iconButton}
                                        style={{ opacity: 0 }}
                                    />
                                ) : (
                                    <IconButton
                                        onClick={handleOnExpand}
                                        className={
                                            store.enableCheckboxSelection
                                                ? classes.iconButton
                                                : ""
                                        }
                                        size="large"
                                    >
                                        {isLoading ? (
                                            <AcxLoadingIndicator
                                                size={24}
                                                alternate="PuffLoader"
                                            />
                                        ) : (
                                            expandButtonIcon
                                        )}
                                    </IconButton>
                                )}
                            </Grid>
                            <Grid
                                item
                                container
                                xs={store.enableCheckboxSelection ? 8 : 11}
                            >
                                {store.branchContent?.(
                                    thisBranch,
                                    handleBranch,
                                )}
                            </Grid>
                            {store.enableCheckboxSelection &&
                                thisBranch.hasPerms && (
                                    <Grid
                                        item
                                        xs={2}
                                        container
                                        justifyContent="flex-end"
                                        style={{ marginLeft: "auto" }}
                                    >
                                        <Checkbox
                                            onChange={handleOnCheck}
                                            id={`${thisBranch.id}_checkbox`}
                                            checked={isSelected}
                                            indeterminate={
                                                store.enableIndeterminateMode
                                            } // TODO: add indeterminate functionality
                                            className={classes.checkbox}
                                            disabled={
                                                store.singleSelect
                                                    ? store.selectedBranchIds
                                                          .length > 0 &&
                                                      !store.selectedBranchIds.includes(
                                                          thisBranch.id,
                                                      )
                                                    : viewOnly
                                            }
                                        />
                                    </Grid>
                                )}
                        </Grid>

                        {/* if is parent, or parent is selected, or parent is expanded */}
                        {store.displayChildSelection &&
                            !viewOnly &&
                            thisBranch &&
                            !thisBranch.isLeaf &&
                            (isSelected || isExpanded) &&
                            renderSelectAllChildren}

                        <Grid
                            className={classes.children}
                            item
                            container
                            xs={12}
                        >
                            {isExpanded &&
                                children.length > 0 &&
                                children.map((child) => (
                                    <AcxRecursiveTreeBranch
                                        key={`${child.id}_${child.name}`}
                                        parentThisBranchHandler={setThisBranch}
                                        parentChildHandler={setChildren}
                                        parentBranch={thisBranch}
                                        currentBranch={child}
                                        viewOnly={viewOnly}
                                        store={store}
                                    />
                                ))}
                        </Grid>
                    </>
                )}
            </>
        );
    },
);

export default AcxRecursiveTreeBranch;
