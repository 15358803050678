import { Theme, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { startCase } from "lodash";
import { observer } from "mobx-react";
import React, { FunctionComponent } from "react";
import { MetaLabels } from "services/MetaLabelService";
import AudioMetadataModel, {
    AudioMetadataStatus,
    UISamplingStatus,
} from "../../../../../models/AudioMetadataModel";
import { InteractionType } from "../../../../../models/InteractionType";
import { TableComponentStore } from "../../../../../stores/ComponentStores/TableComponentStore";
import { isType } from "../../../../../utils/TypeGuards";
import { defaultDurationFormatter } from "../../../../ManagerInteractions/Util";
import AcxControlledTable, {
    CustomControlItem,
} from "../../../../UI/AcxTable/AcxControlledTable";
import { IAcxTableColumn } from "../../../../UI/AcxTable/AcxTable";
import { interactionDateHelper } from "components/UI/AcxDataGrid/ColumnTypes/DateColTypes";
import moment from "moment";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        minHeight: "500px",
        height: "100%",
    },
}));

interface OwnProps {
    title?: React.ReactNode;
    alternateTitle?: React.ReactNode;
    tableStoreFragment: TableComponentStore<AudioMetadataModel>;
    loading: boolean;
    controls?: (CustomControlItem | React.ReactElement)[];
    formatters?: Map<
        string,
        (columnValue: any, row: AudioMetadataModel) => React.ReactNode
    >;
    enableCheck?: boolean;
    enablePaging?: boolean;
    metaLabels?: MetaLabels;
    extendedMetaLabels?: string[];
}

type Props = OwnProps;

const SourceFilesTable: FunctionComponent<Props> = observer((props) => {
    const classes = useStyles();

    const columnDefs = [
        [
            "Name",
            "fileName",
            (fileName) =>
                props.formatters?.["fileName"]?.(fileName) ?? fileName,
        ],
        ["Interaction Date", "timestamp", dateTimeColumnFormatter],
        [
            "Agent",
            "agentName",
            (agentName) =>
                props.formatters?.["agentName"]?.(agentName) ?? agentName,
        ],
        ["Direction", "callDirection"],
        [
            "Duration",
            "callDurationMillis",
            (millis) => defaultDurationFormatter(millis, "milliseconds"),
        ],
        ["Directory", "origDirectoryPath"],
        ["Type", "mediaType", mediaTypeFormatter],
        ["Status", "status", statusFormatter],
        [
            props.metaLabels?.Meta1
                ? startCase(props.metaLabels?.Meta1)
                : "Meta 1",
            "meta1",
        ],
        [
            props.metaLabels?.Meta2
                ? startCase(props.metaLabels?.Meta2)
                : "Meta 2",
            "meta2",
        ],
        [
            props.metaLabels?.Meta3
                ? startCase(props.metaLabels?.Meta3)
                : "Meta 3",
            "meta3",
        ],
        [
            props.metaLabels?.Meta4
                ? startCase(props.metaLabels?.Meta4)
                : "Meta 4",
            "meta4",
        ],
        [
            props.metaLabels?.Meta5
                ? startCase(props.metaLabels?.Meta5)
                : "Meta 5",
            "meta5",
        ],
    ];

    const addExtendedMetaLabels = (labels: string[]) => {
        if (!labels) return;
        labels.forEach((label) => {
            columnDefs.push([label, "extendedMetadata." + label]);
        });
    };

    if (props.extendedMetaLabels) {
        addExtendedMetaLabels(props.extendedMetaLabels);
    }

    const columns = columnDefs.map((c) => {
        return {
            headerLabel: c[0],
            dataKey: c[1],
            formatter: c[2],
        } as IAcxTableColumn;
    });

    const tableTitle =
        props.alternateTitle ||
        (props.enablePaging ? (
            <Typography variant="subtitle1">
                {props.tableStoreFragment.filteredItems.length}{" "}
                {props.title ?? `Source Files`}
            </Typography>
        ) : (
            props.title ?? `Source Files`
        ));

    return (
        <div className={classes.root}>
            <AcxControlledTable
                enableHover
                selectedItems={props.tableStoreFragment.selectedItemIds ?? []}
                onSelecteditems={props.tableStoreFragment.setSelectedItems}
                rows={
                    props.enablePaging
                        ? props.tableStoreFragment.filteredItems
                        : props.tableStoreFragment.items
                }
                style={{ maxHeight: '70vh' }}
                cellDense
                enableSort
                enableCheck={props.enableCheck}
                controls={props.controls}
                columns={columns}
                isLoading={props.loading}
                rowTerm={tableTitle}
                keyField="id"
                showPaging={props.enablePaging}
                onPageChange={props.tableStoreFragment.onPageChange}
                onRowsPerPageChange={
                    props.tableStoreFragment.onRowsPerPageChange
                }
                rowsPerPage={props.tableStoreFragment.rowsPerPage}
                rowCount={props.tableStoreFragment.totalRows}
            />
        </div>
    );
});

// Note: this is not a dataGrid column type because its used in an AcxControlledTable, not a dataGrid
export function dateTimeColumnFormatter(dateTime?: string) {
    return (
        <Typography noWrap>
            {dateTime
                ? moment(interactionDateHelper(dateTime))?.format(
                      "MM-DD-YYYY HH:mm:ss",
                  )
                : ""}
        </Typography>
    );
}

function statusFormatter(
    status: AudioMetadataStatus | UISamplingStatus | string,
) {
    if (isUIStatusModel(status)) {
        return <Typography color={"error"}>{status.message}</Typography>;
    } else {
        switch (status) {
            case AudioMetadataStatus.ReadyToSample:
                return "Ready To Sample";
            case AudioMetadataStatus.Sampled:
                return "Sampled";
            case AudioMetadataStatus.Transcribing:
                return "Transcribing";
            case AudioMetadataStatus.Processing:
                return "Processing";
            case AudioMetadataStatus.OnDemandProcessing:
                return "On Demand Processing";
            case AudioMetadataStatus.FailedToProcess:
                return "Failed To Process";
            case AudioMetadataStatus.NotProcessed:
                return "Not Processed";
            default:
                return status;
        }
    }
}

function mediaTypeFormatter(type: InteractionType) {
    switch (type) {
        case InteractionType.Audio:
            return "Audio";
        case InteractionType.Email:
            return "Email";
        case InteractionType.Video:
            return "Video";
        case InteractionType.Chat:
            return "Chat";
        case InteractionType.ProcessedChat:
            return "ProcessedChat";
        case InteractionType.ExtendedMetadata:
            return "ExtendedMetaData";
        default:
            return type;
    }
}

function isUIStatusModel(arg: unknown): arg is UISamplingStatus {
    return isType<UISamplingStatus>(arg, "message");
}

export default SourceFilesTable;
