import {
    AppBar,
    Button,
    Grid,
    IconButton,
    Theme,
    Toolbar,
    Tooltip,
    Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import BookIcon from "@mui/icons-material/Book";
import BookmarksIcon from "@mui/icons-material/Bookmarks";
import ChevronLeftSharpIcon from "@mui/icons-material/ChevronLeftSharp";
import EditIcon from "@mui/icons-material/Edit";
import ArrowDropDownIcon from "@mui/icons-material/ExpandMore";
import SummarizeIcon from "@mui/icons-material/FindInPage";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import HistoryIcon from "@mui/icons-material/History";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import RefreshIcon from "@mui/icons-material/Refresh";
import SaveAllIcon from "@mui/icons-material/Save";
import TimelineIcon from "@mui/icons-material/Timeline";
import clsx from "clsx";
import ProfileMenuIcon from "components/Navigation/ProfileMenuIcon";
import AcxConfirmationDialog from "components/UI/AcxConfirmationDialog";
import { Observer } from "mobx-react";
import React, { useEffect } from "react";
import { BeatLoader } from "react-spinners";
import { AuthStore } from "stores/AuthStore";
import hexToRGB from "utils/hexToRGB";
import { useStore } from "utils/useStore";
import {
    EvalType,
    EvaluationStatus,
    EvaluationStatusEnumStrConverter,
} from "../../../models/Evaluation";
import theme from "../../../Theme/AppTheme";
import { useResizeObserver } from "../../../utils/useResizeObserver";
import NotificationMenuIcon from "../../Notifications/NotificationMenuIcon";
import AcxMenu, {
    AcxMenuItemProps,
    StyledMenuLabel,
} from "../../UI/Menu/AcxMenu";
import EvaluationSearch from "../../UtilityComponents/EvaluationSearch";
import { ConvertToHumanEval, EvalStore, ModuleName } from "../Stores/EvalStore";
import ModuleNavList from "./ModuleNavList";
import PersonIcon from "@mui/icons-material/Person";
import moment from "moment-business-days";
import * as FedHolidays from "@18f/us-federal-holidays";
import AgentChatMenuIcon from "components/Agent/AgentChatMenuIcon";

interface IEvalNav {
    evalNumber: string | number;
    moduleDisplayNames?: ModuleName[];
    navigateBack: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
    noWrapText: {
        whiteSpace: "nowrap",
    },
    appBar: {
        backgroundColor: theme.palette.white.main,
        width: "100%",
        position: "relative",
        zIndex: theme.zIndex.drawer + 1,
    },
    toolbar: {
        width: "100%",
        height: "100%",
        position: "relative",
        paddingLeft: "0px",
        paddingRight: "1rem",
    },
    smallButtons: {
        maxHeight: "36px",
        textTransform: "none",
    },
    evalTitle: {
        fontSize: "18px",
        fontWeight: theme.typography.fontWeightBold as any,
        color: theme.palette.black.main,
        whiteSpace: "nowrap",
    },
    status: {
        fontSize: "18px",
        color: theme.palette.gray.main,
        whiteSpace: "nowrap",
    },
    spacer: {
        width: "6px",
        color: theme.palette.black.main,
        display: "inline",
        fontFamily: theme.typography.fontFamily,
        fontSize: "14px",
        fontWeight: theme.typography.fontWeightBold as any,
        textAlign: "center",
        marginRight: "4px",
        marginLeft: "4px",
        userSelect: "none",
    },
    statusContainer: {
        display: "flex",
        alignItems: "center",
    },
    requestReviewButton: {
        whiteSpace: "pre-wrap",
        lineHeight: "14px",
        marginTop: "5px",
        marginBottom: "5px",
    },
    tenantIcon: {
        height: 30,
        width: 30,
        borderRadius: "100%",
        backgroundColor: hexToRGB(theme.palette.secondary.main, 0.1),
        color: theme.palette.blue.main,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: "bold",
    },
}));

export default function EvalNav(props: IEvalNav) {
    const classes = useStyles(props);
    const authStore = useStore(AuthStore);

    const evalStore = useStore(EvalStore);

    const [saveAsAnchorEl, setSaveAsAnchorEl] =
        React.useState<null | HTMLElement>(null);

    const [showMoreAnchorEl, setShowMoreAnchorEl] =
        React.useState<null | HTMLElement>(null);

    const [noValueConfirmOpen, setNoValueConfirmOpen] =
        React.useState<boolean>(false);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { elementHeight: appBarHeight, theRef } =
        useResizeObserver<HTMLElement>({
            name: "EvalNavToolbar",
            pixelDelta: 1,
            debounceWait: 50,
        });

    function showMore(event: React.MouseEvent<HTMLButtonElement>) {
        setShowMoreAnchorEl(event.currentTarget);
    }

    function showMoreClose() {
        setShowMoreAnchorEl(null);
        setSaveAsAnchorEl(null);
    }

    async function handleRefreshActions() {
        await evalStore.refreshActionThread();
        showMoreClose();
    }

    async function handleShowActionHistory() {
        await evalStore.getAllActionThreads();
        showMoreClose();
    }

    function handleShowInteractionMetadata() {
        evalStore.drawerStore.closeDrawer();
        evalStore.toggleShowInteractionMetadata();
        showMoreClose();
    }

    function handleShowAnswerHistory() {
        evalStore.toggleAnswerHistory();
        showMoreClose();
    }

    function handleUndo() {
        evalStore.undoLastChange();
        showMoreClose();
    }

    function saveAsMoreOpen(event: React.MouseEvent<HTMLButtonElement>) {
        setSaveAsAnchorEl(event.currentTarget);
    }

    function saveAsMoreClose() {
        setSaveAsAnchorEl(null);
    }

    function handleSaveAsComplete(evt) {
        showMoreClose();
        evalStore.saveEvalDialogStore.setTitle(
            evalStore.currentEval?.submittedDate
                ? `Updating Completed Evaluation`
                : `Saving Complete Evaluation`,
        );

        if (
            evalStore.currentEval?.evaluationStatus ===
                EvaluationStatus.PendingReview &&
            evalStore.currentEval.actionThread
        ) {
            handleSaveAsDraft(null);
            return;
        }

        evalStore.saveEvalDialogStore.setLoading();
        evalStore.saveEvalDialogStore.open();
        evalStore
            .saveEvalAsComplete()
            .finally(() => {
                evalStore.saveEvalDialogStore.setNotLoading();
            })
            .then((value) => {
                evalStore.saveEvalDialogStore.close();

                if (value) {
                    props.navigateBack();
                }
            });
    }

    function handleSaveAsNoValue(evt) {
        showMoreClose();
        evalStore.saveEvalDialogStore.setTitle(
            `Marking Evaluation As No-Value`,
        );
        evalStore.saveEvalDialogStore.setLoading();
        evalStore.saveEvalDialogStore.open();
        evalStore
            .updateEval(EvaluationStatus.NoValue)
            .finally(() => {
                evalStore.saveEvalDialogStore.setNotLoading();
            })
            .then((value) => {
                evalStore.saveEvalDialogStore.close();
                if (value) {
                    props.navigateBack();
                }
            });
    }

    function handleSaveAsDraft(evt) {
        showMoreClose();

        let title = `Saving Draft Evaluation`;
        let status = EvaluationStatus.InProgress;
        if (
            evalStore.currentEval?.evaluationStatus ===
                EvaluationStatus.PendingReview &&
            evalStore.currentEval.actionThread
        ) {
            status = EvaluationStatus.PendingReview;
            title = `Updating Evaluation For Review`;
        }

        evalStore.saveEvalDialogStore.setTitle(title);
        evalStore.saveEvalDialogStore.setLoading();
        evalStore.saveEvalDialogStore.open();

        evalStore
            .updateEval(status)
            .finally(() => {
                evalStore.saveEvalDialogStore.setNotLoading();
            })
            .then((value) => {
                evalStore.saveEvalDialogStore.close();
            });
    }

    function handleRequestReview(evt) {
        showMoreClose();
        evalStore.requestActionDialogStore.setTitle(
            "Evaluation Review Request",
        );
        evalStore.requestActionDialogStore.setSubTitle(undefined);
        evalStore.requestActionDialogStore.open();
    }

    function handleStartDispute(evt) {
        showMoreClose();
        evalStore.disputeHasStarted = true;
    }

    function handleRequestDispute(evt) {
        showMoreClose();
        evalStore.requestDisputeDialogStore.setTitle(
            "Evaluation Dispute Request",
        );
        evalStore.requestDisputeDialogStore.setSubTitle(undefined);
        evalStore.requestDisputeDialogStore.open();
    }

    const onCancelNoValueOpen = () => {
        setNoValueConfirmOpen(true);
    };

    const onCancelNoValueClose = () => {
        setNoValueConfirmOpen(false);
    };

    const noValueConfirm = (evt) => {
        setNoValueConfirmOpen(false);
        handleSaveAsNoValue(evt);
    };

    const acknowledgeClosedText =
        "The window to acknowledge this evaluation has closed.";

    useEffect(() => {
        if (evalStore.authStore.canUserView("Agent Acknowledge")) {
            const checkAcknowledgeWindow = () => {
                const orgDayVal = Number(
                    evalStore.authStore.orgStore.selectedOrganization
                        ?.organizationConfigurations?.evalAcknowledgeDays,
                );

                if (
                    !evalStore.authStore.orgStore.selectedOrganization
                        ?.organizationConfigurations?.evalAcknowledgeDays ||
                    orgDayVal === 0
                ) {
                    evalStore.acknowledgeWindowHasClosed = false;
                    return;
                }

                const holidays = FedHolidays.allForYear(2016).map(
                    (i) => i.dateString,
                );

                moment.updateLocale("us", {
                    holidays: holidays,
                    holidayFormat: "MM-D-YYYY",
                });

                const today = moment();

                const dayDiff = today.businessDiff(
                    moment(evalStore.currentEval?.submittedDate),
                );

                if (dayDiff >= orgDayVal) {
                    evalStore.acknowledgeWindowHasClosed = true;
                } else {
                    evalStore.acknowledgeWindowHasClosed = false;
                }
            };
            checkAcknowledgeWindow();
        }
    }, [
        evalStore,
        evalStore.authStore.orgStore.selectedOrganization
            ?.organizationConfigurations,
        evalStore.currentEval,
    ]);

    return (
        <Observer>
            {() => {
                const saveAsMenuItems: AcxMenuItemProps[] = [
                    {
                        id: "eval-save-as-no-value",
                        label: <StyledMenuLabel>No Value</StyledMenuLabel>,
                        icon: <HighlightOffIcon fontSize={"small"} />,
                        hidden: evalStore.currentEval?.isComplete,
                        props: {
                            onClick: onCancelNoValueOpen,
                            disabled:
                                evalStore.currentEval?.evaluationStatus ===
                                EvaluationStatus.PendingReview,
                        },
                    },
                    {
                        id: "eval-save-as-draft",
                        label: <StyledMenuLabel>Draft</StyledMenuLabel>,
                        icon: <EditIcon fontSize={"small"} />,
                        props: {
                            onClick: handleSaveAsDraft,
                            disabled: evalStore.currentEval?.isComplete,
                        },
                    },
                    {
                        id: "eval-save-as-complete",
                        label: <StyledMenuLabel>Complete</StyledMenuLabel>,
                        icon: <SaveAllIcon fontSize={"small"} />,
                        props: {
                            onClick: handleSaveAsComplete,
                            disabled:
                                (evalStore.currentEval?.evaluationStatus ===
                                    EvaluationStatus.PendingReview &&
                                    !evalStore.currentEval.isComplete &&
                                    !!evalStore.currentEval.actionThread) ||
                                evalStore.hasInProgressWorkflows,
                        },
                    },
                ];

                const moreMoreItems: AcxMenuItemProps[] = [
                    {
                        id: "eval-more-undo",
                        label: <StyledMenuLabel>Undo</StyledMenuLabel>,
                        icon: <HistoryIcon fontSize={"small"} />,
                        props: {
                            disabled: !evalStore.isUndoAvailable,
                            onClick: handleUndo,
                            style: { height: "40px" },
                        },
                    },
                    {
                        id: "action-thread-refresh",
                        action: (
                            <BeatLoader
                                size={4}
                                color={theme.palette.secondary.main}
                                loading={evalStore.getTaskLoading(
                                    "Loading Latest Action Thread",
                                )}
                            />
                        ),
                        label: (
                            <StyledMenuLabel>
                                {evalStore.currentEval?.actionThread &&
                                evalStore.showThread
                                    ? "Refresh Latest Review Action"
                                    : "Show Latest Review Action"}
                            </StyledMenuLabel>
                        ),

                        icon:
                            evalStore.currentEval?.actionThread &&
                            evalStore.showThread ? (
                                <RefreshIcon fontSize={"small"} />
                            ) : (
                                <BookIcon fontSize={"small"} />
                            ),
                        hidden:
                            !!evalStore.currentEval?.allActionThreads &&
                            evalStore.showThreadHistory,
                        props: {
                            onClick: handleRefreshActions,
                            disabled: evalStore.evalAndDependenciesLoading,
                            style: { height: "40px" },
                        },
                    },
                    {
                        id: "all-action-thread-show",
                        action: (
                            <BeatLoader
                                size={4}
                                color={theme.palette.secondary.main}
                                loading={evalStore.getTaskLoading(
                                    "Loading Action Thread History",
                                )}
                            />
                        ),
                        label: (
                            <StyledMenuLabel>
                                {evalStore.currentEval?.allActionThreads &&
                                evalStore.showThreadHistory
                                    ? "Refresh Review Action History"
                                    : "Show Review Action History"}
                            </StyledMenuLabel>
                        ),
                        icon:
                            evalStore.currentEval?.allActionThreads &&
                            evalStore.showThreadHistory ? (
                                <RefreshIcon fontSize={"small"} />
                            ) : (
                                <BookmarksIcon fontSize={"small"} />
                            ),
                        props: {
                            onClick: handleShowActionHistory,
                            disabled: evalStore.evalAndDependenciesLoading,
                            style: { height: "40px" },
                        },
                    },
                    {
                        id: "interaction-metadata-show",
                        action: (
                            <BeatLoader
                                size={4}
                                color={theme.palette.secondary.main}
                                loading={evalStore.getTaskLoading(
                                    "Loading Interaction Metadata",
                                )}
                            />
                        ),
                        label: (
                            <StyledMenuLabel>
                                {evalStore.showInteractionMetadata
                                    ? "Close Interaction Metadata"
                                    : "Show Interaction Metadata"}
                            </StyledMenuLabel>
                        ),
                        icon: <SummarizeIcon fontSize={"small"} />,
                        props: {
                            onClick: handleShowInteractionMetadata,
                            disabled: evalStore.evalAndDependenciesLoading,
                            style: { height: "40px" },
                        },
                    },
                    {
                        id: "evaluation-answer-history-show",
                        action: (
                            <BeatLoader
                                size={4}
                                color={theme.palette.secondary.main}
                                loading={evalStore.getTaskLoading(
                                    "Loading Answer History",
                                )}
                            />
                        ),
                        label: (
                            <StyledMenuLabel>
                                {evalStore.drawerStore.isOpen
                                    ? "Close Answer History"
                                    : "Show Answer History"}
                            </StyledMenuLabel>
                        ),
                        icon: <TimelineIcon fontSize={"small"} />,
                        props: {
                            onClick: handleShowAnswerHistory,
                            disabled: evalStore.evalAndDependenciesLoading,
                            style: { height: "40px" },
                        },
                    },
                    {
                        id: "evaluation-convert-to-analyst-eval-full",
                        action: (
                            <BeatLoader
                                size={4}
                                color={theme.palette.secondary.main}
                                loading={evalStore.getTaskLoading(
                                    ConvertToHumanEval,
                                )}
                            />
                        ),
                        label: (
                            <StyledMenuLabel>
                                Convert to Human Evaluation
                            </StyledMenuLabel>
                        ),
                        icon: <PersonIcon fontSize={"small"} />,
                        hidden:
                            !authStore.canUserEdit(
                                "Convert to Analyst evaluation",
                            ) ||
                            !evalStore.evaluationPermissions
                                ?.canConvertToAnalystEvaluation,
                        props: {
                            onClick: () => {
                                evalStore.setConvertToHumanModalOpen(true);
                                showMoreClose();
                            },
                            disabled: evalStore.evalAndDependenciesLoading,
                            style: { height: "40px" },
                        },
                    },
                ];

                return (
                    <AppBar
                        ref={theRef}
                        className={classes.appBar}
                        elevation={1}
                        position="sticky"
                    >
                        <AcxConfirmationDialog
                            onClose={onCancelNoValueClose}
                            isOpen={noValueConfirmOpen}
                            content='Save this evaluation as "No Value" ?'
                            onConfirm={noValueConfirm}
                        />

                        <Toolbar className={classes.toolbar}>
                            <Grid
                                container
                                id={"root-column-grid"}
                                direction="column"
                                justifyContent="flex-start"
                                alignItems="stretch"
                            >
                                <Grid
                                    container
                                    item
                                    xs={12}
                                    style={{ marginTop: "4px" }}
                                    id={"first-row-grid"}
                                    direction="row"
                                    wrap={"nowrap"}
                                    justifyContent={"space-between"}
                                    alignItems="center"
                                >
                                    <Grid
                                        item
                                        xs={4}
                                        id={"eval-title-grid-item"}
                                        container
                                        direction={"row"}
                                        justifyContent={"flex-start"}
                                        wrap={"nowrap"}
                                        alignItems={"center"}
                                    >
                                        <Grid item>
                                            <IconButton
                                                onClick={props.navigateBack}
                                                size="large"
                                            >
                                                <ChevronLeftSharpIcon />
                                            </IconButton>
                                        </Grid>
                                        <Grid item>
                                            <Typography
                                                sx={{
                                                    fontSize: "18px",
                                                    fontWeight: theme.typography
                                                        .fontWeightBold as any,
                                                    color: theme.palette.black
                                                        .main,
                                                    whiteSpace: "nowrap",
                                                }}
                                            >
                                                Evaluation #{props.evalNumber}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            container
                                            alignItems="center"
                                        >
                                            <div
                                                className={
                                                    classes.statusContainer
                                                }
                                            >
                                                <Typography
                                                    sx={{
                                                        width: "6px",
                                                        color: theme.palette
                                                            .black.main,
                                                        display: "inline",
                                                        fontFamily:
                                                            theme.typography
                                                                .fontFamily,
                                                        fontSize: "14px",
                                                        fontWeight: theme
                                                            .typography
                                                            .fontWeightBold as any,
                                                        textAlign: "center",
                                                        marginRight: "4px",
                                                        marginLeft: "4px",
                                                        userSelect: "none",
                                                    }}
                                                >
                                                    &bull;
                                                </Typography>

                                                <Typography
                                                    style={{
                                                        paddingLeft: "4px",
                                                    }}
                                                >
                                                    <span
                                                        className={clsx(
                                                            classes.status,
                                                        )}
                                                    >
                                                        {EvaluationStatusEnumStrConverter(
                                                            evalStore
                                                                .currentEval
                                                                ?.evaluationStatus ??
                                                                EvaluationStatus.NotStarted,
                                                        )}
                                                    </span>
                                                </Typography>
                                            </div>
                                        </Grid>
                                    </Grid>

                                    <Grid
                                        id={"eval-module-nav-grid"}
                                        item
                                        xs={4}
                                        container
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <ModuleNavList
                                            moduleDisplayNames={
                                                props.moduleDisplayNames
                                            }
                                        />
                                    </Grid>

                                    <Grid
                                        id={"eval-saveAs-gridItem"}
                                        container
                                        item
                                        xs={4}
                                        direction={"row"}
                                        justifyContent={"flex-end"}
                                        wrap={"nowrap"}
                                        alignItems={"center"}
                                    >
                                        {evalStore.showDisputeOption && (
                                            <Tooltip
                                                title={
                                                    evalStore.authStore.canUserView(
                                                        "Agent Acknowledge",
                                                    ) &&
                                                    !evalStore.currentEval
                                                        ?.agentAcknowledged &&
                                                    evalStore.acknowledgeWindowHasClosed
                                                        ? acknowledgeClosedText
                                                        : evalStore.currentEval
                                                              ?.agentAcknowledged
                                                        ? "Cannot dispute after acknowledging an evaluation."
                                                        : ""
                                                }
                                            >
                                                <Grid item>
                                                    <Button
                                                        className={clsx(
                                                            classes.noWrapText,
                                                            classes.smallButtons,
                                                        )}
                                                        onClick={
                                                            handleStartDispute
                                                        }
                                                        style={{
                                                            marginRight: "8px",
                                                        }}
                                                        variant="outlined"
                                                        color="primary"
                                                        disableElevation
                                                        disabled={
                                                            evalStore.authStore.canUserView(
                                                                "Agent Acknowledge",
                                                            )
                                                                ? evalStore.acknowledgeWindowHasClosed ||
                                                                  evalStore
                                                                      .currentEval
                                                                      ?.agentAcknowledged ||
                                                                  evalStore.disputeHasStarted
                                                                : false
                                                        }
                                                    >
                                                        {evalStore.currentEval
                                                            ?.evaluationStatus ===
                                                            EvaluationStatus.Disputed ||
                                                        evalStore.disputeHasStarted ||
                                                        evalStore.currentEval
                                                            ?.previouslyDisputed
                                                            ? "Evaluation Disputed"
                                                            : "Dispute Evaluation"}
                                                    </Button>
                                                </Grid>
                                            </Tooltip>
                                        )}
                                        {evalStore.showAcknowledgeOption && (
                                            <Tooltip
                                                title={
                                                    evalStore.acknowledgeWindowHasClosed &&
                                                    !evalStore.currentEval
                                                        ?.agentAcknowledged
                                                        ? acknowledgeClosedText
                                                        : ""
                                                }
                                            >
                                                <span>
                                                    <Button
                                                        className={clsx(
                                                            classes.noWrapText,
                                                            classes.smallButtons,
                                                        )}
                                                        onClick={
                                                            evalStore.acknowledgeEvaluation
                                                        }
                                                        style={{
                                                            marginRight: "8px",
                                                        }}
                                                        variant="outlined"
                                                        color="primary"
                                                        disableElevation
                                                        disabled={
                                                            evalStore
                                                                .currentEval
                                                                ?.agentAcknowledged ||
                                                            evalStore.acknowledgeWindowHasClosed
                                                        }
                                                    >
                                                        {evalStore.currentEval
                                                            ?.agentAcknowledged
                                                            ? "Evaluation Acknowledged"
                                                            : "Acknowledge Evaluation"}
                                                    </Button>
                                                </span>
                                            </Tooltip>
                                        )}
                                        {Boolean(
                                            evalStore.showDisputeOption &&
                                                evalStore.disputeHasStarted &&
                                                evalStore.currentEval?.type !==
                                                    EvalType.MiddleOfTheFunnel,
                                        ) && (
                                            <Grid item>
                                                <Button
                                                    className={clsx(
                                                        classes.noWrapText,
                                                        classes.smallButtons,
                                                    )}
                                                    onClick={
                                                        handleRequestDispute
                                                    }
                                                    style={{
                                                        marginRight: "8px",
                                                    }}
                                                    variant="outlined"
                                                    color="primary"
                                                    disabled={
                                                        evalStore.currentEval
                                                            ?.disputedAnswers
                                                            ?.length === 0
                                                    }
                                                    disableElevation
                                                >
                                                    Request Dispute
                                                </Button>
                                            </Grid>
                                        )}
                                        {Boolean(
                                            ![
                                                EvaluationStatus.PendingReview,
                                                EvaluationStatus.ReviewComplete,
                                            ].includes(
                                                evalStore.currentEval
                                                    ?.evaluationStatus ??
                                                    EvaluationStatus.InProgress,
                                            ) && !evalStore.showDisputeOption,
                                        ) &&
                                            authStore.canUserView(
                                                "Request Review",
                                            ) && (
                                                <Grid item>
                                                    <Button
                                                        className={clsx(
                                                            classes.noWrapText,
                                                            classes.smallButtons,
                                                        )}
                                                        onClick={
                                                            handleRequestReview
                                                        }
                                                        disabled={
                                                            evalStore.isReviewRequestDisabled
                                                        }
                                                        style={{
                                                            marginRight: "8px",
                                                        }}
                                                        variant="outlined"
                                                        color="primary"
                                                        disableElevation
                                                        // classes={{
                                                        //     label: clsx(
                                                        //         classes.requestReviewButton,
                                                        //     ),
                                                        // }}
                                                    >
                                                        Request Review
                                                    </Button>
                                                </Grid>
                                            )}
                                        {Boolean(
                                            !evalStore.showDisputeOption,
                                        ) && (
                                            <Grid item>
                                                <Button
                                                    className={clsx(
                                                        classes.noWrapText,
                                                        classes.smallButtons,
                                                    )}
                                                    disableElevation
                                                    variant={"contained"}
                                                    color={"primary"}
                                                    disabled={
                                                        evalStore.areSaveActionsDisabled
                                                    }
                                                    onClick={saveAsMoreOpen}
                                                    style={{
                                                        lineHeight: "14px",
                                                    }}
                                                >
                                                    Save As
                                                    <ArrowDropDownIcon
                                                        style={{
                                                            marginLeft: "8px",
                                                        }}
                                                    />
                                                </Button>
                                                <AcxMenu
                                                    menuItems={saveAsMenuItems}
                                                    onMenuClose={
                                                        saveAsMoreClose
                                                    }
                                                    anchorElement={
                                                        saveAsAnchorEl
                                                    }
                                                />
                                            </Grid>
                                        )}
                                        <Grid item>
                                            <IconButton
                                                onClick={showMore}
                                                size="large"
                                            >
                                                <MoreVertIcon />
                                            </IconButton>
                                            <AcxMenu
                                                menuItems={moreMoreItems}
                                                anchorElement={showMoreAnchorEl}
                                                open={Boolean(showMoreAnchorEl)}
                                                onMenuClose={showMoreClose}
                                            />
                                        </Grid>
                                        {(!evalStore.authStore.isLoggedInUserAgent() ||
                                            evalStore.authStore.isUserUltra()) && (
                                            <Grid item>
                                                <EvaluationSearch />
                                            </Grid>
                                        )}
                                        <Grid
                                            item
                                            style={{ alignSelf: "center" }}
                                        >
                                            <AgentChatMenuIcon />
                                        </Grid>
                                        <Grid item>
                                            <NotificationMenuIcon
                                                drawerOffsetPx={appBarHeight}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <ProfileMenuIcon
                                                currLocation={"mainApp"}
                                                organizationName={
                                                    evalStore.authStore.orgStore
                                                        .selectedOrganization
                                                        ?.name ?? ""
                                                }
                                                customRootStyles={{
                                                    marginLeft: "0.5rem",
                                                    marginRight: "0.25rem",
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Toolbar>
                    </AppBar>
                );
            }}
        </Observer>
    );
}
