import { Grid } from "@mui/material";
import AgentChatStore from "components/Agent/Stores/AgentChatStore";
import AcxDialog from "components/UI/Dialog/AcxDialog";
import React, { useEffect, useState } from "react";
import { useStore } from "utils/useStore";
import { Suggestion } from "../Suggestions/Suggestion";
import AcxButton from "components/UI/AcxButton";
import AcxSelectMulti from "components/UI/Select/BaseSelectComponents/AcxSelectMulti";
import { ApplicationFilterValuesStore } from "stores/ApplicationFilters/ApplicationFilterValuesStore";
import { SimpleTopicCluster } from "stores/ApplicationFilters/ApplicationFiltersStore";
import { observer } from "mobx-react";

export const SelectTopicsAction = observer(({args}) => {
    const store = useStore(AgentChatStore);
    const valuesStore = useStore(ApplicationFilterValuesStore);
    const [topics, setTopics] = useState<SimpleTopicCluster[] | undefined>(
        undefined,
    );
    const [dialogOpen, setDialogOpen] = useState(false);

    useEffect(() => {
        if (!dialogOpen) return;
        valuesStore.loadTopics();
    }, [dialogOpen, valuesStore]);

    return (
        <React.Fragment>
            <AcxDialog
                isOpen={dialogOpen}
                onClose={() => {
                    setDialogOpen(false);
                }}
                title="Topics"
                maxWidth="lg"
                dialogContentChildren={
                    <AcxSelectMulti
                        id="ava-topics-multi-select"
                        fullWidth
                        isLoading={valuesStore.getTaskLoading(
                            ApplicationFilterValuesStore.Tasks.LOAD_TOPICS,
                        )}
                        options={valuesStore.topicClusters ?? []}
                        defaultValue={topics}
                        onChange={(options) => {
                            setTopics(!!options ? options : []);
                        }}
                        valueField="id"
                        labelField="topicLabel"
                        containerHeight="auto"
                        maxContainerHeight="96px"
                        alignControls="flex-start"
                        width="24rem"
                        isClearable={true}
                    />
                }
            >
                <AcxButton
                    fullWidth={false}
                    onClick={() => {
                        if (!topics || topics.length === 0) return;
                        store.streamingSubmitInput({
                            manualInput: topics
                                .map((t) => t.topicLabel)
                                .join(", "),
                        });
                    }}
                    disabled={!topics || topics.length === 0}
                >
                    Use selected topics
                </AcxButton>
            </AcxDialog>
            <Grid item>
                <Suggestion onClick={() => setDialogOpen((open) => !open)}>
                    {args?.buttonText || "Select Topics"}
                </Suggestion>
            </Grid>
        </React.Fragment>
    );
});
