import { Grid, Paper, Popper, TextField, Theme, useTheme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import SaveIcon from "@mui/icons-material/Save";
import UpdateIcon from "@mui/icons-material/Update";
import AcxButton from "components/UI/AcxButton";
import { observer } from "mobx-react";
import React, { ChangeEvent } from "react";
import { AuthStore } from "stores/AuthStore";
import { useStore } from "utils/useStore";
import { ClassifierBuilderStore } from "../ClassifierBuilderStore";
import ClassifierCheatsheet from "./ClassifierCheatsheet";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        fullWidth: {
            width: "100%",
        },
        saveDialog: {
            minWidth: "50em",
            minHeight: "20em",
        },
        saveButtonIcon: {
            marginLeft: theme.spacing(3),
        },
        bottomControlsWrapper: {
            marginTop: "0.5rem",
        },
        textField: {
            width: "70%",
        },
    }),
);

interface OwnProps {
    store: ClassifierBuilderStore;
    onQueryChange: (newQuery: string) => void;
    query: string;
    onSaveButtonClick: () => void;
    onUpdateButtonClick: () => void;
}

const ClassifierBuilderEditorView = observer((props: OwnProps) => {
    const classes = useStyles();
    const theme = useTheme();
    const authStore = useStore(AuthStore);

    const onQueryChange = (e: ChangeEvent<HTMLInputElement>) => {
        props.onQueryChange(e.target.value);
    };

    return (
        <>
            <Grid container item>
                <TextField
                    onContextMenu={props.store.onEditorContextMenuClick}
                    className={classes.textField}
                    id="search-term-entry"
                    label="Search Terms"
                    multiline
                    rows={17}
                    value={props.query}
                    onChange={onQueryChange}
                    variant="outlined"
                    inputProps={{
                        style: {
                            fontFamily:
                                "SFMono-Regular, Menlo,Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace",
                        },
                    }}
                    InputLabelProps={{
                        style: {
                            fontFamily:
                                "SFMono-Regular, Menlo,Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace",
                        },
                    }}
                />

                <ClassifierCheatsheet />
            </Grid>

            <Popper
                style={{
                    marginLeft: theme.spacing(1),
                    maxWidth: "450px",
                }}
                open={!!props.store.editorMenuPopperElement}
                anchorEl={props.store.editorMenuPopperElement}
                placement={"right"}
            >
                <Paper elevation={2}>
                    {props.store.documentationContentForMenuItem(
                        props.store?.editorMenuPopperElement?.id,
                    )}
                </Paper>
            </Popper>

            <Grid
                container
                justifyContent="flex-start"
                alignItems="center"
                className={classes.bottomControlsWrapper}
            >
                <Grid item style={{ paddingTop: "0px" }}>
                    <AcxButton
                        id="save-new-classifier"
                        color="primary"
                        disabled={
                            !props.query ||
                            props.query.trim() === "" ||
                            !authStore.canUserEdit("Builder")
                        }
                        onClick={props.onSaveButtonClick}
                        style={{ marginRight: "0.5rem" }}
                    >
                        Save New Classifier
                        <SaveIcon className={classes.saveButtonIcon} />
                    </AcxButton>
                </Grid>

                {props.store.selectedClassifier && (
                    <Grid item style={{ paddingTop: "0px" }}>
                        <AcxButton
                            id="update-classifier"
                            color="primary"
                            disabled={
                                !props.query ||
                                props.query.trim() === "" ||
                                !authStore.canUserEdit("Builder")
                            }
                            onClick={props.onUpdateButtonClick}
                        >
                            Update Classifier
                            <UpdateIcon className={classes.saveButtonIcon} />
                        </AcxButton>
                    </Grid>
                )}
            </Grid>
        </>
    );
});

export default ClassifierBuilderEditorView;
