import { observer } from "mobx-react";
import { ApplicationFilterItemsStore } from "stores/ApplicationFilters/ApplicationFilterItemsStore";
import type { IApplicationFilterItem } from "stores/ApplicationFilters/ApplicationFilterItemsStore";
import type { IApplicationFilterComponent } from "./IApplicationFilterComponent";
import { Grid } from "@mui/material";
import React from "react";
import { FormLabel, IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Close } from "@mui/icons-material";
import { useStore } from "utils/useStore";
import { FilterComponent } from "./FilterComponent";
import { ApplicationFiltersInstanceStore } from "stores/ApplicationFilters/ApplicationFiltersInstanceStore";

interface ApplicationFilterItemProps
    extends IApplicationFilterItem,
        IApplicationFilterComponent {
    instanceStore: ApplicationFiltersInstanceStore;
}

const useStyles = makeStyles(() => ({
    label: { fontWeight: "bold" },
}));

const ApplicationFilterItem = observer((props: ApplicationFilterItemProps) => {
    const applicationFilterItemsStore = useStore(ApplicationFilterItemsStore);

    const classes = useStyles();

    // Label is managed by the filter component. Due to re-using old components,
    // re-factor will come another day so we don't have to do these shenanigans
    const isLabelManaged =
        props.filterKey === "rbcFilterItems" ||
        props.filterKey === "wordsAndPhrasesSearchString";

    return (
        <Grid container direction="column">
            <Grid
                container
                item
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Grid
                    container
                    item
                    direction="row"
                    spacing={1}
                    width="fit-content"
                    alignItems="center"
                >
                    <Grid item>
                        <FormLabel
                            className={classes.label}
                            htmlFor={props.filterKey}
                        >
                            {!isLabelManaged && props.title}
                        </FormLabel>
                    </Grid>
                </Grid>
                <Grid item>
                    <IconButton
                        id="remove-filter"
                        size="small"
                        onClick={() => {
                            applicationFilterItemsStore.removeSelectedFilterItem(
                                props,
                            );

                            if (props.filterKey === "callDuration") {
                                props.store.setCallDuration([]);
                            } else if (props.filterKey === "dateRange") {
                                // dont remove from quickFiltersStore, rather the instanceStore.
                                props.instanceStore.removeDateRange();
                            } else {
                                props.store.resetByKey(props.filterKey);
                            }
                        }}
                    >
                        <Close />
                    </IconButton>
                </Grid>
            </Grid>
            <Grid container item width="50%">
                <Grid item flexGrow={1}>
                    <FilterComponent
                        id={props.filterKey}
                        store={props.store}
                        filterKey={props.filterKey}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
});

export default ApplicationFilterItem;
