import { observer } from "mobx-react";
import React, { useCallback } from "react";
import { useStore } from "utils/useStore";
import SignalsStore from "./Store/SignalsStore";
import { Route, Routes, useLocation } from "react-router-dom";
import SignalsBody from "./Components/SignalsBody";
import SignalsCustomReportBody from "./SignalsCustomReportBody";
import { INavigationGroup } from "Layouts/SideNavigation/NavigationStore";
import { Box } from "@mui/material";
import SignalsReportStore from "./Store/SignalsReportStore";
import {
    IDashboardVisibility,
    ISignalsVizDefinition,
    SignalsDomain,
    TopicPrevalenceID,
} from "./Models/SignalsModels";

type StyleProps = {
    height?: number;
    width?: number;
    hideTitle?: boolean;
    top?: number;
    right?: number;
};

const SignalsRoutes = observer((props: StyleProps) => {
    const signalsStore = useStore(SignalsStore);
    const signalsReportStore = useStore(SignalsReportStore);

    const location = useLocation();

    const currentSignalsLocation = location.pathname
        .split("signals")[1]
        .slice(1);

    const currentDomainName = (
        currentSignalsLocation !== "" ? currentSignalsLocation : "eddy"
    ) as SignalsDomain;

    const dashboardDomain = currentDomainName.split("/")[0] as SignalsDomain;

    const buildSignalsNavItems = useCallback(() => {
        const pathArray = location.pathname.split("/");

        const navItemCallback = (
            item: ISignalsVizDefinition,
            inputDomain: SignalsDomain,
        ) => {
            const { id, chartTitle, showDataGrid } = item;
            let hashtagLink = `${inputDomain}#${id}`;

            if (inputDomain === "eddy") {
                if (dashboardDomain === "dashboard") {
                    hashtagLink = `${pathArray.slice(0, -2)?.join("/")}#${id}`;
                } else if (currentDomainName !== "eddy") {
                    hashtagLink = `${pathArray.slice(0, -1)?.join("/")}#${id}`;
                } else {
                    hashtagLink = `#${id}`;
                }
            }
            const isCurrentDomain = inputDomain === currentDomainName;
            return {
                title: (
                    <Box
                        fontSize={12}
                        onClick={() => {
                            setTimeout(
                                () =>
                                    document
                                        .getElementById(id)
                                        ?.scrollIntoView({
                                            behavior: "smooth",
                                            block: showDataGrid
                                                ? "end"
                                                : "center",
                                        }),
                                !isCurrentDomain ? 1000 : 0, // timeout is to allow page to re-render new charts before scrolling to id
                            );
                        }}
                    >
                        {chartTitle}
                    </Box>
                ),
                link: hashtagLink,
                removeMinHeight: true,
                signalsVariant: true,
                itemOptions: { linkFontSize: "12px" },
            };
        };

        const navItems: INavigationGroup[] = [
            {
                header: "Eddy Reports",
                items: signalsReportStore.allChartData
                    .filter((c) => c.isEddyReport)
                    .map((item) => {
                        return navItemCallback(item, "eddy");
                    }),
                expanded: false,
                linkPath: "",
            },
        ];

        if (signalsStore.authStore.canUserView("Trends")) {
            navItems.push({
                header: "Trends Highlights",
                items: signalsReportStore.allChartData
                    .filter((c) => c.isTrendReport)
                    .map((item) => {
                        return navItemCallback(item, "trends");
                    }),
                expanded: false,
                linkPath: "trends",
            });
        }

        if (signalsStore.authStore.canUserView("Topics")) {
            navItems.push({
                header: "Themes and Topics",
                items: signalsReportStore.allChartData
                    .filter((c) => c.isTopicReport)
                    .sort((a, b) => {
                        // need to sort so that Topic Prev is before TopicPrev over time
                        if (a.id === TopicPrevalenceID) {
                            return -1;
                        } else {
                            return 1;
                        }
                    })
                    .map((item) => {
                        return navItemCallback(item, "topics");
                    }),
                expanded: false,
                linkPath: "topics",
            });
        }

        if (signalsStore.authStore.canUserView("Contacts")) {
            navItems.push({
                header: "Contact Types",
                items: signalsReportStore.allChartData
                    .filter((c) => c.isContactReport)
                    .map((item) => {
                        return navItemCallback(item, "contacts");
                    }),
                expanded: false,
                linkPath: "contacts",
            });
        }

        return navItems;
    }, [
        location.pathname,
        signalsReportStore.allChartData,
        signalsStore.authStore,
        currentDomainName,
        dashboardDomain,
    ]);

    const buildSignalsDashboardNavItems = useCallback(
        (visibility: IDashboardVisibility) => {
            const navItemCallbackForDash = (
                item: ISignalsVizDefinition,
                dashId: string,
            ) => {
                const { id, chartTitle } = item;
                const showDataGrid = signalsReportStore.allChartData.find(
                    (cd) => cd.id === item.dataEndpoint,
                )?.showDataGrid;
                let hashtagLink = `dashboard/${dashId}#${id}`;

                const isCurrentDomain =
                    "dashboard" === currentDomainName.split("/")[0];

                const isCurrentDashboard =
                    dashId === currentDomainName.split("/")[1];

                return {
                    title: (
                        <Box
                            fontSize={12}
                            onClick={() => {
                                setTimeout(
                                    () =>
                                        document
                                            .getElementById(id)
                                            ?.scrollIntoView({
                                                behavior: "smooth",
                                                block: showDataGrid
                                                    ? "end"
                                                    : "center",
                                            }),
                                    !isCurrentDomain || !isCurrentDashboard
                                        ? 1000
                                        : 0, // timeout is to allow page to re-render new charts before scrolling to id
                                );
                            }}
                        >
                            {chartTitle}
                        </Box>
                    ),
                    link: hashtagLink,
                    removeMinHeight: true,
                    signalsVariant: true,
                    itemOptions: { linkFontSize: "12px" },
                };
            };

            const navItems: INavigationGroup[] = [];

            signalsReportStore.allDashboardsUserCanView
                .filter((i) => i.visibility === visibility)
                .forEach((dash) => {
                    navItems.push({
                        header: dash.title,
                        items: dash.signalsVizDefinitions.map((item) => {
                            return navItemCallbackForDash(item, dash.id);
                        }),
                        expanded: false,
                        linkPath: "dashboard/" + dash.id,
                    });
                });

            return navItems;
        },
        [
            signalsReportStore.allChartData,
            signalsReportStore.allDashboardsUserCanView,
            currentDomainName,
        ],
    );

    React.useEffect(() => {
        const pathArray = location.pathname.split("/");
        const isReports = pathArray.includes("reports");

        signalsStore.navStore.clearNavItems();

        if (isReports) {
            signalsStore.showReports = true;
            signalsStore.navStore.setNavItems(
                signalsStore.reportsStore.navStore.itemGroups,
            );
        } else {
            signalsStore.showReports = false;

            signalsStore.navStore.setNavItems(buildSignalsNavItems());
            signalsStore.userDashboardNavStore.setNavItems(
                buildSignalsDashboardNavItems("User"),
            );
            signalsStore.orgDashboardNavStore.setNavItems(
                buildSignalsDashboardNavItems("Organization"),
            );
        }
    }, [
        buildSignalsDashboardNavItems,
        buildSignalsNavItems,
        location,
        signalsStore,
    ]);

    return (
        <Routes>
            <Route
                path="/reports/*"
                element={<SignalsCustomReportBody hideTitle={true} />}
            />

            <Route
                path="/dashboard/:dashboardId"
                element={<SignalsBody domainName={dashboardDomain} />}
            />

            <Route
                path="*"
                element={<SignalsBody domainName={currentDomainName} />}
            />
        </Routes>
    );
});

export default SignalsRoutes;
