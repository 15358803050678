import {
    BackupFilters,
    ConversationFilterObject,
    EvalType as ConversationsEvalType,
} from "components/Conversations/Stores/ConversationsStore";
import { IRBCFilter } from "components/Conversations/Views/Drawer/components/DeepFilter/RBCFilterStore";
import {
    IWordsPhrasesModel,
    initWordsPhrasesItems,
} from "components/Conversations/Views/Drawer/components/DeepFilter/WordsPhrasesStore";
import MessageStore from "components/ManagerInteractions/Stores/MessageStore";
import _ from "lodash";
import { action, computed, makeObservable, observable, reaction, runInAction } from "mobx";
import moment from "moment";
import {
    ApplicationFilterDomain, ApplicationFiltersService, CreateApplicationFilter,
    UpdateApplicationFilter
} from "services/ApplicationFiltersService";
import { ServiceError } from "services/BaseService";
import { ConversationsSafetyEvent } from "services/ConversationService";
import { uuidv4 } from "utils/helpers";
import { AuthStore } from "../AuthStore";
import { AsyncTaskStatus } from "../BaseStore";
import { DateReferenceOption } from "../ComponentStores/DatePickerComponentStore";
import LocalStorage from "../LocalStorage";
import type { IRootStore } from "../RootStore";
import { AcxStore } from "../RootStore";
import { FilterKey } from "./ApplicationFilterItemsStore";
import { ApplicationFiltersInstanceStore } from "./ApplicationFiltersInstanceStore";
import { CallDirection } from "./Filters/CallDirection";
import { EvaluationType } from "./Filters/EvaluationType";
import { HipaaCompliance } from "./Filters/HipaaCompliance";
import { MediaType } from "./Filters/MediaType";
import { SafetyEvent } from "./Filters/SafetyEvent";
import { Sentiment } from "./Filters/Sentiment";

type SavedConversationsFilters = {
    label: string;
    value: ConversationFilterObject & {
        startDate: string | moment.Moment;
        endDate: string | moment.Moment;
        dateReference: DateReferenceOption;
    };
};

export type DateRangeLabel =
    | "Any Time"
    | "This Quarter"
    | "This Month"
    | "This Week"
    | "Today"
    | "Yesterday"
    | "Last 30 Days"
    | "";

export interface SimpleTopicCluster {
    id: string;
    topicLabel: string;
}

export interface ContactTypeOption {
    value: string;
    label: string;
}

export interface ApplicationFilters {
    startDate?: string | moment.Moment;
    endDate?: string | moment.Moment;
    dateReferenceOption: DateReferenceOption;
    dateRangeLabel?: DateRangeLabel;
    minCallDuration?: number;
    maxCallDuration?: number;
    evaluationTypes: EvaluationType[];
    hierarchyIds: string[];
    selectedCategories?: string[];
    selectedSubcategories?: string[];
    agentIds?: string[];
    mediaTypes: MediaType[];
    callDirections: CallDirection[];
    clientCallId?: string;
    meta1?: string;
    meta2?: string;
    meta3?: string;
    meta4?: string;
    meta5?: string;
    extendedMetadata?: Record<string, string>;
    beginningSentiment?: Sentiment;
    endingSentiment?: Sentiment;
    eddyEffectStatus?: boolean;
    safetyEvent?: SafetyEvent;
    hipaaCompliance?: HipaaCompliance;
    adverseEvent?: boolean;
    topics: SimpleTopicCluster[];
    containsTopics: boolean;
    wordsAndPhrasesSearchString?: string;
    wordsAndPhrasesItems: IWordsPhrasesModel[];
    wordsAndPhrasesIsInputStandard?: boolean;
    rbcFilterItems: IRBCFilter[];
    contactTypes: string[];
    favoriteConversations?: boolean;
}

export interface SavedFilter {
    id: string;
    name: string;
    userId?: string;
    createdBy?: string;
    value: ApplicationFilters;
    domain: ApplicationFilterDomain;
}

export interface LocalSavedFilter {
    id: string;
    label: string;
    filters: ApplicationFilters;
}

interface LastSelectedFilters {
    id?: never;
    name?: never;
    filters: ApplicationFilters;
}

//

@AcxStore
export class ApplicationFiltersStore extends ApplicationFiltersInstanceStore {
    public static Tasks = {
        VALIDATE_WORDS_AND_PHRASES: "Validate Words and Phrases",
        PERSIST_LOCAL_FILTERS: "Persist Local Filters",
        LOAD_SAVED_FILTERS: "Load Saved Filters",
        SAVE_FILTER: "Save Filter",
        UPDATE_FILTER: "Update Filter",
        DELETE_FILTER: "Delete Filter",
        LOAD_AGENTS: "Load Agents",
    } as const;

    public static AllSavedFilterDomains = [
        ApplicationFilterDomain.Signals,
        ApplicationFilterDomain.Conversations,
        ApplicationFilterDomain.User,
        ApplicationFilterDomain.Organization
    ] as const satisfies readonly ApplicationFilterDomain[];

    private static SAVED_FILTERS_KEY = "SavedFilters";
    private static LAST_SELECTED_FILTERS_KEY = "LastSelected";
    private static OLD_CONVERSATIONS_SAVED_FILTERS_KEY = "filterItems";
    private static OLD_CONVERSATIONS_BACKUP_FILTERS_KEY =
        "backupConversationFilters";

    readonly authStore: AuthStore;
    @observable quickFiltersStore: ApplicationFiltersInstanceStore;

    readonly applicationFiltersService: ApplicationFiltersService;

    localStorage: LocalForage;
    conversationsLocalStorage: LocalForage;

    @observable savedFiltersByDomain: Record<
        ApplicationFilterDomain,
        SavedFilter[]
    > = {
        Organization: [],
        User: [],
        Conversations: [],
        Signals: [],
    };
    @computed get savedFilters() {
        return Object.values(this.savedFiltersByDomain).flatMap(
            (filters) => filters,
        );
    }
    @computed get mySavedFilters() {
        const filters = [...this.savedFiltersByDomain.User, ...this.savedFiltersByDomain.Conversations, ...this.savedFiltersByDomain.Signals]
        return filters.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
    }
    @computed get sharedSavedFilters() {
        return this.savedFiltersByDomain.Organization;
    }
    @computed get ownedSavedFilters() {
        return this.savedFilters.filter((filter) => filter.userId === this.authStore._user.profile.sub)
    }
    @computed get notOwnedSavedFilters() {
        return this.savedFilters.filter((filter) => filter.userId !== this.authStore._user.profile.sub)
    }

    @observable selectedSavedFilter?: SavedFilter;
    
    override get dateReference() {
        return this.quickFiltersStore?.dateReferenceOption ?? this.dateReferenceOption
    }

    private organizationId: string;

    constructor(public rootStore: IRootStore) {
        super(rootStore, "Application Filters");
        makeObservable(this);

        // Default user to interaction date
        this.setDateReferenceOption(DateReferenceOption.InteractionDate);

        this.authStore = rootStore.getStore(AuthStore);
        this.localStorage = rootStore
            .getStore(LocalStorage)
            .getLocalStore("ApplicationFilters");
        this.conversationsLocalStorage = rootStore
            .getStore(LocalStorage)
            .getLocalStore("ConversationsStore");

        this.applicationFiltersService = new ApplicationFiltersService();

        reaction(
            () => this.authStore.orgStore.selectedOrganization,
            async (organization) => {
                if (!organization) return;

                const oldOrganizationId = this.organizationId;
                if (oldOrganizationId) {
                    // save filters on org change
                    this.saveCurrentAsLastSelectedFilters(); 
                }

                this.organizationId = organization.id;

                this.resetForOrganizationChange();
                this.applyLastSelectedFilters();
                this.quickFiltersStore = this.toQuickFilters();

                await this.persistAllLocalFilters();
                this.cleanupAllLocalFilters();
                this.loadSavedFiltersByDomain();
            },
            { fireImmediately: true },
        );

        // Need to make sure we save current filters when outside sources
        // change
        reaction(
            () => [
                this.datePickerStore?.beginDate,
                this.datePickerStore?.endDate,
                this.datePickerStore?.referenceOption,
            ],
            () => this.debouncedSaveCurrentAsLastSelectedFilters(),
        );
    }

    private resetSavedFiltersByDomain() {
        this.savedFiltersByDomain = {
            Organization: [],
            User: [],
            Signals: [],
            Conversations: [],
        };
    }

    private resetForOrganizationChange() {
        this.resetSavedFiltersByDomain();
        this.selectedSavedFilter = undefined;

        this.resetFilters();
    }

    /**
     * ----- Local Filter Support & Persistence -----
     */

    private storageKeyForCurrentOrganization(key: string) {
        return `${key}-${this.organizationId}`;
    }

    private oldConvosStorageKeyForCurrentOrganization(key: string) {
        return `${key}${this.organizationId}`;
    }

    /**
     * Persists any local filters to the database and adds
     * them to savedFiltersByDomain in this store. If a filter
     * fails to persist it's discarded and lost forever. Ignores
     * local filters without a name or an empty string for a name.
     */
    private async persistAllLocalFilters() {
        this.setupAsyncTask(
            ApplicationFiltersStore.Tasks.PERSIST_LOCAL_FILTERS,
            async () => {
                const filters = await this.getLocalSavedFilters();
                const convosFilters =
                    await this.getLocalConversationsSavedFilters();

                const allLocalFilters = [...filters, ...convosFilters];

                if (allLocalFilters.length === 0) return;

                // Would've liked to use Promise.all here but it did not want
                // to cooperate for whatever reason.
                for (const filter of allLocalFilters) {
                    if (!filter.label || filter.label === "") continue;

                    await this.saveLocalFilter(filter);
                }

                await this.cleanupAllLocalFilters();
            },
        );
    }

    private async saveLocalFilter(
        filter: LocalSavedFilter,
    ): Promise<SavedFilter> {
        const createFilter: CreateApplicationFilter = {
            name: filter.label,
            domain: ApplicationFilterDomain.Conversations, // all old local filters were created in/for convos
            value: filter.filters,
        };

        return this.applicationFiltersService.createApplicationFilter(
            createFilter,
        );
    }

    @action
    private async getLocalSavedFilters() {
        let stringifiedFilters = await this.localStorage.getItem<string>(
            this.storageKeyForCurrentOrganization(
                ApplicationFiltersStore.SAVED_FILTERS_KEY,
            ),
        );

        if (!stringifiedFilters) stringifiedFilters = "[]";

        const filters: LocalSavedFilter[] = JSON.parse(stringifiedFilters);
        return filters;
    }

    @action
    private async getLocalConversationsSavedFilters() {
        let stringifiedConversationsFilters =
            await this.conversationsLocalStorage.getItem<string>(
                this.oldConvosStorageKeyForCurrentOrganization(
                    ApplicationFiltersStore.OLD_CONVERSATIONS_SAVED_FILTERS_KEY,
                ),
            );

        if (!stringifiedConversationsFilters)
            stringifiedConversationsFilters = "[]";

        const filters: LocalSavedFilter[] = (
            JSON.parse(
                stringifiedConversationsFilters,
            ) as SavedConversationsFilters[]
        ).map((saved) => ({
            id: uuidv4(),
            label: saved.label,
            filters:
                ApplicationFiltersStore.savedConversationsFiltersToApplicationFilters(
                    saved.value,
                ),
        }));

        return filters;
    }

    /**
     * Removes all local saved filters from browser indexed dbs
     * @returns
     */
    private async cleanupAllLocalFilters() {
        return await Promise.all([
            this.conversationsLocalStorage.removeItem(
                this.oldConvosStorageKeyForCurrentOrganization(
                    ApplicationFiltersStore.OLD_CONVERSATIONS_SAVED_FILTERS_KEY,
                ),
            ),
            this.localStorage.removeItem(
                this.storageKeyForCurrentOrganization(
                    ApplicationFiltersStore.SAVED_FILTERS_KEY,
                ),
            ),
        ]);
    }

    /**
     * ----- End Local Filter Support & Persistence -----
     */

    @computed
    public get isSelectedSavedFilterEditable(): boolean {
        if (!this.selectedSavedFilter) return false;
        return this.authStore._user.profile.sub === this.selectedSavedFilter.userId;
    }

    public getSavedFiltersByDomains(domains: ApplicationFilterDomain[] | readonly ApplicationFilterDomain[]) {
        const filters: SavedFilter[] = [];

        for (const domain of domains) {
            filters.push(...this.savedFiltersByDomain[domain]);
        }

        return filters;
    }

    @action
    public loadSavedFiltersByDomain() {
        return this.setupAsyncTask(
            ApplicationFiltersStore.Tasks.LOAD_SAVED_FILTERS,
            async () => {
                const filtersByDomain =
                    await this.applicationFiltersService.getApplicationFiltersByDomain();

                this.resetSavedFiltersByDomain();

                for (const [domain, filters] of Object.entries(
                    filtersByDomain,
                ) as [ApplicationFilterDomain, SavedFilter[]][]) {
                    if (!domain) continue;

                    this.addSavedFiltersByDomain(filters, domain);
                }
            },
        );
    }

    /**
     * Loads in the last selected filters for the users current organization from session
     * storage. If none exist it will look for backupConversationFilters in session storage.
     * If those exist they will be loaded and then deleted.
     * @returns
     */
    @action
    public applyLastSelectedFilters() {
        const stringifiedFilters = sessionStorage.getItem(
            this.storageKeyForCurrentOrganization(
                ApplicationFiltersStore.LAST_SELECTED_FILTERS_KEY,
            ),
        );

        let filters: LastSelectedFilters;
        if (!stringifiedFilters) {
            const oldBackupFiltersStringified = sessionStorage.getItem(
                ApplicationFiltersStore.OLD_CONVERSATIONS_BACKUP_FILTERS_KEY,
            );

            if (!oldBackupFiltersStringified) return;

            const backupFilters: BackupFilters = JSON.parse(
                oldBackupFiltersStringified,
            );
            if (backupFilters.orgId !== this.organizationId) return;

            sessionStorage.removeItem(
                ApplicationFiltersStore.OLD_CONVERSATIONS_BACKUP_FILTERS_KEY,
            );

            filters = {
                filters:
                    ApplicationFiltersStore.backupConversationsFiltersToApplicationFilters(
                        backupFilters,
                    ),
            };
        } else {
            filters = JSON.parse(stringifiedFilters);
        }

        this.applyExternalFilters(filters.filters);
    }

    @action
    public saveCurrentAsLastSelectedFilters() {
        const filters: LastSelectedFilters = {
            filters: this.toFilterObject(),
        };

        return sessionStorage.setItem(
            this.storageKeyForCurrentOrganization(
                ApplicationFiltersStore.LAST_SELECTED_FILTERS_KEY,
            ),
            JSON.stringify(filters),
        );
    }

    public debouncedSaveCurrentAsLastSelectedFilters = _.debounce(() => {
        if (!this.organizationId) return;
        this.saveCurrentAsLastSelectedFilters();
    }, 500);

    protected override onFilterChange(): void {
        this.debouncedSaveCurrentAsLastSelectedFilters();
    }

    @action
    public applySavedFilters(filtersId: string) {
        if (!this.savedFilters) return;

        const filters = this.savedFilters.find(
            (filters) => filters.id === filtersId,
        );
        if (!filters) return;
        if (typeof filters.value === "string")
            filters.value = JSON.parse(filters.value) as ApplicationFilters;

        this.selectedSavedFilter = filters;
        // If a user saved w/o date range, don't apply it to 
        const quickFilters = _.cloneDeep(filters.value)
        if (!filters.value.startDate && !filters.value.endDate) {
            quickFilters.startDate = this.quickFiltersStore.startDate;
            quickFilters.endDate = this.quickFiltersStore.endDate
        }
        this.quickFiltersStore.applyExternalFilters(quickFilters);
        this.applyExternalFilters(filters.value);

        // make sure we never send filter values they dont have access to
        if (!this.authStore.canUserView("Safety Events Model")) {
            this.setSafetyEvent(undefined);
            this.setAdverseEvent(undefined);
        }

        if (!this.authStore.canUserView("HIPAA Compliance Model")) {
            this.setHipaaCompliance(undefined);
        }

        if (!this.authStore.canUserView("Topics")) {
            this.setTopics([])
        }

        this.applicationFiltersService
            .willReceiveFullData(filters.value)
            .then((willReceiveFullData) => {
                if (willReceiveFullData) return;
                this.rootStore.getStore(MessageStore).logWarning("Some data not shown, due to user permissions.")
            })
    }

    @action
    public async saveCurrentFilters(
        name: string,
        domain: ApplicationFilterDomain = ApplicationFilterDomain.User,
    ): Promise<SavedFilter | AsyncTaskStatus.Error> {
        return this.saveFilters(this, name, domain);
    }

    @action
    public async saveFilters(
        filters: ApplicationFiltersInstanceStore,
        name: string,
        domain: ApplicationFilterDomain = ApplicationFilterDomain.User,
    ) {
        return this.setupAsyncTask(
            ApplicationFiltersStore.Tasks.SAVE_FILTER,
            async () => {
                const createFilter: CreateApplicationFilter = {
                    name,
                    value: filters.toFilterObject(),
                    domain,
                };

                if (!this.savedFiltersByDomain[domain])
                    this.savedFiltersByDomain[domain] = [];

                const savedFilter =
                    await this.applicationFiltersService.createApplicationFilter(
                        createFilter,
                    );

                this.addSavedFiltersByDomain(savedFilter);

                return savedFilter;
            },
        );
    }

    @action
    public async updateFilters(
        id: string,
        filters: ApplicationFiltersInstanceStore,
        name: string,
        domain: ApplicationFilterDomain 
    ) {
        return this.setupAsyncTask(
            ApplicationFiltersStore.Tasks.UPDATE_FILTER,
            async () => {
                const filterObj = filters.toFilterObject();
                const updateFilter: UpdateApplicationFilter = {
                    id,
                    name,
                    domain,
                    value: filterObj,
                };

                await this.applicationFiltersService.updateApplicationFilter(
                    updateFilter,
                );

                filters.saveCurrentFilterState("Update");      
                
                // this ensures that when a filter is updated with a new domain is 
                // in the correct array in `savedFiltersByDomain` 
                const current = this.savedFilters.find((f) => f.id === id)!
                // remove from old domain
                this.savedFiltersByDomain[current.domain] = this.savedFiltersByDomain[current.domain].filter((f) => f.id !== id);
                // add to new domain
                this.savedFiltersByDomain[domain].push({...current, value: filterObj, name, domain });

                for (const filtersByDomain of Object.values(
                    this.savedFiltersByDomain,
                )) {
                    for (let i = 0; i < filtersByDomain.length; i++) {
                        if (filtersByDomain[i].id !== id) {
                            continue;
                        } else {
                            const updated = {
                                ...filtersByDomain[i],
                                value: filterObj,
                                name,
                                domain
                            }
                            filtersByDomain[i] = updated
                            if (this.selectedSavedFilter && this.selectedSavedFilter.id === updated.id)
                                runInAction(() => (this.selectedSavedFilter = updated));
                            return;
                        }
                    }
                }
            },
        );
    }

    @action
    public async updateSelectedSavedFilterWith(instance: ApplicationFiltersInstanceStore = this, name?: string, domain?: ApplicationFilterDomain): Promise<boolean> {
        const savedFilter = this.selectedSavedFilter;

        if (!savedFilter || !savedFilter.id)
            return false;

        const result =
            await this.updateFilters(
                savedFilter.id,
                instance, 
                name ?? savedFilter.name,
                domain ?? savedFilter.domain
            );

  

        return result !== AsyncTaskStatus.Error;
    }

    @action
    public async deleteFilters(ids: string[]) {
        const result = await this.setupAsyncTask(ApplicationFiltersStore.Tasks.DELETE_FILTER, async () => {
            try {
                return this.applicationFiltersService.deleteApplicationFilters(ids)
            } catch (e: any) {
                if (e instanceof ServiceError && e.errorMessage) {
                    this.rootStore.getStore(MessageStore).logError(e.errorMessage);
                }
                return AsyncTaskStatus.Error;
            }
        })

        const success = result !== AsyncTaskStatus.Error;
        if (!success) return false

        for (const [domain, filters] of Object.entries(this.savedFiltersByDomain)) {
            this.savedFiltersByDomain[domain] = filters.filter((f) => !ids.includes(f.id))
        }

        if (this.selectedSavedFilter && ids.includes(this.selectedSavedFilter.id))
            this.setSelectedSavedFilters();

        return true;
    }

    @action
    public setSelectedSavedFilters(
        filters?: SavedFilter,
    ) {
        this.selectedSavedFilter = filters;
    }

    @action
    public addSavedFiltersByDomain(
        filters: SavedFilter | SavedFilter[],
        domain?: ApplicationFilterDomain,
    ) {
        const filtersArray = Array.isArray(filters) ? filters : [filters];
        for (const filter of filtersArray) {
            const existingSavedFilters =
                this.savedFiltersByDomain[domain ?? filter.domain] ?? [];

            existingSavedFilters.push(filter);
            this.savedFiltersByDomain[domain ?? filter.domain] =
                existingSavedFilters;
        }
    }

    @action
    public setSavedFilters(filters: SavedFilter[]) {
        this.resetSavedFiltersByDomain();

        for (const filter of filters) {
            this.savedFiltersByDomain[filter.domain].push(filter);
        }

        this.forceUpdateSelectedSavedFilter();
    }

    /**
     * Finds the associated saved filter in the
     * `savedFilters` array (using id) and updates the
     * selected saved filter with that value
     * @returns
     */
    @action
    public forceUpdateSelectedSavedFilter() {
        if (!this.selectedSavedFilter) return;

        const id = this.selectedSavedFilter.id;
        if (!id) return;

        this.selectedSavedFilter = this.savedFilters.find(
            (filter) => filter.id === id,
        );
    }

    public getSavedFilterById(id?: string): SavedFilter | undefined {
        if (!id) return;

        return this.savedFilters.find((filter) => filter.id === id)
    }

    private instanceCounter: number = 0;
    /**
     * Returns a new ApplicationFiltersInstanceStore where you can
     * freely modify the filter values without affecting the global filters
     * contained within this store
     */
    public toInstance(): ApplicationFiltersInstanceStore {
        const instance = new ApplicationFiltersInstanceStore(
            this.rootStore,
            `Application Filters Instance Store ${++this.instanceCounter}`,
        );

        instance.applyExternalFilters(_.cloneDeep(super.toFilterObject()));

        return instance;
    }

    protected toQuickFilters(): ApplicationFiltersInstanceStore {
        const instance = new ApplicationFiltersInstanceStore(this.rootStore, "Quick Filters Store")

        // we don't allow quick filters to have an undefined date.
        const filters = _.cloneDeep(super.toFilterObject());
        if (!filters.startDate && !filters.endDate) {
            filters.startDate = instance.startDate;
            filters.endDate = instance.endDate;
        }

        instance.applyExternalFilters(filters);

        return instance;
    }

    public override toFilterObject(): ApplicationFilters {        
        const filterObject = super.toFilterObject();
        if (!this.quickFiltersStore) return filterObject;

        filterObject.startDate = this.quickFiltersStore.startDate;
        filterObject.endDate = this.quickFiltersStore.endDate;
        filterObject.dateReferenceOption = this.quickFiltersStore.dateReference;
        filterObject.dateRangeLabel = this.quickFiltersStore.dateRangeLabel;
        filterObject.hierarchyIds = this.quickFiltersStore.hierarchyIds;
        filterObject.selectedCategories = this.quickFiltersStore.selectedCategories;
        filterObject.selectedSubcategories = this.quickFiltersStore.selectedSubcategories;
        return filterObject;
    }

    private quickFilterKeys: FilterKey[] = ["dateRange", "dateReferenceOption", "hierarchyIds"]

    public alertOfQuickFilterOverride(filterKey: FilterKey) {
        if (this.appliedFilterCount === 0) return;
        if (filterKey === "hierarchyIds" && (!this.hierarchyIds || this.hierarchyIds.length === 0)) return;
        if (this.quickFilterKeys.includes(filterKey))
            this.rootStore.getStore(MessageStore).logInfo("Page-wide quick filters are overriding at least one filter selection.")
    }

    public override applyExternalFilters(filters: ApplicationFilters, applyToQuickFilters?: boolean): void {
        super.applyExternalFilters(filters);
        if (!this.quickFiltersStore || !applyToQuickFilters) return;
        this.quickFiltersStore.setStartDate(filters.startDate);
        this.quickFiltersStore.setEndDate(filters.endDate);
        this.quickFiltersStore.setDateReferenceOption(filters.dateReferenceOption);
        this.quickFiltersStore.setDateRangeLabel(filters.dateRangeLabel ?? "");
        this.quickFiltersStore.setHierarchyIds(filters.hierarchyIds ?? []);
        this.quickFiltersStore.setSelectedCategories(filters.selectedCategories ?? [], filters.selectedSubcategories ?? []);
    }


    /* 
        Below here is support for existing conversations filtering logic.
    */

    static conversationsSafetyEventConversion(
        safetyEvent: ConversationsSafetyEvent | null,
    ) {
        switch (safetyEvent) {
            case ConversationsSafetyEvent.SafetyEventNotIdentified:
                return SafetyEvent.NotIdentified;
            case ConversationsSafetyEvent.SafetyEventIdentifiedAndAcknowledged:
                return SafetyEvent.Acknowledged;
            case ConversationsSafetyEvent.SafetyEventIdentifiedAndNotAcknowledged:
                return SafetyEvent.NotAcknowledged;
            default:
                return undefined;
        }
    }

    static conversationsEvalTypeConversion(
        evalType: ConversationsEvalType | null,
        hasEval?: boolean,
    ): EvaluationType[] {
        // @ts-expect-error at evalType === ""
        if (hasEval !== undefined && (evalType === null || evalType === ""))
            return hasEval ? [] : [EvaluationType.NotEvaluated];

        const evaluationTypes: EvaluationType[] = [];

        switch (evalType) {
            case ConversationsEvalType.BottomOfTheFunnel:
                evaluationTypes.push(EvaluationType.HumanEvaluated);
                break;
            case ConversationsEvalType.MiddleOfTheFunnel:
                evaluationTypes.push(EvaluationType.AIEvaluated);
                break;
        }

        return evaluationTypes;
    }

    static conversationsHipaaConversion(
        hipaa: "Compliant" | "Not Compliant" | "N/A" | null,
    ) {
        switch (hipaa) {
            case "Compliant":
                return HipaaCompliance.Compliant;
            case "Not Compliant":
                return HipaaCompliance.NotCompliant;
            case "N/A":
                return HipaaCompliance.NotApplicable;
        }
    }

    static conversationsSentimentConversion(
        sentiment: "Positive" | "Neutral" | "Negative" | null,
    ) {
        switch (sentiment) {
            case "Positive":
                return Sentiment.Positive;
            case "Neutral":
                return Sentiment.Neutral;
            case "Negative":
                return Sentiment.Negative;
        }
    }

    static conversationsCallDirectionConversion(
        callDirection: "Inbound" | "Outbound" | "Transfer",
    ) {
        switch (callDirection) {
            case "Inbound":
                return CallDirection.Inbound;
            case "Outbound":
                return CallDirection.Outbound;
            case "Transfer":
                return CallDirection.Transfer;
        }
    }

    static conversationsMediaTypeConversion(
        mediaType: "Audio" | "ProcessedChat",
    ) {
        switch (mediaType) {
            case "Audio":
                return MediaType.Audio;
            case "ProcessedChat":
                return MediaType.ProcessedChat;
        }
    }

    static savedConversationsFiltersToApplicationFilters(
        filters: SavedConversationsFilters["value"],
    ): ApplicationFilters {
        return {
            startDate: filters.startDate,
            endDate: filters.endDate,
            dateReferenceOption: filters.dateReference,
            dateRangeLabel: filters.dateRangeLabel,
            minCallDuration: filters.callDuration?.[0],
            maxCallDuration: filters.callDuration?.[1],
            evaluationTypes:
                ApplicationFiltersStore.conversationsEvalTypeConversion(
                    filters.evalType,
                    filters.hasEval,
                ),
            hierarchyIds: filters.hierarchyIds,
            agentIds: filters.agentIds,
            mediaTypes: filters.mediaType.map(
                ApplicationFiltersStore.conversationsMediaTypeConversion,
            ),
            callDirections: filters.callDirection.map(
                ApplicationFiltersStore.conversationsCallDirectionConversion,
            ),
            clientCallId: filters.clientCallId,
            meta1: filters.meta1,
            meta2: filters.meta2,
            meta3: filters.meta3,
            meta4: filters.meta4,
            meta5: filters.meta5,
            extendedMetadata: filters.extendedMetadataFields?.reduce(
                (previous, item) => {
                    previous[item.item1] = item.value;
                    return previous;
                },
                {},
            ),
            beginningSentiment:
                ApplicationFiltersStore.conversationsSentimentConversion(
                    filters.begSentiment,
                ),
            endingSentiment:
                ApplicationFiltersStore.conversationsSentimentConversion(
                    filters.endSentiment,
                ),
            eddyEffectStatus: filters.hasEddyEffect,
            safetyEvent:
                ApplicationFiltersStore.conversationsSafetyEventConversion(
                    filters.safetyEvent,
                ),
            hipaaCompliance:
                ApplicationFiltersStore.conversationsHipaaConversion(
                    filters.hipaa,
                ),
            topics: filters.topicsFilter?.topics,
            containsTopics: filters.topicsFilter?.contains,
            wordsAndPhrasesItems: initWordsPhrasesItems,
            wordsAndPhrasesIsInputStandard: false,
            wordsAndPhrasesSearchString: filters.searchPhrase,
            rbcFilterItems: filters.RBCFilterItems,
            contactTypes: filters.contactTypes,
        };
    }

    static backupConversationsFiltersToApplicationFilters(
        backupFilters: BackupFilters,
    ) {
        const combinedFilters: SavedConversationsFilters["value"] = {
            ...backupFilters.filterObject,
            startDate: backupFilters.beginDate,
            endDate: backupFilters.endDate,
            dateReference: backupFilters.referenceOption,
        };

        const filters =
            ApplicationFiltersStore.savedConversationsFiltersToApplicationFilters(
                combinedFilters,
            );
        filters.wordsAndPhrasesItems = backupFilters.wordsPhrases.items;
        filters.wordsAndPhrasesIsInputStandard =
            backupFilters.wordsPhrases.isInputStandard;

        return filters;
    }
}
