import { Grid, Paper, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import LocationSearchingIcon from "@mui/icons-material/LocationSearching";
import { AudioFileSamplerStore } from "components/Admin/AdminUIComponents/AudioFileSampler/Stores/AudioFileSamplerStore";
import { RecommendationStepStore } from "components/Admin/AdminUIComponents/AudioFileSamplerV2/Stores/RecommendationStepStore";
import { SourceFilesStepStore } from "components/Admin/AdminUIComponents/AudioFileSamplerV2/Stores/SourceFilesStepStore";
import { stringToAllotment } from "components/Admin/AdminUIComponents/AudioFileSamplerV2/Views/RecommendationStep/StartSamplingDrawerContent";
import AcxButton from "components/UI/AcxButton";
import AcxDataGrid from "components/UI/AcxDataGrid/AcxDataGrid";
import AcxLoadingIndicator from "components/UI/AcxLoadingIndicator";
import { observer } from "mobx-react";
import AudioMetadataModel from "models/AudioMetadataModel";
import React from "react";
import { AuthStore } from "stores/AuthStore";
import useStyles from "Styles/Styles";
import { useStore } from "utils/useStore";
import AcxDialog from "../../../UI/Dialog/AcxDialog";
import AcxWordCloud from "../../../UI/Visualization/AcxWordCloud";
import ModelDashboardStore from "../Stores/ModelDashboardStore";
import BuildChartForModelData from "../Utils/BuildChartForModelData";

interface IModelDashboardPrimaryContentProps {
    store: ModelDashboardStore;
}

const styles = () =>
    createStyles({
        paper: {
            padding: "1rem",
            width: "100%",
            height: "100%",
            margin: "0.5rem 0",
        },
    });

const ModelDashboardPrimaryContent: React.FC<IModelDashboardPrimaryContentProps> =
    observer(({ store }) => {
        const classes = useStyles(styles);
        const drillEddySampleTooltip =
            "You may only sample up to 10 files at a time";

        const authStore = useStore(AuthStore);
        const recommendationStepStore = useStore(RecommendationStepStore);
        const sourceFilesStepStore = useStore(SourceFilesStepStore);
        const audioFileSamplerStore = useStore(AudioFileSamplerStore);

        store.selectedDataDgStore.pagination = false;

        // drill eddy audio sampler btn
        const handleDrillEddyAudioFiles = () => {
            sourceFilesStepStore.isFromOutsideSampler = true;
            sourceFilesStepStore.orgSelectStore.selectOrg(
                authStore.orgStore.selectedOrganization,
            );
            audioFileSamplerStore.orgId =
                authStore.orgStore.selectedOrganization?.id;

            const selectedDrillEddyData =
                store.selectedDataDgStore.selectedRows.map((row) => {
                    const splitFilePath = row.filePath.split("/");
                    const eddyInteractionFilePath =
                        splitFilePath[splitFilePath.length - 1];
                    const eddyInteractionStatus =
                        row.isReadyToSample === true
                            ? "Ready To Sample"
                            : "Sampled";
                    return {
                        fileName: eddyInteractionFilePath,
                        agentName: row.agentName,
                        callDurationMillis: row.callLength,
                        mediaType: row.mediaType,
                        callDirection: row.callDirection,
                        id: row.audioMetadataId,
                        timestamp: row.createdDate,
                        status: eddyInteractionStatus,
                    } as AudioMetadataModel;
                });

            recommendationStepStore.tableStore.setItems(selectedDrillEddyData);
            recommendationStepStore.tableStore.setSelectedItems(
                selectedDrillEddyData.map((row) => row.id),
            );

            const existing =
                recommendationStepStore.hierarchyAssignmentDistribution.get(
                    0,
                ) ?? {
                    selections: [],
                    count: "0",
                };
            existing.count = stringToAllotment(
                recommendationStepStore.tableStore.selectedItems.length.toString(),
            );
            recommendationStepStore.hierarchyAssignmentDistribution.set(
                0,
                existing,
            );
            recommendationStepStore.useSample();
            store.selectedDataDgStore.clearSelected();
            // refresh the drill eddy interactions list with updated sample status after sampling
            setTimeout(() => {
                store.onSelect(
                    store.prevOnselectData.series,
                    store.prevOnselectData.x,
                    store.prevOnselectData.y,
                    store.prevOnselectData.chartId,
                    store.prevOnselectData.metadata,
                );
            }, 3000);
        };

        return (
            <>
                {store.availableModelData &&
                store.availableModelData.length > 0 ? (
                    <Grid container>
                        {store.availableModelData?.map((model) => {
                            if (
                                model.chartName ===
                                "Aggregated Eddy Effect Topic Word Cloud"
                            ) {
                                return (
                                    <Grid
                                        key={model.chartName}
                                        container
                                        item
                                        xs={12}
                                        style={{
                                            margin: "0.5rem 0",
                                            height: "500px",
                                        }}
                                    >
                                        <Paper className={classes.paper}>
                                            <Typography variant="h2">
                                                {model.chartName}
                                            </Typography>
                                            <AcxWordCloud
                                                mainColor="blue"
                                                data={
                                                    store.selectedWordCloudDataForAggregatedCard
                                                }
                                                alternateTooltip
                                                maxWords={150}
                                            />
                                        </Paper>
                                    </Grid>
                                );
                            } else {
                                return (
                                    <Grid
                                        key={`${model.chartName}_${model.chartType}`}
                                        container
                                        item
                                        xs={12}
                                        style={{
                                            margin: "0.5rem 0",
                                            height: "500px",
                                        }}
                                    >
                                        <BuildChartForModelData
                                            chartName={model.chartName}
                                            chartType={model.chartType}
                                            data={model.data}
                                            xTitle={model.xTitle}
                                            yTitle={model.yTitle}
                                            store={store}
                                            chartId={model.chartId}
                                        />
                                    </Grid>
                                );
                            }
                        })}
                    </Grid>
                ) : store.isLoading() ? (
                    <AcxLoadingIndicator
                        color="secondary"
                        alternate="PuffLoader"
                        size={75}
                    />
                ) : (
                    <></>
                )}

                <AcxDialog
                    title="Word Cloud"
                    text=""
                    dialogContentChildren={
                        <AcxWordCloud
                            mainColor="blue"
                            data={store.selectedWordCloudData}
                            alternateTooltip
                        />
                    }
                    isOpen={store.showWordCloudDialog}
                    onClose={() => store.toggleShowWordCloudDialog(null)}
                    contentWidth="600px"
                />

                <AcxDialog
                    title={store.drillEddyDialogTitle}
                    text=""
                    dialogContentChildren={
                        store.getTaskLoading("Load Drill Down Data") ? (
                            <AcxLoadingIndicator
                                alternate="PuffLoader"
                                size={75}
                            />
                        ) : (
                            <Grid style={{ height: "75vh" }}>
                                <AcxButton
                                    tooltip={drillEddySampleTooltip}
                                    color="primary"
                                    onClick={() => handleDrillEddyAudioFiles()}
                                    style={{ marginBottom: "-6px" }}
                                    disabled={
                                        store.selectedDataDgStore.selectedRows
                                            .length === 0 ||
                                        store.selectedDataDgStore.selectedRows
                                            .length > 10
                                    }
                                    containerStyle={{
                                        width: "215px",
                                    }}
                                >
                                    <LocationSearchingIcon
                                        fontSize="small"
                                        style={{ marginRight: "1rem" }}
                                    />
                                    Sample Selected Files
                                </AcxButton>
                                <AcxDataGrid
                                    dataGridStore={store.selectedDataDgStore}
                                />
                            </Grid>
                        )
                    }
                    isOpen={store.showSelectedDataDialog}
                    onClose={() => store.toggleShowSelectedData()}
                    contentWidth="90vw"
                    maxWidth="xl"
                    disableEnforceFocus
                />
            </>
        );
    });

export default ModelDashboardPrimaryContent;
