import { Grid, IconButton, Theme, Tooltip, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { observer } from "mobx-react";
import { CombinatorRuleType } from "models/RuleCombinator";
import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import InfoIcon from "@mui/icons-material/Info";
import AcxButton from "components/UI/AcxButton";
import WordsPhraseStore from "./WordsPhrasesStore";
import WordsPhraseItem from "./WordsPhrasesItem";
import { blueButtonWhiteBackgroundStyle } from "utils/Style";
import AcxMainTextField from "components/UI/AcxMainTextField";
import AcxWideTooltip from "components/UI/AcxWideTooltip";
import theme from "Theme/AppTheme";

const useWordsPhrasesStyles = makeStyles((theme: Theme) => ({
    labelText: { fontWeight: "bold" },
    addIcon: {
        border: "1px solid #E4E4E7",
        borderRadius: "4px",
    },
    containerPadding: { paddingBottom: theme.spacing(1) },
    icon: {
        height: "16px",
        width: "16px",
        marginLeft: "4px",
    },
}));

interface Props {
    store: WordsPhraseStore;
}

const WordsPhraseInput: React.FunctionComponent<Props> = observer(
    ({ store }) => {
        const classes = useWordsPhrasesStyles();

        const [isAddingItem, setIsAddingItem] = useState(false);

        const handleAdd = (combinator: CombinatorRuleType) => {
            store.addWordsPhrasesItem(combinator);
            setIsAddingItem(false);
        };

        const wordsPhrasesTooltipHTML = (
            <>
                <p>Select a condition and type a word or phrase.</p>
                <ul>
                    <li>
                        To search for a phrase, type it into the box: i.e. have
                        a nice day
                    </li>
                    <li>
                        If you'd like to search for multiple words separately,
                        add another condition and select AND or OR (i.e. have
                        AND nice AND day)
                    </li>
                    <li>
                        When using Starts With, you may only use one word. i.e.
                        insur would find results including insurance, insure, or
                        insures
                    </li>
                </ul>
            </>
        );

        const renderLabel = (
            <Grid item container alignItems="center" xs={10}>
                <Typography className={classes.labelText} component="span">
                    Words or Phrases
                </Typography>
                <span>
                    {store.isInputStandard ? (
                        <AcxWideTooltip title={wordsPhrasesTooltipHTML}>
                            <Grid container alignItems="center">
                                <InfoIcon
                                    sx={{
                                        height: "16px",
                                        width: "16px",
                                        marginLeft: "4px",
                                    }}
                                    color="primary"
                                />
                            </Grid>
                        </AcxWideTooltip>
                    ) : (
                        <Tooltip title="The advanced editor allows you to write your own search parameters from scratch using Full Text Search syntax, rather than using the conditions and search boxes in the standard editor.">
                            <Grid container alignItems="center">
                                <InfoIcon
                                    sx={{
                                        height: "16px",
                                        width: "16px",
                                        marginLeft: "4px",
                                    }}
                                    color="primary"
                                />
                            </Grid>
                        </Tooltip>
                    )}
                </span>
            </Grid>
        );

        const renderAddBlockButton = (
            <Grid item>
                <IconButton
                    size="small"
                    onClick={() => {
                        if (store.wordsPhrasesItems.length === 0) {
                            store.addWordsPhrasesItem(0);
                        } else {
                            setIsAddingItem(true);
                        }
                    }}
                >
                    <AddIcon
                        sx={{
                            border: "1px solid #E4E4E7",
                            borderRadius: "4px",
                        }}
                    />
                </IconButton>
            </Grid>
        );

        const renderAddAndOrButtons = (
            <Grid container style={{ marginTop: "8px" }}>
                <Grid item style={{ marginRight: "10px" }}>
                    <AcxButton
                        fullWidth
                        id="words-phrases-filter-add-and-button"
                        onClick={() => handleAdd(CombinatorRuleType.And)}
                        style={blueButtonWhiteBackgroundStyle}
                    >
                        + AND
                    </AcxButton>
                </Grid>
                <Grid item>
                    <AcxButton
                        fullWidth
                        id="words-phrases-filter-add-or-button"
                        onClick={() => handleAdd(CombinatorRuleType.Or)}
                        style={blueButtonWhiteBackgroundStyle}
                    >
                        + OR
                    </AcxButton>
                </Grid>
            </Grid>
        );

        return (
            <>
                <Grid
                    container
                    item
                    justifyContent={
                        store.isInputStandard ? "space-between" : "flex-start"
                    }
                    alignItems="center"
                    className={classes.containerPadding}
                    xs={12}
                >
                    {renderLabel}
                    {store.isInputStandard && renderAddBlockButton}
                </Grid>
                {store.isInputStandard ? (
                    <>
                        {store.wordsPhrasesItems.map((item, index) => (
                            <WordsPhraseItem
                                item={item}
                                index={index}
                                key={index}
                                store={store}
                            />
                        ))}
                        {isAddingItem && renderAddAndOrButtons}
                    </>
                ) : (
                    <AcxMainTextField
                        id="advanced-words-phrases-input"
                        value={store.wordsPhrasesString}
                        onChange={(e) => {
                            store.setWordsPhrasesString(e.target.value);
                        }}
                        multiline
                        placeholderText="Type to add..."
                        rowsMin={1}
                        rowsMax={3}
                        containerStyle={{ width: "100%" }}
                    />
                )}
                <Grid container item xs={2}>
                    <AcxButton
                        fullWidth
                        id="words-phrases-filter-toggle-advanced-button"
                        onClick={() => {
                            store.wordsPhrasesString
                                ? store.toggleConfirmationModal()
                                : store.toggleStandardInput();
                        }}
                        style={{
                            backgroundColor: theme.palette.white.main,
                            color: theme.palette.blue.main,
                            padding: 0,
                            justifyContent: "flex-start",
                        }}
                    >
                        {store.isInputStandard ? "Advanced" : "Standard"}
                    </AcxButton>
                </Grid>
            </>
        );
    },
);

export default WordsPhraseInput;
