import BaseService from "./BaseService";

export interface IHierarchy {
    id: string;
    name: string;
    isLeaf: boolean;
    // permissions: EvaluationPermission[];
    // types: InteractionType[];
    isActive: boolean | null;
    parentMemberId?: string;
    branchDepth: number;
    hasPerms?: boolean;
    childrenCount?: number;
}

interface IGetAllChildrenForHierarchyPayload {
    rowCount: number;
    childrenHierarchies: IHierarchy[];
}

interface IHierarchyAndOrgIdPayload {
    childrenHierarchies: IHierarchy[];
    organizationId: string;
}

export class HierarchyService extends BaseService {
    protected urlBase: string = "api/Hierarchy";

    public async updateHierarchyForEval(evalId: string, hierarchyId: string) {
        const params = new URLSearchParams({ evalId, hierarchyId });
        const res = await this.put(params, "UpdateHierarchyForEvalAsync");

        return res;
    }

    public async getHierarchyCategories() {
        const res = await this.get<string[]>(
            new URLSearchParams({}),
            "GetHierarchyCategories",
        );
        const sortedAndFilteredResponse = res.filter((value) => !!value).sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))

        return sortedAndFilteredResponse;
    }

    public async getHierarchySubcategories() {
        const res = await this.get<string[]>(
            new URLSearchParams({}),
            "GetHierarchySubcategories",
        );

        const sortedAndFilteredResponse = res.filter((value) => !!value).sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))

        return sortedAndFilteredResponse;
    }

    public async getTopLevelHierarchiesForUser(
        showInactive?: boolean,
        fromHierarchyIds?: string[],
    ): Promise<IHierarchyAndOrgIdPayload> {
        const params = new URLSearchParams();

        if (showInactive) {
            params.set("showInactive", String(showInactive));
        }

        const res = await this.post<IHierarchyAndOrgIdPayload>(
            fromHierarchyIds ? JSON.stringify(fromHierarchyIds) : "",
            params,
            "GetTopLevelHierarchiesForUser",
        );
        return res;
    }

    public async searchHierarchiesForUserAsync(
        search: string,
        showInactive?: boolean,
    ): Promise<IHierarchyAndOrgIdPayload> {
        const params = new URLSearchParams({
            search,
        });

        if (showInactive) {
            params.set("showInactive", String(showInactive));
        }
        const res = await this.get<IHierarchyAndOrgIdPayload>(
            params,
            "HierarchyNameSearchByUserAsync",
        );
        return res;
    }

    public async searchHierarchiesByCategoryAsync(
        search: string,
        includeInactive?: boolean,
    ): Promise<IGetAllChildrenForHierarchyPayload> {
        const params = new URLSearchParams({
            search,
        });

        if (includeInactive) {
            params.set("includeInactive", String(includeInactive));
        }
        const res = await this.get<IGetAllChildrenForHierarchyPayload>(
            params,
            "HierarchyNameSearchByCategoryAsync",
        );
        return res;
    }

    public async searchHierarchiesBySubCategoryAsync(
        search: string,
        includeInactive?: boolean,
    ): Promise<IGetAllChildrenForHierarchyPayload> {
        const params = new URLSearchParams({
            search,
        });

        if (includeInactive) {
            params.set("includeInactive", String(includeInactive));
        }
        const res = await this.get<IGetAllChildrenForHierarchyPayload>(
            params,
            "HierarchyNameSearchBySubCategoryAsync",
        );
        return res;
    }

    // confirm this is not used
    public async getAllHierarchiesForUser(): Promise<IHierarchyAndOrgIdPayload> {
        const res = await this.get<IHierarchyAndOrgIdPayload>(
            new URLSearchParams(),
            "GetAllHierarchiesForUser",
        );
        return res;
    }

    public async getAllChildrenForHierarchyAsync(
        osmId: string,
        showInactive?: boolean,
    ): Promise<IGetAllChildrenForHierarchyPayload> {
        const params = new URLSearchParams({
            osmId,
        });

        if (showInactive) {
            params.set("showInactive", String(showInactive));
        }

        const res = await this.get<IGetAllChildrenForHierarchyPayload>(
            params,
            "GetServiceHierarchyChildrenAsync",
        );
        return res;
    }
}
