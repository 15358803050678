import { Grid, Paper, Typography } from "@mui/material";
import AcxButton from "components/UI/AcxButton";
import AcxLoadingIndicator from "components/UI/AcxLoadingIndicator";
import AcxTruncate from "components/UI/AcxTruncate";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { BeatLoader } from "react-spinners";
import useStyles from "Styles/Styles";
import theme from "../../../Theme/AppTheme";
import { extractGuid } from "../../../utils/StringUtils";
import { styles } from "../ClassifierBuilder/ClassifierBuilder";
import { SearchResult } from "../ClassifierBuilder/Search/ClassifierBuilderSearchController";
import { LuceneIndexSearchStore } from "./LuceneIndexSearchStore";

interface Props {
    isLoading: boolean;
    searchResults?: SearchResult[];
    store: LuceneIndexSearchStore;
}

function getTranscriptionId(result: SearchResult) {
    const fileName = result.metadata_storage_name;

    if (!fileName) {
        return null;
    }

    return extractGuid(fileName);
}

interface IndexableTranscription {
    txDocument: string;
    speaker: string;
    channel: string;
}

function formatHtmlTranscription(html: string): string {
    function fallbackFormatting(text: string): string {
        if (text.indexOf("txDocument") > -1) {
            text = text.substring(text.indexOf("txDocument") + 13); // txDcoument\":\ accounts for 13 characters
        }

        if (text.indexOf("}") > -1) {
            text = text.replaceAll("}", "");
        }

        if (text.indexOf("]") > -1) {
            text = text.replaceAll("]", "");
        }
        return text;
    }

    html = html.replaceAll("< div", "<div"); // azure messes up this tag WHO KNOWS WHY NOT ME - CR
    html = html.replaceAll('class="highlighted"', "class='highlighted'");
    html = html.replaceAll(
        "<div class='highlighted'>Combined</div>",
        "Combined",
    );
    html = html.replaceAll("<div class='highlighted'>speaker</div>", "speaker");
    html = html.replaceAll("<div class='highlighted'>channel</div>", "channel");
    html = html.replaceAll(
        "<div class='highlighted'>txDocument</div>",
        "txDocument",
    );

    if (html.indexOf("txDocument") === -1) {
        return fallbackFormatting(html);
    }

    try {
        const indexable = JSON.parse(html) as IndexableTranscription[];
        const combinedTranscription = indexable.find(
            (value) =>
                value.speaker === "Combined" || value.channel === "Combined",
        );

        return combinedTranscription?.txDocument ?? fallbackFormatting(html);
    } catch (e) {
        return fallbackFormatting(html);
    }
}

const LuceneIndexSearchResults = observer((props: Props) => {
    const classes = useStyles(styles);
    const [editorLoading, setEditorLoading] = useState(false);

    React.useEffect(() => {
        props.store.anyTaskLoading
            ? setEditorLoading(true)
            : setEditorLoading(false);
    }, [props.store.anyTaskLoading]);

    const renderResult = (result: SearchResult, index: number) => {
        const transcriptionId = getTranscriptionId(result);

        const onTranscriptionToAudioEditor = () => {
            if (transcriptionId) {
                props.store.loadAudioMetadataForTranscription(
                    transcriptionId,
                    formatHtmlTranscription(
                        result.audioFileResults?.[0].combinedResults?.[0]
                            ?.display ?? "",
                    ),
                );
            }
        };

        const noAudio = Boolean(
            result.metadata_storage_name?.toLowerCase().includes("chat") ||
                result.metadata_storage_path?.toLowerCase().includes("chat"),
        );

        let html = result.audioFileResults[0].combinedResults[0].display;
        html = formatHtmlTranscription(html);

        return (
            <Paper className={classes.component} key={"result_" + index}>
                <Grid container item xs={12}>
                    <Grid
                        container
                        item
                        xs={10}
                        justifyContent="flex-start"
                        alignItems="center"
                    >
                        <div
                            style={{ marginBottom: "0.25rem" }}
                            className={classes.fontWeightBold}
                        >{`#${index + 1}`}</div>

                        {/* azure gives us back html with arbitrary tags for highlighting, so we need to parse it out like this */}
                        <AcxTruncate
                            lineClamp={2}
                            content={
                                <div
                                    dangerouslySetInnerHTML={{ __html: html }}
                                />
                            }
                        />
                    </Grid>

                    <Grid
                        container
                        item
                        xs={2}
                        justifyContent="center"
                        alignItems="center"
                    >
                        {Boolean(transcriptionId) && !noAudio && (
                            <AcxButton
                                fullWidth
                                color="primary"
                                onClick={onTranscriptionToAudioEditor}
                            >
                                <Typography
                                    className={classes.editorLinkButtonText}
                                >
                                    Open Audio
                                </Typography>

                                <BeatLoader
                                    size={6}
                                    color={theme.palette.white.main}
                                    loading={editorLoading}
                                />
                            </AcxButton>
                        )}
                    </Grid>
                </Grid>
            </Paper>
        );
    };

    function renderCount() {
        return (
            <Paper className={classes.component}>
                <div>{props.searchResults?.length ?? 0} results (max 100)</div>
            </Paper>
        );
    }

    if (props.isLoading) {
        return (
            <Grid container item xs={12} style={{ margin: "2rem auto" }}>
                <AcxLoadingIndicator
                    color={"secondary"}
                    alternate={"PuffLoader"}
                    size={175}
                />
            </Grid>
        );
    }

    if (props.searchResults === undefined) return null;

    return (
        <Grid id="lucerne-search-results-wrapper" container item>
            {renderCount()}
            {props.searchResults?.map(renderResult)}
        </Grid>
    );
});

export default LuceneIndexSearchResults;
