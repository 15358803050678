import {
    ApplicationFilters,
    ApplicationFiltersStore,
} from "stores/ApplicationFilters/ApplicationFiltersStore";
import AgentChatStore from "../Stores/AgentChatStore";
import ConversationsStore from "components/Conversations/Stores/ConversationsStore";
import { ApplicationFilterValuesStore } from "stores/ApplicationFilters/ApplicationFilterValuesStore";
import { DateReferenceOption } from "stores/ComponentStores/DatePickerComponentStore";
import { IThreadMessageGroup } from "./ThreadMessageGroup";
import { ThreadMessage } from "./ThreadMessage";

export type ThreadSuggestionAction = (
    store: AgentChatStore,
    suggestion: ThreadSuggestion,
) => void | Promise<void>;

export interface ThreadSuggestion {
    content: string;
    action: ThreadSuggestionAction;
    navigationURL?: string;
}

export type ThreadServerSuggestionActionType = "navigate" | "build_montage";
export type ThreadServerSuggestionNavigationActionDomain = "conversations";

interface ThreadServerSuggestionActionBase {
    type: ThreadServerSuggestionActionType;
    data?: unknown;
}

export interface ThreadServerSuggestionNavigationAction
    extends ThreadServerSuggestionActionBase {
    type: "navigate";
    domain: ThreadServerSuggestionNavigationActionDomain;
    data: unknown;
}

export interface BuildMontageFromClipsData {
    clipIds: string[];
    audioSoundClipsCount?: number;
    minClipDate: string;
    maxClipDate: string;
}

export interface ThreadServerSuggestionBuildMontageAction
    extends ThreadServerSuggestionActionBase {
    type: "build_montage";
    data: BuildMontageFromClipsData;
}

export type ThreadServerSuggestionAction =
    | ThreadServerSuggestionNavigationAction
    | ThreadServerSuggestionBuildMontageAction;

/**
 * Represents a suggestion generated by the agent to take a specific, predefined action
 */
export interface ThreadServerSuggestion {
    content: string;
    action?: ThreadServerSuggestionAction;
}

type AvaFilters = ApplicationFilters &
    Partial<{
        conversationIds: string[];
        topicIds?: string[];
        classifierId?: string;
        wordsPhrasesSearchString?: string;
        dateReference?: number;
    }>;

export function fromServerSuggestion(
    serverSuggestion: ThreadServerSuggestion,
    messageGroup: IThreadMessageGroup,
): ThreadSuggestion | undefined {
    const suggestion: ThreadSuggestion = {
        content: serverSuggestion.content,
        action: defaultAction,
    };

    if (!serverSuggestion.action) return suggestion;

    switch (serverSuggestion.action.type) {
        case "navigate":
            const domain = serverSuggestion.action.domain;
            if (domain === "conversations") {
                const filters = serverSuggestion.action.data as AvaFilters;

                suggestion.action = (store) =>
                    conversationsNavigationAction(store, filters);

                suggestion.navigationURL = "/app/conversations";
            }
            break;
        case "build_montage":
            const clipData = serverSuggestion.action.data;
            if (clipData.audioSoundClipsCount === undefined) return;

            const agentMessage = messageGroup.messages.at(-1) as ThreadMessage;
            if (clipData.audioSoundClipsCount === 0) {
                agentMessage.appendContent(
                    "\n\nPlease note: none of these clips are audio clips and cannot be included in a montage.",
                );
                return;
            } else if (
                clipData.audioSoundClipsCount < clipData.clipIds.length
            ) {
                agentMessage.appendContent(
                    "\n\nPlease note: some of these clips are not audio clips and will not be included in a montage.",
                );
            }

            suggestion.action = async (store, suggestion) => {
                await store.thread
                    .addUserMessageGroup()
                    .addMessage(suggestion.content)
                    .persist();

                await store.thread
                    .addAgentMessageGroup()
                    .addMessage("I'm taking you to montage builder now.")
                    .persist();

                await store.prepareBuildMontage(clipData);
            };
            suggestion.navigationURL = "/app/montagelibrary";
            break;
        default:
            suggestion.action = () => notImplementedAction(serverSuggestion);
    }

    return suggestion;
}

/**
 * Default behavior is to submit the server suggestions content as the user message
 * @param store
 * @param suggestion
 */
const defaultAction: ThreadSuggestionAction = (store, suggestion) => {
    store.streamingSubmitInput({
        manualInput: suggestion.content,
        suggestion: true,
    });
};

function notImplementedAction(serverSuggestion: ThreadServerSuggestion) {
    console.warn(
        "Server suggestion action not implemented yet",
        serverSuggestion,
    );
}

async function conversationsNavigationAction(
    store: AgentChatStore,
    filters: AvaFilters,
) {
    const rootStore = store.rootStore;
    const conversationsStore = rootStore.getStore(ConversationsStore);
    const applicationFiltersStore = rootStore.getStore(ApplicationFiltersStore);

    if (filters.conversationIds) {
        conversationsStore.setConversationIds(filters.conversationIds);
    } else {
        if (filters.topicIds && filters.topicIds.length > 0) {
            const valuesStore = rootStore.getStore(
                ApplicationFilterValuesStore,
            );

            await valuesStore.loadTopics();
            if (valuesStore.topicClusters) {
                const topics = valuesStore.topicClusters.filter((cluster) =>
                    filters.topicIds!.includes(cluster.id),
                );
                filters.topics = topics;
            }
        }

        if (filters.classifierId) {
            filters.rbcFilterItems = [
                {
                    value: true,
                    classifierIds: [filters.classifierId],
                    combinator: 0,
                    filterGroupId: 0,
                },
            ];
        }

        if (filters.wordsPhrasesSearchString) {
            filters.wordsAndPhrasesIsInputStandard = true;
            filters.wordsAndPhrasesSearchString =
                filters.wordsPhrasesSearchString;
            filters.wordsAndPhrasesItems = [
                {
                    value: "Contains",
                    searchPhrase: filters.wordsPhrasesSearchString,
                    combinator: 0,
                    filterGroupId: 0,
                },
            ];
        }

        switch (filters.dateReference) {
            case 0:
                filters.dateReferenceOption =
                    DateReferenceOption.InteractionDate;
                break;
            case 1:
                filters.dateReferenceOption = DateReferenceOption.ArrivalDate;
                break;
        }

        applicationFiltersStore.applyExternalFilters(filters, true);
    }
    conversationsStore.setPageNumberAndGetConversations(1);
}
