import MessageStore from "components/ManagerInteractions/Stores/MessageStore";
import AcxDataGridStore from "components/UI/AcxDataGrid/AcxDataGridStore";
import IColDef from "components/UI/AcxDataGrid/IColDef";
import {
    action,
    computed,
    makeObservable,
    observable,
    reaction,
    runInAction,
    toJS,
} from "mobx";
import LicensedModule, { LMType } from "models/LicensedModule";
import { ApplicationUser } from "models/Permission/ApplicationUser";
import Question from "models/Question";
import { Tag } from "models/Tag";
import { ModuleService } from "services/ModuleService";
import { WorkflowService } from "services/WorkflowService";
import { AuthStore } from "stores/AuthStore";
import { BaseStore } from "stores/BaseStore";
import { LayoutDrawerStore } from "stores/Layout/LayoutDrawerStore";
import { AcxStore } from "stores/RootStore";
import type { IRootStore } from "stores/RootStore";
import { OrganizationWorkflowViews } from "../OrganizationWorkflowsWrapper";
import {
    ContactType,
    CustomContactType,
    DueDateType,
    UserContact,
    WorkflowConditionType,
    WorkflowDefinition,
    WorkflowRecipient,
    WorkflowTemplate,
    WorkflowTemplateDeliveryCadence,
} from "../Types/WorkflowModels";
import type WorkflowCondition from "../Types/WorkflowModels";

export const LOAD_WORKFLOW_DEFINITIONS = "LOAD WORKFLOW DEFINITIONS";
export const LOAD_CONTACT_TYPES = "LOAD CONTACT TYPES";
export const LOAD_RECIPIENTS = "LOAD USER CONTACTS";
export const LOAD_LICENSED_MODULES = "LOAD LICENSED MODULES";
export const LOAD_CONDITIONS = "READ WORKFLOW CONDITIONS";
export const CREATE_CONDITION = "CREATE WORKFLOW CONDITION";
export const UPDATE_CONDITION = "UPDATE WORKFLOW CONDITIONS";

@AcxStore
export default class OrganizationWorkflowsStore extends BaseStore {
    private readonly authStore: AuthStore;
    private readonly moduleService: ModuleService;
    readonly dataGridStore: AcxDataGridStore;
    readonly messageStore: MessageStore;
    readonly workflowService: WorkflowService;
    readonly recipientTypes = [
        {
            label: "User Contacts",
            value: "userContacts",
        },
        {
            label: "Hierarchy Contact Type",
            value: "contactType",
        },
        {
            label: "Agent Manager",
            value: CustomContactType[CustomContactType.AgentManager].toString(),
        },
    ];

    readonly dueDateTypes = [
        {
            label: "Business Days",
            value: DueDateType.BusinessDays,
        },
        {
            label: "Calendar Days",
            value: DueDateType.CalendarDays,
        },
    ];

    @observable.ref
    availableWorkflowDefinitions: WorkflowDefinition[] = [];

    @observable.ref
    availableContactTypes: { id: number; name: string }[] = [];

    @observable.ref
    availableQuestions: Question[] = [];

    @observable.ref
    availableRecipients: UserContact[] = [];

    @observable.ref
    availableLicensedModules: LicensedModule[] = [];

    @observable.ref
    hierarchyAndAlwaysModules: LicensedModule[] = [];

    @observable.ref
    availableQuestionModules: LicensedModule[] = [];

    @observable.ref
    availableSEModules: LicensedModule[] = [];

    @observable
    selectedView: OrganizationWorkflowViews = 0; // Workflow Conditions

    @observable
    drawerStore: LayoutDrawerStore;

    @observable
    organizationId: string;

    @observable
    workflowConditions: WorkflowCondition[] = [];

    @observable
    selectedQuestion?: Question;

    @observable
    selectedLicensedModule?: LicensedModule;

    @observable
    selectedTag?: Tag;

    @observable
    inProgressCondition?: WorkflowCondition = undefined;

    @observable
    searchTerm: string = "";

    @observable
    selectedRecipientTypes: Array<string> = [];

    constructor(private rootStore: IRootStore) {
        super("OrganizationWorkflowsStore");

        makeObservable(this);

        this.dataGridStore = new AcxDataGridStore(
            "workflowConditionsTable",
            "Workflows",
        );

        this.workflowService = new WorkflowService();
        this.moduleService = new ModuleService();

        this.authStore = this.rootStore.getStore(AuthStore);
        this.messageStore = this.rootStore.getStore(MessageStore);

        this.drawerStore = this.rootStore.getStore(LayoutDrawerStore);
        this.drawerStore.isOpen = false;

        reaction(
            (r) => ({
                storeError: this.nextTaskError,
            }),
            (arg) => {
                if (arg && arg.storeError?.message) {
                    const msg = arg.storeError?.message;
                    this.messageStore.logError(msg);
                    this.clearLastTaskError();
                }
            },
            { delay: 0 },
        );

        reaction(
            (r) => ({
                organizationId: this.organizationId,
                activeLocation: this.rootStore.activeLocation,
            }),
            (args) => {
                if (
                    args.activeLocation &&
                    !(
                        args.activeLocation.location.includes(
                            "admin/organizations",
                        ) && args.activeLocation.location.includes("/workflows")
                    )
                ) {
                    return;
                }

                if (args.organizationId) {
                    this.getWorkflowConditions(args.organizationId);

                    this.setupAsyncTask(LOAD_LICENSED_MODULES, async () => {
                        const allLicensedModules =
                            await this.moduleService.getAllLicensedModuleTypes(
                                args.organizationId,
                            );

                        runInAction(() => {
                            const allLicensedModulesFormatted =
                                allLicensedModules?.map(
                                    (module) =>
                                        ({
                                            ...module,
                                            displayName: `${module.name}${
                                                module.description
                                                    ? ` - ${module.description}`
                                                    : ""
                                            }`,
                                        } as any),
                                );

                            this.availableLicensedModules =
                                allLicensedModulesFormatted.filter(
                                    (module: LicensedModule) =>
                                        module.lmType === LMType.Workflow,
                                );

                            this.hierarchyAndAlwaysModules =
                                allLicensedModulesFormatted.filter(
                                    (module: LicensedModule) =>
                                        module.lmType === LMType.Always ||
                                        module.lmType === LMType.Hierarchy,
                                );

                            this.availableQuestionModules =
                                allLicensedModulesFormatted.filter(
                                    (module: LicensedModule) =>
                                        module.lmType !== LMType.Workflow,
                                );

                            this.availableSEModules =
                                allLicensedModulesFormatted.filter(
                                    (module: LicensedModule) =>
                                        module.lmType ===
                                        LMType["Safety Event"],
                                );
                        });
                    });

                    this.setupAsyncTask(LOAD_WORKFLOW_DEFINITIONS, async () => {
                        const availableDefinitions =
                            await this.workflowService.getWorkflowDefinitions(
                                args.organizationId,
                            );

                        runInAction(() => {
                            this.availableWorkflowDefinitions =
                                availableDefinitions;
                        });
                    });

                    this.setupAsyncTask(LOAD_CONTACT_TYPES, async () => {
                        this.availableContactTypes =
                            await this.workflowService.getContactTypes();
                    });

                    this.setupAsyncTask(LOAD_RECIPIENTS, async () => {
                        this.availableRecipients =
                            await this.workflowService.getWorkflowRecipients(
                                args.organizationId,
                            );
                    });
                }
            },
            { fireImmediately: true },
        );

        reaction(
            (r) => ({
                condition: this.inProgressCondition,
                availableQuestionModules: this.availableQuestionModules,
            }),
            (arg) => {
                if (
                    arg.condition?.implementationType ===
                        WorkflowConditionType.APT &&
                    arg.condition?.id &&
                    arg.availableQuestionModules.length > 0
                ) {
                    this.selectedLicensedModule =
                        arg.availableQuestionModules.find((module) =>
                            module.id ===
                            arg.condition?.question?.licensedModuleId
                                ? arg.condition?.question?.licensedModuleId
                                : arg.condition?.licensedModuleId,
                        );

                    this.selectedQuestion =
                        this.selectedLicensedModule?.questions.find(
                            (question) =>
                                arg.condition?.questionId === question.id,
                        );

                    this.selectedTag = this.selectedQuestion?.tags?.find(
                        (tag) => arg.condition?.tagId === tag.id,
                    );
                } else if (
                    arg.condition?.implementationType ===
                    WorkflowConditionType.ScoreEscalation
                ) {
                    this.selectedLicensedModule =
                        arg.availableQuestionModules.find(
                            (module) =>
                                module.id === arg.condition?.licensedModuleId,
                        );
                }
            },
            { fireImmediately: true },
        );
    }

    @action
    handleDrawerOpen = (
        condition?:
            | WorkflowCondition
            | React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => {
        if (condition && "id" in condition) {
            this.inProgressCondition = condition;
            this.selectedRecipientTypes =
                condition.workflowMessagingMetadata.map((data) => {
                    var custom = data.workflowRecipients?.find(
                        (recipient) =>
                            recipient.customContactType !== undefined &&
                            recipient.customContactType !== null,
                    );

                    if (custom) {
                        return CustomContactType[
                            custom.customContactType as CustomContactType
                        ].toString();
                    } else {
                        return data.workflowRecipients?.some(
                            (recipient) =>
                                recipient.contactType !== undefined &&
                                recipient.contactType !== null,
                        )
                            ? "contactType"
                            : "userContacts";
                    }
                });
        } else {
            this.inProgressCondition = {
                organizationId: this.organizationId,
                workflowMessagingMetadata: [],
                isActive: true,
                highPriority: false,
            };
        }

        this.drawerStore.setOpen(true);
    };

    @action
    handleViewChange = (view: OrganizationWorkflowViews) => {
        this.selectedView = view;
    };

    @action
    handleDrawerClose = () => {
        this.inProgressCondition = undefined;
        this.selectedQuestion = undefined;
        this.selectedTag = undefined;
        this.selectedLicensedModule = undefined;
        this.selectedRecipientTypes = [];

        this.dataGridStore.clearSelected();
        this.drawerStore.setOpen(false);
    };

    @action
    handleLevelAddition = () => {
        if (this.inProgressCondition) {
            this.inProgressCondition.workflowMessagingMetadata.push({
                level: this.inProgressCondition.workflowMessagingMetadata
                    .length,
            });
        }
    };

    @action
    handleLevelRemoval = () => {
        if (this.inProgressCondition) {
            this.inProgressCondition.workflowMessagingMetadata?.pop();
        }
    };

    @action
    handleWorkflowNameUpdate = (value: string) => {
        if (this.inProgressCondition) {
            this.inProgressCondition.name = value;
        }
    };

    @action
    handleWorkflowScoreThreshold = (value: string) => {
        if (this.inProgressCondition) {
            this.inProgressCondition.scoreThreshold = parseFloat(value);
        }
    };

    @action
    handleWorkflowDefinitionSelection = (def: WorkflowDefinition) => {
        if (this.inProgressCondition) {
            this.inProgressCondition.workflowDefinitionId = def.id;
            this.inProgressCondition.workflowIdentifier = def.workflowUri;
            this.inProgressCondition.workflowDefinition = def;

            if (def.name === "SafetyEvent") {
                this.inProgressCondition.implementationType =
                    WorkflowConditionType.ClassifierResult;
            } else {
                this.inProgressCondition.implementationType =
                    WorkflowConditionType[def.name ?? "APT"];
            }

            if (
                this.inProgressCondition.implementationType ===
                WorkflowConditionType.EvaluationDispute
            ) {
                this.inProgressCondition.name = "Evaluation Dispute-Condition";
            }
            if (
                this.inProgressCondition.implementationType ===
                WorkflowConditionType.ClassifierResult
            ) {
                this.inProgressCondition.name = "Safety Event Identified Email";
                this.inProgressCondition.workflowMessagingMetadata = [
                    {
                        level: 0,
                        name: "Safety Event Identified",
                    },
                ];

                this.inProgressCondition.workflowMessagingMetadata[0].emailCadences =
                    [2];
            }
        }
    };

    @action
    handleAvailableLicensedModuleSelection = (lm: LicensedModule) => {
        this.selectedLicensedModule = lm;
        this.availableQuestions = lm.questions;
        this.selectedQuestion = undefined;
        this.selectedTag = undefined;
        if (this.inProgressCondition) {
            this.inProgressCondition.licensedModuleId = lm.id;
            if (
                this.inProgressCondition.implementationType !==
                    WorkflowConditionType.APT &&
                this.inProgressCondition.implementationType !==
                    WorkflowConditionType.ClassifierResult
            ) {
                this.inProgressCondition.name = lm.name + "-Condition";
            }
        }
    };

    @action
    handleQuestionSelection = (value: Question) => {
        this.selectedQuestion = value;

        if (this.inProgressCondition) {
            this.inProgressCondition.questionId = value.id;
            this.inProgressCondition.name =
                (value.shortQuestionText ?? value.questionText) + "-Condition";
        }
    };

    @action
    handleTagSelection = (value: Tag) => {
        this.selectedTag = value;

        if (this.inProgressCondition) {
            this.inProgressCondition.tagId = value.id;
        }
    };

    @action
    handleWorkflowLevelNameUpdate = (value: string, level: number) => {
        if (this.inProgressCondition) {
            this.inProgressCondition.workflowMessagingMetadata[level].name =
                value;
        }
    };

    @action
    handleWorkflowLevelDueDateDaysUpdate = (value: string, level: number) => {
        if (this.inProgressCondition) {
            this.inProgressCondition.workflowMessagingMetadata[
                level
            ].dueDateDays = parseFloat(value);
        }
    };

    @action
    handleContactTypeSelection = (value: any, level: number) => {
        if (this.inProgressCondition) {
            this.inProgressCondition.workflowMessagingMetadata[
                level
            ].workflowRecipients = [
                {
                    userId: undefined,
                    organizationId: this.organizationId,
                    domain: this.authStore.orgStore.selectedOrganization
                        ?.saml2AuthenticxDomain,
                    contactType: value.id,
                } as WorkflowRecipient,
            ];
        }
    };

    @action
    handleEmailTemplateSelection = (value: WorkflowTemplate, level: number) => {
        if (this.inProgressCondition) {
            this.inProgressCondition.workflowMessagingMetadata[
                level
            ].workflowEmailTemplateId = value.id;
        }
    };

    @action
    handleRecipientSelection = (value: UserContact[], level: number) => {
        if (this.inProgressCondition) {
            this.inProgressCondition.workflowMessagingMetadata[
                level
            ].workflowRecipients = value.map((userContact) => ({
                userId: userContact.id,
                organizationId: this.organizationId,
                domain: userContact.domain,
                contactType: undefined,
                user: {
                    userName: userContact.userName,
                } as ApplicationUser,
            }));
        }
    };

    @action
    handleEmailSendCadenceSelection = (
        value: { label: string; value: number }[],
        level: number,
    ) => {
        if (this.inProgressCondition) {
            this.inProgressCondition.workflowMessagingMetadata[
                level
            ].emailCadences = value.map(
                (option) =>
                    option.value as unknown as WorkflowTemplateDeliveryCadence,
            );
        }
    };

    @action
    handleRecipientTypeSelect = (
        option: { label: string; value: any },
        level: number,
    ) => {
        this.selectedRecipientTypes[level] = option.value;

        if (this.inProgressCondition) {
            if (
                option.value !== "userContacts" &&
                option.value !== "contactType"
            ) {
                this.inProgressCondition.workflowMessagingMetadata[
                    level
                ].workflowRecipients = [
                    {
                        userId: undefined,
                        organizationId: this.organizationId,
                        domain: this.authStore.orgStore.selectedOrganization
                            ?.saml2AuthenticxDomain,
                        customContactType:
                            CustomContactType[option.value as string],
                    } as WorkflowRecipient,
                ];
            } else {
                this.inProgressCondition.workflowMessagingMetadata[
                    level
                ].workflowRecipients = [];
            }
        }
    };

    @action
    handleDueDateTypeSelect = (
        value: DueDateType | undefined,
        level: number,
    ) => {
        if (this.inProgressCondition) {
            if (value !== undefined) {
                this.inProgressCondition.workflowMessagingMetadata[
                    level
                ].dueDateType = DueDateType[value];
            } else
                this.inProgressCondition.workflowMessagingMetadata[
                    level
                ].dueDateType = undefined;
        }
    };

    @action
    handleLicensedModuleSelection = (value: LicensedModule, level: number) => {
        if (this.inProgressCondition) {
            this.inProgressCondition.workflowMessagingMetadata[
                level
            ].licensedModuleId = value?.id;
        }
    };

    @action
    handleIsActiveCheckbox = (checked: boolean) => {
        if (this.inProgressCondition) {
            this.inProgressCondition.isActive = checked;
        }
    };

    @action
    handleIsHighPriorityCheckbox = (checked: boolean) => {
        if (this.inProgressCondition) {
            this.inProgressCondition.highPriority = checked;
        }
    };

    @action
    handleReminderEmailsCheckbox = (level: number, checked: boolean) => {
        if (this.inProgressCondition) {
            this.inProgressCondition.workflowMessagingMetadata[
                level
            ].overdueReminders = checked;
        }
    };

    @action
    setInProgressCondition = (condition: WorkflowCondition) => {
        this.inProgressCondition = condition;
    };

    @action
    setOrganizationId = (id: string) => {
        this.organizationId = id;
    };

    @action
    initializeDataGrid = () => {
        this.dataGridStore.reset();
        const columns: IColDef[] = [
            {
                headerName: "Name",
                field: "name",
                flex: 1,
            },
            {
                headerName: "Is Active",
                field: "isActive",
                type: "string",
                flex: 1,
            },
            {
                headerName: "High Priority",
                field: "highPriority",
                type: "string",
                flex: 1,
            },
            {
                headerName: "#Levels",
                field: "workFlowCount",
                type: "number",
                flex: 1,
            },
            {
                headerName: "Definition",
                field: "workflowDefinitionName",
                type: "string",
                flex: 1,
            },
            {
                headerName: "Module Name",
                field: "moduleName",
                type: "string",
                flex: 1,
            },
            {
                headerName: "Question",
                field: "shortQuestionText",
                type: "string",
                flex: 1,
            },
            {
                headerName: "Tag",
                field: "tag",
                type: "string",
                flex: 1,
            },
        ];

        this.dataGridStore.setColumns(columns);
    };

    @computed
    get isEditDisabled() {
        return this.dataGridStore.selectedRows.length !== 1;
    }

    @computed
    get isCreateDisabled() {
        return this.dataGridStore.selectedRows.length !== 0;
    }

    private prepareDataForSubmission = (condition: WorkflowCondition) => {
        if (condition) {
            condition?.workflowMessagingMetadata?.forEach((data) => {
                data.workflowRecipients?.forEach(
                    (recipient) => (recipient.user = undefined),
                );

                //if Due Date Type is not defined remove changes to those variables
                if (
                    data.dueDateType === null ||
                    data.dueDateType === undefined
                ) {
                    data.overdueReminders = false;
                    data.dueDateDays = undefined;
                } else {
                    //in this case the user enabled due dates by selecting a type but left the days at 1, on change was not called
                    if (
                        data.dueDateDays === null ||
                        data.dueDateDays === undefined
                    ) {
                        data.dueDateDays = 1;
                    }
                }
            });

            condition.tag = undefined;
            condition.question = undefined;
            condition.workflowDefinition = undefined;
            if (
                condition.implementationType ===
                WorkflowConditionType.ClassifierResult
            ) {
                condition.workflowDefinitionName = "SafetyEvent";
                condition.result = true;
            } else {
                condition.workflowDefinitionName =
                    WorkflowConditionType[condition.implementationType!];
                condition.result = null;
            }
        }
    };

    public getContactType = (level: number) => {
        const contactType = this.inProgressCondition?.workflowMessagingMetadata[
            level
        ]?.workflowRecipients?.find(
            (recipient) =>
                recipient.contactType !== undefined &&
                recipient.contactType !== null,
        )?.contactType;

        if (contactType !== undefined && contactType !== null) {
            return {
                id: contactType,
                name: ContactType[contactType],
            };
        }

        return undefined;
    };

    public getDueDateType = (level: number) => {
        const dueDateType =
            this.inProgressCondition?.workflowMessagingMetadata[level]
                ?.dueDateType;

        if (dueDateType !== undefined && dueDateType !== null) {
            return {
                value: DueDateType[dueDateType],
                label: this.dueDateTypes.find(
                    (x) => DueDateType[x.value] === dueDateType,
                )?.label,
            };
        }

        return undefined;
    };

    public getWorkflowConditions = (orgId: string) => {
        this.setupAsyncTask(LOAD_CONDITIONS, async () => {
            this.workflowConditions =
                await this.workflowService.getWorkflowConditions(orgId);

            this.initializeDataGrid();
            this.dataGridStore.rows = this.workflowConditions;
        });
    };

    public createWorkflowCondition = () => {
        this.setupAsyncTask(CREATE_CONDITION, async () => {
            if (this.inProgressCondition) {
                const condition = toJS(this.inProgressCondition);
                this.prepareDataForSubmission(condition);

                const response =
                    await this.workflowService.createWorkflowCondition(
                        condition,
                    );

                if (response instanceof Error) {
                    if ((response as any).errorJson) {
                        return this.messageStore.logError(
                            (response as any).errorJson[
                                Object.keys((response as any).errorJson)[0]
                            ][0],
                        );
                    }
                    throw response;
                }

                this.messageStore.logMessage(
                    `${
                        condition.name ?? "Condition"
                    } has been successfully created.`,
                    "success",
                );

                this.getWorkflowConditions(this.organizationId);
                this.handleDrawerClose();
            }
        });
    };

    public updateWorkflowCondition = () => {
        this.setupAsyncTask(UPDATE_CONDITION, async () => {
            if (this.inProgressCondition) {
                const condition = toJS(this.inProgressCondition);
                this.prepareDataForSubmission(condition);

                const response =
                    await this.workflowService.updateWorkflowCondition(
                        condition,
                    );

                if (response instanceof Error) {
                    if ((response as any).errorJson) {
                        return this.messageStore.logError(
                            (response as any).errorJson[
                                Object.keys((response as any).errorJson)[0]
                            ][0],
                        );
                    }
                    throw response;
                }

                this.messageStore.logMessage(
                    `${
                        condition.name ?? "Condition"
                    } has been successfully updated.`,
                    "success",
                );

                this.getWorkflowConditions(this.organizationId);
                this.handleDrawerClose();
            }
        });
    };
}
