import { Grid } from "@mui/material";
import AgentChatStore from "components/Agent/Stores/AgentChatStore";
import AcxDialog from "components/UI/Dialog/AcxDialog";
import React, { useEffect, useState } from "react";
import { useStore } from "utils/useStore";
import { Suggestion } from "../Suggestions/Suggestion";
import AcxButton from "components/UI/AcxButton";
import { ApplicationFilterValuesStore } from "stores/ApplicationFilters/ApplicationFilterValuesStore";
import { observer } from "mobx-react";
import AcxSelectSingleAsync from "../../UI/Select/BaseSelectComponents/AcxSelectSingleAsync";
import Agentv2 from "../../../models/Agentv2";
import { getAgentOptionLabel } from "../../ApplicationFilters/Agent/AgentsFilter";

export const SelectAgentAction = observer(() => {
    const store = useStore(AgentChatStore);
    const valuesStore = useStore(ApplicationFilterValuesStore);
    const [agent, setAgent] = useState<Agentv2 | undefined>();
    const [dialogOpen, setDialogOpen] = useState(false);

    useEffect(() => {
        if (!dialogOpen) return;
        if (!!valuesStore.userClassifiers) return;
        valuesStore.loadUserClassifiers();
    }, [dialogOpen, valuesStore]);

    const isLoading =
        valuesStore.getTaskLoading("Get Agent") ||
        valuesStore.getTaskLoading("Search Agents");

    return (
        <React.Fragment>
            <AcxDialog
                isOpen={dialogOpen}
                onClose={() => {
                    setDialogOpen(false);
                }}
                title="Select Agent"
                maxWidth="lg"
                dialogContentChildren={
                    <AcxSelectSingleAsync
                        fullWidth
                        isLoading={isLoading}
                        loadOptions={(input) => valuesStore.searchAgent(input)}
                        cacheOptions
                        isClearable
                        onChange={(agent?: Agentv2) => {
                            setAgent(agent);
                        }}
                        enableNoSelection
                        valueField="id"
                        labelField={getAgentOptionLabel}
                        id="conversations-filter-agent-async-select"
                        noOptionMessage="Please enter First, Last, Agent Code, or Email"
                    />
                }
            >
                <AcxButton
                    fullWidth={false}
                    onClick={() => {
                        if (!agent) return;
                        store.streamingSubmitInput({
                            userMessage: agent.fullName,
                            manualInput: `Agent name: ${agent.fullName}\nid: ${agent.id}`,
                        });
                    }}
                    disabled={!agent}
                >
                    Use selected agent
                </AcxButton>
            </AcxDialog>
            <Grid item>
                <Suggestion onClick={() => setDialogOpen((open) => !open)}>
                    Select Agent
                </Suggestion>
            </Grid>
        </React.Fragment>
    );
});
