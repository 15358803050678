import { Grid, Tooltip } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import InfoIcon from "@mui/icons-material/Info";
import ManagerInteractionsStore, {
    GENERATE_SAMPLE,
} from "components/ManagerInteractions/Stores/ManagerInteractionsStore";
import AcxButton from "components/UI/AcxButton";
import AcxMainTextField from "components/UI/AcxMainTextField";
import AcxSelectSingle from "components/UI/Select/BaseSelectComponents/AcxSelectSingle";
import AcxSelectSingleAsync from "components/UI/Select/BaseSelectComponents/AcxSelectSingleAsync";
import _ from "lodash";
import { observer } from "mobx-react";
import Agentv2 from "models/Agentv2";
import React from "react";
import AgentService from "services/AgentService";
import useStyles from "Styles/Styles";
import { EnumToDropdownOptionConversion } from "utils/EnumToDropdownOptionConversion";
import theme from "Theme/AppTheme";

export enum OnDemandMediaTypes {
    "Audio" = 0,
    "ProcessedChat" = 6,
}

const styles = () =>
    createStyles({
        filterItem: {
            margin: "0.5rem 0",
        },
        filterItemSelect: {
            margin: "0.5rem 0",
            paddingRight: "0.5rem",
        },
    });

const selectCustomStyle = {
    menuPortal: (provided, state) => ({ ...provided, zIndex: 10000 }),
};

const callDirectionOptions = [
    { label: "Inbound", value: "Inbound" },
    { label: "Outbound", value: "Outbound" },
];

const mediaTypeOptions = EnumToDropdownOptionConversion(OnDemandMediaTypes);

interface IOnDemandFiltersProps {
    store: ManagerInteractionsStore;
}

const OnDemandFilters: React.FC<IOnDemandFiltersProps> = observer(
    ({ store }) => {
        const classes = useStyles(styles);
        const orgStore = store.authStore.orgStore;
        const [isLoading, setIsLoading] = React.useState<boolean>(false);
        const [campaignOptions, setCampaignOptions] = React.useState<
            { label: string; value: string }[]
        >([]);

        // Apply filter parameters to generate sample request
        const handleOnClick = async () => {
            await store.generateSample(false);

            store.drawerStore.closeAndResetDrawer();
            store.drawerStore.restoreDefaults();

            store.messageStore.logMessage(
                "Filters have been applied.",
                "success",
            );
        };

        // Handle async select Agents drop-down
        const agentSearch = async (inputValue: string): Promise<Agentv2[]> => {
            const agentService = new AgentService();

            setIsLoading(true);

            const agts = await agentService.searchAgents(
                orgStore.selectedOrganization?.id,
                inputValue,
            );
            // console.log(agts);
            setIsLoading(false);

            return agts;
        };

        const getOptionLabel = (data) => {
            let label = "";

            if (_.has(data, "fullName")) {
                if (data?.fullName !== " ") {
                    label = data.fullName;
                } else {
                    label = data.agentEmail;
                }

                if (data.isActive) {
                    label = label + " - Active";
                } else {
                    label = label + " - Inactive";
                }
            } else {
                label = data.label;
            }
            // console.log(label);
            return label;
        };

        React.useEffect(() => {
            setCampaignOptions(
                store.campaigns.map((campaign) => {
                    const dropdownCampaignOption = {} as any;
                    dropdownCampaignOption.label = campaign.externalName;
                    dropdownCampaignOption.value = campaign.id;
                    return dropdownCampaignOption;
                }),
            );
        }, [store.campaigns]);

        return (
            <Grid container>
                <Grid item xs={12} className={classes.filterItem}>
                    <AcxMainTextField
                        type="number"
                        id="callLengthMin"
                        labelText="Call Length Minimum (Seconds)"
                        value={store.callLengthMin ?? ""}
                        onChange={(e) => store.setCallLengthMin(e.target.value)}
                        insertBlankIfNumber
                    />
                </Grid>

                <Grid item xs={12} className={classes.filterItem}>
                    <AcxMainTextField
                        type="number"
                        id="callLengthMax"
                        labelText="Call Length Maximum (Seconds)"
                        value={store.callLengthMax ?? ""}
                        onChange={(e) => store.setCallLengthMax(e.target.value)}
                        insertBlankIfNumber
                    />
                </Grid>

                <Grid item xs={12} className={classes.filterItemSelect}>
                    <AcxSelectSingle
                        inputLabel="Call Direction"
                        options={callDirectionOptions}
                        id="callDirection"
                        valueField="value"
                        labelField="label"
                        customStyle={selectCustomStyle}
                        defaultValue={store.callDirection}
                        onChange={store.setCallDirection}
                    />
                </Grid>

                <Grid item xs={12} className={classes.filterItemSelect}>
                    <AcxSelectSingle
                        inputLabel="MediaType"
                        options={mediaTypeOptions}
                        id="mediaTypes"
                        valueField="value"
                        labelField="label"
                        customStyle={selectCustomStyle}
                        defaultValue={store.selectedMediaType}
                        onChange={store.setSelectedMediaType}
                    />
                </Grid>

                <Grid item xs={12} className={classes.filterItemSelect}>
                    <AcxSelectSingleAsync
                        inputLabel="Agent"
                        loadOptions={agentSearch}
                        loadOptionsDelay={600}
                        cacheOptions
                        isLoading={isLoading}
                        isClearable={true}
                        enableNoSelection={true}
                        defaultValue={store.selectedAgent}
                        valueField="id"
                        labelField={getOptionLabel}
                        id="onDemand-Agents-Dropdown"
                        placeholder="Type to search..."
                        onChange={store.setSelectedAgent}
                    />
                </Grid>

                <Grid item xs={12} className={classes.filterItem}>
                    <AcxMainTextField
                        type="text"
                        id="meta1"
                        labelText={
                            store.metaLabelSelectLabels["Meta1"] ?? "Meta 1"
                        }
                        value={store.meta1 ?? ""}
                        onChange={(e) => store.setMeta1(e.target.value)}
                    />
                </Grid>

                <Grid item xs={12} className={classes.filterItem}>
                    <AcxMainTextField
                        type="text"
                        id="meta2"
                        labelText={
                            store.metaLabelSelectLabels["Meta2"] ?? "Meta 2"
                        }
                        value={store.meta2 ?? ""}
                        onChange={(e) => store.setMeta2(e.target.value)}
                    />
                </Grid>

                <Grid item xs={12} className={classes.filterItem}>
                    <AcxMainTextField
                        type="text"
                        id="meta3"
                        labelText={
                            store.metaLabelSelectLabels["Meta3"] ?? "Meta 3"
                        }
                        value={store.meta3 ?? ""}
                        onChange={(e) => store.setMeta3(e.target.value)}
                    />
                </Grid>

                <Grid item xs={12} className={classes.filterItem}>
                    <AcxMainTextField
                        type="text"
                        id="meta4"
                        labelText={
                            store.metaLabelSelectLabels["Meta4"] ?? "Meta 4"
                        }
                        value={store.meta4 ?? ""}
                        onChange={(e) => store.setMeta4(e.target.value)}
                    />
                </Grid>

                <Grid item xs={12} className={classes.filterItem}>
                    <AcxMainTextField
                        type="text"
                        id="meta5"
                        labelText={
                            store.metaLabelSelectLabels["Meta5"] ?? "Meta 5"
                        }
                        value={store.meta5 ?? ""}
                        onChange={(e) => store.setMeta5(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} className={classes.filterItem}>
                    <AcxMainTextField
                        type="text"
                        id="phone"
                        labelText="Phone"
                        value={store.phone ?? ""}
                        onChange={(e) => store.setPhone(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} className={classes.filterItemSelect}>
                    <AcxSelectSingle
                        inputLabel="Campaign"
                        options={campaignOptions}
                        id="campaigns"
                        valueField="value"
                        labelField="label"
                        customStyle={selectCustomStyle}
                        defaultValue={store.selectedCampaign}
                        onChange={store.setSelectedCampaign}
                    />
                </Grid>
                <Grid item xs={12} className={classes.filterItem}>
                    <AcxMainTextField
                        type="number"
                        id="conversationMessageCountMax"
                        labelText="Conversation Message Max"
                        insertBlankIfNumber
                        value={store.conversationMessageCountMax ?? ""}
                        onChange={(e) =>
                            store.setconversationMessageCountMax(e.target.value)
                        }
                    />
                </Grid>
                <Grid item xs={12} className={classes.filterItem}>
                    <AcxMainTextField
                        type="number"
                        id="conversationMessageCountMin"
                        labelText="Conversation Message Min"
                        insertBlankIfNumber
                        value={store.conversationMessageCountMin ?? ""}
                        onChange={(e) =>
                            store.setconversationMessageCountMin(e.target.value)
                        }
                    />
                </Grid>

                {!!store.extendedMetadata &&
                    store.extendedMetadata.map((extMetaItem, index) => {
                        return (
                            <Grid
                                item
                                xs={12}
                                className={classes.filterItem}
                                key={"extMetaItem" + index}
                            >
                                <AcxMainTextField
                                    id={"extMetaItem" + index}
                                    type="text"
                                    labelText={extMetaItem.item1}
                                    value={
                                        store.extendedMetadata?.[index].value
                                    }
                                    onChange={(e) =>
                                        store.setExtendedMetadata(
                                            index,
                                            e.target.value,
                                        )
                                    }
                                    endAdornment={
                                        <Tooltip title="This field supports comma separated values e.g.: value1,value2,value3 ">
                                            <InfoIcon color="disabled" />
                                        </Tooltip>
                                    }
                                    disableTooltip
                                />
                            </Grid>
                        );
                    })}

                <Grid container item xs={12} className={classes.filterItem}>
                    <AcxButton color="primary" onClick={store.resetFilters}>
                        Reset
                    </AcxButton>

                    <AcxButton
                        color="primary"
                        onClick={handleOnClick}
                        loading={store.getTaskLoading(GENERATE_SAMPLE)}
                        disabled={store.getTaskLoading(GENERATE_SAMPLE)}
                        style={{
                            marginRight: theme.spacing(1),
                            marginLeft: theme.spacing(1),
                        }}
                    >
                        Apply Filters
                    </AcxButton>
                </Grid>
            </Grid>
        );
    },
);

export default OnDemandFilters;
