import ClearIcon from "@mui/icons-material/Clear";
import InfoIcon from "@mui/icons-material/Info";
import { Grid, IconButton, Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { debounce } from "lodash";
import { Observer, observer } from "mobx-react";
import React, { useEffect, useMemo, useState } from "react";
import { HierarchyService } from "services/HierarchyService";
import { AuthStore } from "stores/AuthStore";
import LocalStorage from "stores/LocalStorage";
import RootStore from "stores/RootStore";
import { useStore } from "utils/useStore";
import AcxMainTextField from "../AcxMainTextField";
import RecursiveTreeStore from "../AcxRecursiveTree/Stores/AcxRecursiveTreeStore";
import AcxSelectMulti from "../Select/BaseSelectComponents/AcxSelectMulti";
import {
    IAcxHierarchyComponentProps,
    loadUserServiceHierarchiesHelper,
} from "./AcxHierarchySelector";

interface HierarchyCategoryOptions {
    value: string;
    label: string;
}

interface HierCategorySelectionProps {
    treeStore: RecursiveTreeStore;
    isSubCategoryComponent?: boolean;
}

const HierCategorySelection: React.FC<HierCategorySelectionProps> = (props) => {
    const hierService = useMemo(() => new HierarchyService(), []);
    const getHierarchiesForCategory = props.isSubCategoryComponent
        ? (subcategory) =>
              hierService.searchHierarchiesBySubCategoryAsync(subcategory)
        : (category) => hierService.searchHierarchiesByCategoryAsync(category);

    const selectedCategories = props.isSubCategoryComponent
        ? props.treeStore.selectedSubcategories
        : props.treeStore.selectedCategories;

    const defaultSelectedCategories = selectedCategories.map((category) => ({
        value: category,
        label: category,
    }));

    const [hierarchyCategories, setHierarchyCategories] = useState<
        HierarchyCategoryOptions[]
    >([]);

    useEffect(() => {
        async function getCategories() {
            const response = props.isSubCategoryComponent
                ? await hierService.getHierarchySubcategories()
                : await hierService.getHierarchyCategories();

            const hierarchyCategories = response
                .map(
                    (value) =>
                        ({
                            value: value,
                            label: value,
                        } as HierarchyCategoryOptions),
                );
            setHierarchyCategories(hierarchyCategories);
        }

        getCategories();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Observer key={`search`}>
            {() => (
                <Grid
                    item
                    xs={12}
                    key={`hierarchy-categories`}
                    marginTop={"10px"}
                >
                    <AcxSelectMulti
                        id="hierarchy-category-selected"
                        options={hierarchyCategories}
                        defaultValue={defaultSelectedCategories}
                        valueField="value"
                        labelField="label"
                        inputLabel={
                            props.isSubCategoryComponent
                                ? "Hierarchy Subcategory"
                                : "Hierarchy Category"
                        }
                        onChange={(
                            categoryOptions?: HierarchyCategoryOptions[],
                        ) => {
                            // make sure we mark hierarchies from the categories in these arrays as selected
                            // filter out what categories are already selected?
                            const currentSelectedCategories =
                                categoryOptions?.map(
                                    (categoryOption) => categoryOption.value,
                                ) ?? [];
                            const missingCategories = selectedCategories.filter(
                                (category) =>
                                    !currentSelectedCategories?.includes(
                                        category,
                                    ),
                            );
                            const newCategories =
                                currentSelectedCategories.filter(
                                    (category) =>
                                        !selectedCategories.includes(category),
                                );

                            if (props.isSubCategoryComponent) {
                                props.treeStore.selectedSubcategories =
                                    currentSelectedCategories;
                            } else {
                                props.treeStore.selectedCategories =
                                    currentSelectedCategories;
                            }

                            // add new
                            newCategories?.forEach((category) => {
                                getHierarchiesForCategory(category).then(
                                    (hierarchies) => {
                                        let hierarchyIdsToSelect: string[] = [];
                                        let hierarchyLabelsToSelect: string[] =
                                            [];
                                        hierarchies.childrenHierarchies.forEach(
                                            (hierarchy) => {
                                                hierarchyIdsToSelect.push(
                                                    hierarchy.id,
                                                );
                                                hierarchyLabelsToSelect.push(
                                                    hierarchy.name,
                                                );
                                            },
                                        );
                                        props.treeStore.addMultipleSelectedBranches(
                                            hierarchyIdsToSelect,
                                            hierarchyLabelsToSelect,
                                        );
                                    },
                                );
                            });

                            // remove old
                            missingCategories?.forEach((category) => {
                                getHierarchiesForCategory(category).then(
                                    (hierarchies) => {
                                        let hierarchyIdsToSelect: string[] = [];
                                        let hierarchyLabelsToSelect: string[] =
                                            [];
                                        hierarchies.childrenHierarchies.forEach(
                                            (hierarchy) => {
                                                hierarchyIdsToSelect.push(
                                                    hierarchy.id,
                                                );
                                                hierarchyLabelsToSelect.push(
                                                    hierarchy.name,
                                                );
                                            },
                                        );
                                        props.treeStore.removeMultipleSelectedBranches(
                                            hierarchyIdsToSelect,
                                            hierarchyLabelsToSelect,
                                        );
                                    },
                                );
                            });
                        }}
                        containerHeight="auto"
                        fullWidth
                        error={false}
                        // helperText={}
                    />
                </Grid>
            )}
        </Observer>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    topContentContainer: {
        padding: "16px 12px 8px 12px",
    },
    selectAllText: {
        padding: "8px 0px",
        fontWeight: "bold",
        textAlign: "end",
    },
    totalText: {
        display: "flex",
        flexWrap: "nowrap",
        alignItems: "center",
        textWrap: "nowrap",
    },
    headerWarningContainer: {
        borderRadius: "4px",
        backgroundColor: "#DBEAFE",
        padding: "6px 16px",
        maxHeight: "70px",
    },
}));

const localKey = "hierarchyWarningCount";

const HierarchySelectorHeader: React.FC<IAcxHierarchyComponentProps> = observer(
    (props: IAcxHierarchyComponentProps) => {
        const classes = useStyles();
        const [showHeaderWarning, setShowHeaderWarning] =
            useState<boolean>(false);
        const hierService = useMemo(() => new HierarchyService(), []);
        const authStore = useStore(AuthStore);

        const closeHeaderWarning = () => {
            setShowHeaderWarning(false);
        };

        const hasSearchResults: boolean = Boolean(
            props.treeStore.treeSearchResponse?.length !== 0,
        );

        const handleOnSelectAllResults = () => {
            const hasPermsChildrenHierarchies =
                props.treeStore.treeSearchResponse.filter(
                    (branch) => branch.hasPerms !== false,
                );

            hasPermsChildrenHierarchies.forEach((branch) => {
                branch.hasPerms !== false &&
                    props.treeStore.addSelectedBranchId(branch.id, branch.name);
            });
        };

        const HierSearchInput: React.FC = () => {
            const handleInputChange = (
                e: React.ChangeEvent<HTMLInputElement>,
            ) => {
                props.treeStore.setSearchString(e.target.value);

                performHierarchyNameSearch();
            };

            const clearInput = () => {
                props.treeStore.setSearchString("");
                loadUserServiceHierarchiesHelper(hierService, props.treeStore);
                props.treeStore.setTreeSearchResponse([]);
            };

            const performHierarchyNameSearch = debounce(() => {
                props.treeStore.setIsTreeLoading(true);

                if (props.treeStore.searchString !== "") {
                    hierService
                        .searchHierarchiesForUserAsync(
                            props.treeStore.searchString,
                        )
                        .then((res) => {
                            props.treeStore.setTreeSearchResponse(
                                res.childrenHierarchies,
                            );
                            props.treeStore.setIsTreeLoading(false);
                        });
                } else {
                    loadUserServiceHierarchiesHelper(
                        hierService,
                        props.treeStore,
                    );
                    props.treeStore.setTreeSearchResponse([]);
                }
            }, 500);

            return (
                <Observer>
                    {() => (
                        <Grid item xs={12} key={`search`}>
                            <AcxMainTextField
                                id="hierarchy-input-search"
                                value={props.treeStore.searchString}
                                placeholderText={
                                    props.treeStore.searchString
                                        ? ""
                                        : "Search hierarchies..."
                                }
                                onChange={handleInputChange}
                                endAdornment={
                                    <IconButton
                                        size="small"
                                        onClick={clearInput}
                                    >
                                        <ClearIcon fontSize="small" />
                                    </IconButton>
                                }
                                textareaRootStyles={{
                                    borderColor: "#E4E4E7",
                                }}
                            />
                        </Grid>
                    )}
                </Observer>
            );
        };

        const HierSelectionWarning: React.FC = () => (
            <Observer key={`warning`}>
                {() => (
                    <Grid
                        item
                        container
                        direction="row"
                        wrap="nowrap"
                        justifyContent="flex-start"
                        alignContent="center"
                        alignItems="center"
                        className={classes.headerWarningContainer}
                    >
                        <Grid item xs={2}>
                            <InfoIcon color="secondary" />
                        </Grid>
                        <Grid item xs={8}>
                            <h4 style={{ color: "#1E3A8A", fontSize: "11px" }}>
                                Children are not selected by default.
                                <br />
                                Please confirm selection.
                            </h4>
                        </Grid>
                        <Grid item container xs={2} justifyContent="flex-end">
                            <IconButton
                                size="small"
                                onClick={closeHeaderWarning}
                            >
                                <ClearIcon fontSize="small" color="secondary" />
                            </IconButton>
                        </Grid>
                    </Grid>
                )}
            </Observer>
        );

        const HierSelectionDetails: React.FC = () => (
            <Observer key={`search`}>
                {() => (
                    <Grid
                        item
                        container
                        justifyContent="space-between"
                        wrap="nowrap"
                        style={{ paddingTop: "8px" }}
                    >
                        <Typography className={classes.totalText}>
                            <b
                                style={{
                                    marginRight: "5px",
                                }}
                            >
                                Total selected:
                            </b>
                            {props.treeStore.selectedBranchIds.length}
                        </Typography>
                        {!props.viewOnly && (
                            <Grid
                                item
                                container
                                justifyContent="flex-end"
                                wrap="nowrap"
                            >
                                <Typography
                                    className={classes.selectAllText}
                                    onClick={
                                        hasSearchResults
                                            ? handleOnSelectAllResults
                                            : () => {}
                                    }
                                    style={{
                                        cursor: hasSearchResults
                                            ? "pointer"
                                            : "default",
                                        color: hasSearchResults
                                            ? "#3564D5"
                                            : "#AFB4BE",
                                    }}
                                >
                                    Select All Results
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                )}
            </Observer>
        );

        useEffect(() => {
            const local = RootStore().getStore(LocalStorage);
            const localStore = local.getLocalStore("AcxDefault");
            localStore.getItem(localKey).then((item: any) => {
                if (!item) {
                    localStore.setItem(localKey, 1);
                } else if (item < 6) {
                    localStore.setItem(localKey, item + 1);
                    props.treeStore.headerWarningHasRendered &&
                        setShowHeaderWarning(true);
                }
            });
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [props.treeStore.headerWarningHasRendered]);

        return (
            <Grid
                item
                container
                justifyContent="center"
                xs={12}
                className={classes.topContentContainer}
            >
                {!props.viewOnly && <HierSearchInput />}
                {authStore.canUserEdit("Hierarchy Category Search") && (
                    <>
                        <HierCategorySelection treeStore={props.treeStore} />
                        <HierCategorySelection
                            isSubCategoryComponent={true}
                            treeStore={props.treeStore}
                        />
                    </>
                )}
                <HierSelectionDetails />
                {showHeaderWarning && <HierSelectionWarning />}
            </Grid>
        );
    },
);

export default HierarchySelectorHeader;
