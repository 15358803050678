import {
    GridColTypeDef,
    GridComparatorFn,
    GridFilterInputValueProps,
    GridSortCellParams,
} from "@mui/x-data-grid-pro";
import AcxCalendarPopup from "components/UI/Calendar/AcxCalendarPopup";
import { get } from "lodash";
import moment, { Moment } from "moment";
import React from "react";
import {
    parseFromISO,
    standardDateFormat,
    standardDateTimeFormat,
} from "utils/DateTimeUtils";

export const dateComparator: GridComparatorFn = (
    v1,
    v2,
    param1: GridSortCellParams,
    param2: GridSortCellParams,
): number => {
    const row1 = param1.api.getRow(param1.id);
    const row2 = param2.api.getRow(param2.id);

    const p1 = get(row1, param1.field)?.toString();
    const p2 = get(row2, param2.field)?.toString();

    if (!p1) return 1;
    if (!p2) return -1;
    return p1.localeCompare(p2);
};

export const interactionDateComparator: GridComparatorFn = (
    v1,
    v2,
    param1: GridSortCellParams,
    param2: GridSortCellParams,
): number => {
    const row1 = param1.api.getRow(param1.id);
    const row2 = param2.api.getRow(param2.id);

    let value1 = get(row1, param1.field)?.toString();
    let value2 = get(row2, param2.field)?.toString();

    value1 = interactionDateHelper(value1);
    value2 = interactionDateHelper(value2);

    const p1 = standardDateFormat(parseFromISO(value1, false)) ?? "";
    const p2 = standardDateFormat(parseFromISO(value2, false)) ?? "";

    if (!p1) return 1;
    if (!p2) return -1;
    return p1.localeCompare(p2);
};

export const dateComparatorMoment: GridComparatorFn = (
    v1,
    v2,
    param1: GridSortCellParams,
    param2: GridSortCellParams,
): number => {
    const row1 = param1.api.getRow(param1.id);
    const row2 = param2.api.getRow(param2.id);

    const p1 = moment(get(row1, param1.field)?.toString());
    const p2 = moment(get(row2, param2.field)?.toString());

    if (!p1.isValid()) return 1;
    if (!p2.isValid()) return -1;
    if (p1.isSame(p2)) return 0;
    return p1.isBefore(p2) ? -1 : 1;
};

export const DateFilter = (props: GridFilterInputValueProps) => {
    const { item, applyValue } = props;
    const [dt, setDt] = React.useState(moment(item.value) ?? moment());

    const handleFilterChange = (d: Moment) => {
        setDt(d);
        applyValue({
            ...item,
            value: standardDateFormat(parseFromISO(d.toISOString(), false)),
        });
    };

    return (
        <AcxCalendarPopup
            inputLabel="Select Date"
            id="data-grid-date-filter"
            currentDate={dt}
            onSelect={handleFilterChange}
            popperPlacement="left"
        />
    );
};

export const dateTimeColumnType: GridColTypeDef = {
    valueFormatter: (_, row, column, apiRef) => {
        const value = row[column.field];
        return value ? standardDateTimeFormat(parseFromISO(value, false)) : "";
    },
    type: "date",
    valueGetter: (_, row, column) => {
        const value = row[column.field];
        return value instanceof Date ? value : new Date(value);
    },
};

export const dateColumnType: GridColTypeDef = {
    valueFormatter: (_, row, column, apiRef) => {
        const value = row[column.field];
        return value ? standardDateFormat(parseFromISO(value, false)) : "";
    },
    type: "date",
    valueGetter: (_, row, column) => {
        const value = row[column.field];
        return value instanceof Date ? value : new Date(value);
    },
};

export const dateIgnoreTimeColumnType: GridColTypeDef = {
    valueFormatter: (_, row, column, apiRef) => {
        const value = apiRef.current.getCellValue(row.id, column.field);
        return standardDateFormat(parseFromISO(value, false));
    },

    type: "date",
    valueGetter: (_, row, column) => {
        const value = row[column.field];
        return value instanceof Date ? value : new Date(value);
    },
};

export const interactionDateColumnType: GridColTypeDef = {
    valueFormatter: (_, row, column, apiRef) => {
        let value = get(row, column.field);
        value = interactionDateHelper(value);
        return value ? standardDateFormat(parseFromISO(value, true)) : "";
    },

    type: "date",
    valueGetter: (_, row, column) => {
        const value = get(row, column.field);
        return value instanceof Date ? value : new Date(value);
    },
};

export const interactionDateTimeColumnType: GridColTypeDef = {
    valueFormatter: (_, row, column, apiRef) => {
        let value = get(row, column.field);
        value = interactionDateHelper(value);
        return value ? standardDateTimeFormat(parseFromISO(value, false)) : "";
    },
    type: "date",
    valueGetter: (_, row, column) => {
        const value = row[column.field];
        return value instanceof Date ? value : new Date(value);
    },
};

export const interactionDateHelper = (
    value?: string | moment.Moment | Date,
): string | moment.Moment | undefined => {
    if (value) {
        value = moment.utc(value);

        // check if timestamp is 00:00:00 and set to noon to avoid day change on local conversion
        if (
            value.hours() === 0 &&
            value.minutes() === 0 &&
            value.seconds() === 0
        ) {
            value.set("hour", 12);
        }
        value = value.local();
    }
    return value;
};
