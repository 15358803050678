import { Container, Grid, Paper, Theme, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import SoundClipEditor from "components/SoundClipEditor/SoundClipEditor";
import AcxButton from "components/UI/AcxButton";
import AcxConfirmationDialog from "components/UI/AcxConfirmationDialog";
import AcxExpansion from "components/UI/AcxExpansion";
import { Observer, observer } from "mobx-react";
import { ClipUIModel } from "models/SoundClip";
import React from "react";
import { useNavigate } from "react-router-dom";
import { AuthStore } from "stores/AuthStore";
import useStyles from "Styles/Styles";
import { useStore } from "utils/useStore";
import { SAMPLE_AUDIO } from "../LuceneIndexSearch/LuceneIndexSearchStore";
import { ClassifierBuilderStore } from "./ClassifierBuilderStore";
import ClassifierBuilderClassifierManagement from "./ClassifierManagement/ClassifierBuilderClassifierManagementController";
import ClassifierBuilderEditorController from "./Editor/ClassifierBuilderEditorController";
import ClassifierBuilderReclassifyDialog from "./Reclassify/ClassifierBuilderReclassifyDialog";
import ClassifierBuilderReclassifyDialogStore from "./Reclassify/ClassifierBuilderReclassifyDialogStore";
import ClassifierBuilderSearchController from "./Search/ClassifierBuilderSearchController";
import { ClassifierBuilderSearchStore } from "./Search/ClassifierBuilderSearchStore";

export const styles = (theme: Theme) =>
    createStyles({
        root: {
            marginTop: "1.25rem",
            height: "calc(100% - 50px)",
            overflow: "scroll",
        },
        component: {
            height: "max-content",
            width: "100%",
            padding: "1em",
            marginBottom: "1.25rem",
        },
        link: {
            textDecoration: "none",
        },
        searchResultsWrapper: {
            height: "max-content",
        },
        editorLinkButtonText: {
            color: theme.palette.white.main,
        },
    });

// obsolete
const ClassifierBuilder = observer(() => {
    const classes = useStyles(styles);
    const store = useStore(ClassifierBuilderStore);
    const reclassifyStore = useStore(ClassifierBuilderReclassifyDialogStore);
    const authStore = useStore(AuthStore);
    const orgStore = authStore.orgStore;
    const searchStore = useStore(ClassifierBuilderSearchStore).searchStore;
    const navigate = useNavigate();

    React.useEffect(() => {
        if (orgStore && orgStore.selectedOrganization) {
            store.orgSelectStore.selectOrg(orgStore.selectedOrganization);
        }
    }, [orgStore, orgStore?.selectedOrganization, store]);

    const restrictReclassify =
        !authStore.isUserUltra() &&
        authStore.canUserView("Restrict Reclassify");

    return (
        <Container className={classes.root}>
            <AcxConfirmationDialog
                buttonsDisabled={
                    !authStore.isUserUltra() &&
                    authStore.canUserView("Restrict Downloads")
                }
                isOpen={searchStore.luceneAudioDialogStore.isOpen}
                title="Classifier Audio File"
                maxWidth="xl"
                fullWidth
                confirmButtonText="Download File"
                onClose={searchStore.closeLuceneDialog}
                onConfirm={searchStore.download}
                optionalButtons={
                    <AcxButton
                        loading={searchStore.getTaskLoading(SAMPLE_AUDIO)}
                        onClick={() => searchStore.sampleAudio(navigate)}
                        color="primary"
                    >
                        Sample Audio
                    </AcxButton>
                }
                content={
                    <Observer>
                        {() => (
                            <>
                                {searchStore.currentSoundClip && (
                                    <>
                                        <SoundClipEditor
                                            onAudioBufferReady={
                                                searchStore.setCurrentBuffer
                                            }
                                            index={0}
                                            id="lucene-listen-download"
                                            noMenu={false}
                                            clip={
                                                searchStore.currentSoundClip as ClipUIModel
                                            }
                                            throwWarning={false}
                                        />

                                        <Grid
                                            container
                                            item
                                            xs={12}
                                            justifyContent="center"
                                        >
                                            <Typography variant="caption">
                                                <strong>File Name: </strong>
                                                {
                                                    searchStore.currentSoundClip
                                                        .segmentName
                                                }
                                            </Typography>
                                        </Grid>
                                    </>
                                )}
                            </>
                        )}
                    </Observer>
                }
            />

            <ClassifierBuilderReclassifyDialog />

            <Paper className={classes.component}>
                <Grid container item xs={12}>
                    <Grid item xs={6}>
                        <AcxButton
                            fullWidth
                            color="primary"
                            onClick={reclassifyStore.dialog.open}
                            disabled={
                                store.orgSelectStore.orgId === undefined ||
                                store.orgSelectStore.orgId === null ||
                                restrictReclassify ||
                                !authStore.canUserEdit("Reclassify")
                            }
                            tooltip={
                                restrictReclassify
                                    ? "This role does not have access to reclassify."
                                    : ""
                            }
                        >
                            Reclassify
                        </AcxButton>
                    </Grid>
                </Grid>

                <Grid container item xs={12} style={{ margin: "1rem 0" }}>
                    <AcxExpansion
                        expanded={true}
                        header="Classifier Search Terms"
                        body={
                            <Grid
                                container
                                item
                                xs={12}
                                style={{ paddingTop: "0.5rem" }}
                            >
                                <ClassifierBuilderClassifierManagement />
                                <ClassifierBuilderEditorController />
                            </Grid>
                        }
                    />
                </Grid>

                <Grid container item xs={12}>
                    <ClassifierBuilderSearchController />
                </Grid>
            </Paper>
        </Container>
    );
});

export default React.memo(ClassifierBuilder);
