import { action, computed, makeObservable, observable } from "mobx";
import { BaseStore } from "stores/BaseStore";
import { TranscriptionService } from "services/TranscriptionService";
import type { TranscriptionSpeakerPayload } from "services/TranscriptionService";
import RootStore from "stores/RootStore";
import MessageStore from "components/ManagerInteractions/Stores/MessageStore";

export type TranscriptionSpeaker = Omit<
    TranscriptionSpeakerPayload,
    "isActive" | "audioMetadataId" | "id"
> & {
    id?: string;
    audioMetadataId?: string;
};

export type SpeakerChannelMap = Map<number, TranscriptionSpeakerPayload>;

export class SpeakerStore extends BaseStore {
    private readonly transcriptionService = new TranscriptionService();

    @observable amdId?: string;

    @observable private speakerPayload: TranscriptionSpeakerPayload[] = [];

    // example result: {"speaker 0": "Customer", "speaker 1": "Agent"};
    @observable
    speakerIdentificationModelResults?: Record<string, string>;

    constructor(amdId?: string) {
        super("SpeakerStore");
        makeObservable(this);
        this.setAmdId(amdId);
    }

    @action
    async setAmdId(amdId?: string) {
        this.amdId = amdId;
        await this.fetchSpeakerList();
    }

    @action
    async fetchSpeakerList() {
        if (this.amdId) {
            try {
                const speakers =
                    await this.transcriptionService.getTranscriptionSpeakers(
                        this.amdId,
                    );

                this.speakerPayload = speakers;
            } catch (e) {
                console.error(e);

                RootStore()
                    .getStore(MessageStore)
                    .logError("Error fetching transcription speakers");
            }
        } else {
            this.speakerPayload = [];
        }
    }

    @action
    updateSpeaker(speaker: TranscriptionSpeakerPayload) {
        const idx = this.speakerPayload.findIndex(
            (spk) => speaker.channel === spk.channel,
        );
        if (idx !== -1) {
            this.speakerPayload[idx] = speaker;
        } else {
            RootStore()
                .getStore(MessageStore)
                .logError(`Error updating speaker`);
        }
    }

    @action
    addSpeaker(speaker: TranscriptionSpeakerPayload) {
        const existing = this.speakerPayload.find(
            (spk) => speaker.channel === spk.channel && !speaker.id,
        );
        if (!existing) {
            this.speakerPayload.push(speaker);
        } else {
            RootStore()
                .getStore(MessageStore)
                .logError(`Error updating speaker`);
        }
    }

    @action
    async setSpeakerNameByChannel(channel: number, speaker: string) {
        const spk = this.speakerChannelMap.get(channel);
        if (spk?.id) {
            try {
                await this.transcriptionService
                    .updateTranscriptionSpeaker(spk.id, channel, speaker)
                    .then((response) => {
                        this.updateSpeaker(response);
                    });
            } catch (e) {
                console.error(e);

                RootStore()
                    .getStore(MessageStore)
                    .logError(`Error updating speaker`);
            }
        } else if (this.amdId) {
            try {
                await this.transcriptionService
                    .createTranscriptionSpeaker(this.amdId, channel, speaker)
                    .then((response) => {
                        this.addSpeaker(response);
                    });
            } catch (e) {
                console.error(e);

                RootStore()
                    .getStore(MessageStore)
                    .logError(`Error setting speaker`);
            }
        }
    }

    @computed
    get speakerChannelMap(): SpeakerChannelMap {
        const speakerMap = new Map<number, TranscriptionSpeakerPayload>();
        this.speakerPayload.forEach((speaker) => {
            speakerMap.set(speaker.channel, speaker);
        });
        return speakerMap;
    }

    @computed
    get predictedSpeakerChannelMap(): Map<number, string> {
        const predictedSpeakerChannelMap = new Map<number, string>();
        for (const key in this.speakerIdentificationModelResults) {
            try {
                const channelNum = Number(key.split(" ")[1]);
                const val = this.speakerIdentificationModelResults[key];

                predictedSpeakerChannelMap.set(channelNum, val);
            } catch (e) {
                console.error(JSON.stringify(e));
            }
        }
        return predictedSpeakerChannelMap;
    }
}
