import {
    alpha,
    Checkbox,
    Divider,
    FormControlLabel,
    IconButton,
    Paper,
    Theme,
    Tooltip,
    Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Grid } from "@mui/material";
import { observer } from "mobx-react";
import React, { useMemo } from "react";
import AcxToggleButton from "components/UI/AcxToggleButton";
import { useStore } from "utils/useStore";
import theme from "Theme/AppTheme";

import AcxDataGrid from "components/UI/AcxDataGrid/AcxDataGrid";
import {
    EEOverTimeID,
    ISignalsVizDefinition,
    HipaaCompliancePrevalenceOverTimeId,
    SEPrevalenceOverTimeID,
    TopicPrevalenceOverTimeID,
    SignalsDomain,
    ContactTypePrevalenceId,
    ContactTypePrevalenceOverTimeId,
    ContactTypePrevalenceAndEEId,
    EddyByContactTypeOverTimeId,
    TrendingTopicsID,
} from "../Models/SignalsModels";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AcxMenu, { StyledMenuLabel } from "components/UI/Menu/AcxMenu";
import SignalsSpotlight from "./SignalsSpotlight";
import SignalsReportStore from "../Store/SignalsReportStore";
import SignalsChart from "./SignalsChart";
import SignalsChartControls from "./SignalsChartControls";
import AcxDialog from "components/UI/Dialog/AcxDialog";
import AcxButton from "components/UI/AcxButton";
import { AcxViewSqlDialog } from "../../UI/AcxViewSqlDialog";

const useStyles = makeStyles((theme: Theme) => ({
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2),
        borderRadius: theme.spacing(1), // theme.shape.borderRadius,
        boxShadow: `0px 1px 8px 0px ${alpha(theme.palette.black.main, 0.05)}`,
    },
    chartSection: {
        paddingInline: theme.spacing(3),
        paddingBlock: theme.spacing(2),
    },
    unavailable: {
        margin: "1rem 0",
    },
    unavailableIconContainer: {
        padding: theme.spacing(1),
        outline: "1px solid",
        outlineColor: alpha(theme.palette.primary.main, 0.8),
        borderRadius: "9999rem",
        display: "flex",
        justifyContent: "center",
        width: "fit-content",
        aspectRatio: "1 / 1",
        position: "relative",
        margin: theme.spacing(2),
        "&::before": {
            content: "''",
            outline: "1px solid",
            outlineColor: alpha(theme.palette.primary.main, 0.5),
            borderRadius: "9999px",
            position: "absolute",
            inset: theme.spacing(-1),
        },
        "&::after": {
            content: "''",
            outline: "1px solid",
            outlineColor: alpha(theme.palette.primary.main, 0.2),
            borderRadius: "9999px",
            position: "absolute",
            inset: theme.spacing(-2),
        },
    },
    unavailableIcon: {
        fontSize: "144px",
        aspectRation: "1 / 1",
    },
    toggleButton: { height: "36px" },
    dataGridContainer: { height: "372px" },
    chartSubtitle: {
        color: theme.palette.neutral[600],
    },
    menuLabelText: {
        fontSize: "13px",
        letterSpacing: "0",
        lineHeight: "20px",
        color: "inherit",
    },
}));

type Props = ISignalsVizDefinition & { domainName: SignalsDomain };

const SignalsChartWrapper: React.FC<Props> = observer((props: Props) => {
    const {
        chartTitle,
        id,
        showComparePrevCheckbox,
        showPercentToggle,
        showDataGrid,
        dataGridStore,
        chartSubtitle,
        isTemporal,
        showSpotlight,
        data,
        renderMode,
        dataEndpoint,
        selectionType,
    } = props;
    const classes = useStyles();
    const store = useStore(SignalsReportStore);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [showConfirmDelete, setshowConfirmDelete] = React.useState(false);

    const validData = (d) => {
        return (d?.flatMap((datum) => datum).length ?? 0) > 1;
    };

    const isValidData = useMemo(() => validData(data), [data]);

    const showDataGridWarning =
        showDataGrid === true &&
        (dataGridStore?.selectedRowIds.length ?? 0) > store.maxSelectionCount &&
        dataEndpoint !== ContactTypePrevalenceOverTimeId &&
        dataEndpoint !== EddyByContactTypeOverTimeId;

    const comparePrevTooltipText = `Previous Period: ${store.comparePrevStart.format(
        "MM/DD/YY",
    )} - ${store.comparePrevEnd.format("MM/DD/YY")}`;

    function handleMoreMenuClick(event: React.MouseEvent<HTMLButtonElement>) {
        setAnchorEl(event.currentTarget);
    }

    function handleMoreMenuClose() {
        setAnchorEl(null);
    }

    function onDownloadChartImg() {
        const imgURI = store.chartWrappersByChartId[id]
            ?.getChart()
            ?.getImageURI();
        if (imgURI) {
            const a = document.createElement("a");
            a.setAttribute("style", "display:none");
            a.id = "AcxVizPng";
            a.style.display = "none";
            a.href = imgURI;
            a.download = `${id}.png`;
            a.click();
            a.remove();
        } else {
            store.messageStore.logWarning("Unable to download chart as .png");
        }

        handleMoreMenuClose();
    }

    function refreshChart() {
        store.renderedChartData.find((cd) => cd.id === id)!.shouldReload = true;
        store.getChartDataAndAISummary(id);
        handleMoreMenuClose();
    }

    const menuItems = [
        {
            id: "signals-export-as-png",
            props: {
                onClick: onDownloadChartImg,
                disabled: !isValidData,
            },
            label: (
                <StyledMenuLabel className={classes.menuLabelText}>
                    Export as PNG
                </StyledMenuLabel>
            ),
        },
        {
            id: "signals-refresh-chart",
            props: {
                onClick: refreshChart,
                disabled: store.getTaskLoading(id),
            },
            label: (
                <StyledMenuLabel className={classes.menuLabelText}>
                    Refresh
                </StyledMenuLabel>
            ),
        },
    ];

    const addToDashMenuItem = {
        id: "signals-add-to-dashboard",
        props: {
            onClick: () => {
                store.showSelectDashboard = true;
                store.selectedNewChart = {
                    dataEndpoint,
                    id,
                    chartTitle,
                    chartSubtitle,
                };
                handleMoreMenuClose();
            },
            disabled: store.getTaskLoading(id),
        },
        label: (
            <StyledMenuLabel className={classes.menuLabelText}>
                Pin to dashboard
            </StyledMenuLabel>
        ),
    };
    const removeFromDashMenuItem = {
        id: "signals-remove-from-dashboard",
        props: {
            onClick: () => {
                setshowConfirmDelete(true);
                handleMoreMenuClose();
            },
            disabled: store.getTaskLoading(id),
        },
        label: (
            <StyledMenuLabel className={classes.menuLabelText}>
                Delete
            </StyledMenuLabel>
        ),
    };
    const editChartMenuItem = {
        id: "signals-edit-chart",
        props: {
            onClick: async () => {
                store.populateChartConfigDrawer(id);
                handleMoreMenuClose();
            },
            disabled: store.getTaskLoading(id),
        },
        label: (
            <StyledMenuLabel className={classes.menuLabelText}>
                Report Configuration
            </StyledMenuLabel>
        ),
    };

    menuItems.push(addToDashMenuItem);
    if (props.domainName === "dashboard" && store.dashboardIsEditable) {
        menuItems.push(removeFromDashMenuItem);
        menuItems.push(editChartMenuItem);
    }

    const isOnlyPercentToggleInHeader =
        dataEndpoint === EEOverTimeID ||
        dataEndpoint === HipaaCompliancePrevalenceOverTimeId ||
        dataEndpoint === SEPrevalenceOverTimeID ||
        dataEndpoint === TopicPrevalenceOverTimeID ||
        dataEndpoint === ContactTypePrevalenceId ||
        dataEndpoint === ContactTypePrevalenceOverTimeId ||
        dataEndpoint === ContactTypePrevalenceAndEEId ||
        dataEndpoint === EddyByContactTypeOverTimeId;

    const showSQLQueries = !!props.sqlQuery;

    if (showDataGrid) {
        if (!store.dashboardIsEditable && props.domainName === "dashboard") {
            dataGridStore.checkboxSelection = false;
        } else if (dataEndpoint !== TrendingTopicsID) {
            dataGridStore.checkboxSelection = true;
        }
    }

    React.useEffect(() => {
        if (
            !dataGridStore.rows.length ||
            !showDataGrid ||
            (!store.dashboardIsEditable && props.domainName === "dashboard")
        )
            return;
        if (store.isPresentationMode) {
            dataGridStore.checkboxSelection = false;
            dataGridStore.rows = dataGridStore.rows.filter((r) =>
                dataGridStore.selectedRowIds.includes(r.id),
            );
        } else {
            if (dataEndpoint !== TrendingTopicsID) {
                dataGridStore.checkboxSelection = true;
            }

            if (selectionType === "Classifiers") {
                const unselectedClassifiers = store.userClassifiers.filter(
                    (c) => !dataGridStore.selectedRowIds.includes(c.id),
                );
                dataGridStore.rows.push(...unselectedClassifiers);
            } else if (selectionType === "Tags") {
                const unselectedTags = store.selectedQuestionByChartId[
                    id
                ]?.tags.filter(
                    (t) => !dataGridStore.selectedRowIds.includes(t.id),
                );
                dataGridStore.rows.push(...unselectedTags);
            } else if (
                selectionType === "Questions" ||
                selectionType === "Question"
            ) {
                const unselectedQuestions =
                    store.orgModules
                        .find(
                            (m) => m.id === store.selectedModuleIdByChartId[id],
                        )
                        ?.questions.filter(
                            (q) =>
                                !dataGridStore.selectedRowIds.includes(q.id!),
                        ) ?? [];
                dataGridStore.rows.push(...unselectedQuestions);
            } else if (selectionType === "Modules") {
                const unselectedModules = store.orgModules.filter(
                    (m) => !dataGridStore.selectedRowIds.includes(m.id),
                );
                dataGridStore.rows.push(...unselectedModules);
            } else if (selectionType === "Topics") {
                const unselectedTopics = store.topics.filter(
                    (t) => !dataGridStore.selectedRowIds.includes(t.id),
                );
                dataGridStore.rows.push(...unselectedTopics);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataGridStore, store.isPresentationMode]);

    return (
        <Paper className={classes.paper} id={id}>
            <Grid
                container
                item
                xs={12}
                justifyContent={"space-between"}
                className={classes.chartSection}
            >
                <Grid
                    container
                    item
                    xs={11}
                    direction={"column"}
                    justifyContent="center"
                >
                    <Typography variant="h2">
                        {chartTitle}
                        {isTemporal ? ` - ${store.aggregationLabel}` : ""}
                    </Typography>

                    <Typography className={classes.chartSubtitle}>
                        {chartSubtitle}
                    </Typography>
                </Grid>
                {!store.isPresentationMode && (
                    <Grid item alignSelf="center">
                        <IconButton
                            id={"signals-more-icon-" + id}
                            style={{ color: theme.palette.black.main }}
                            onClick={handleMoreMenuClick}
                            size="small"
                        >
                            <MoreVertIcon />
                        </IconButton>
                    </Grid>
                )}
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid container className={classes.chartSection}>
                {(props.domainName !== "dashboard" ||
                    store.dashboardIsEditable) &&
                    !store.isPresentationMode && (
                        <Grid
                            item
                            container
                            xs={12}
                            justifyContent={
                                isOnlyPercentToggleInHeader
                                    ? "flex-end"
                                    : "space-between"
                            }
                        >
                            <SignalsChartControls {...props} />
                            {(showComparePrevCheckbox || showPercentToggle) && (
                                <Grid
                                    container
                                    item
                                    xs={4}
                                    justifyContent="flex-end"
                                    alignItems="center"
                                >
                                    {showComparePrevCheckbox && (
                                        <Tooltip title={comparePrevTooltipText}>
                                            <FormControlLabel
                                                id={
                                                    "compare-to-previous-period-" +
                                                    id
                                                }
                                                label={
                                                    <Grid
                                                        container
                                                        alignItems="center"
                                                    >
                                                        Compare to previous
                                                        period
                                                    </Grid>
                                                }
                                                control={
                                                    <Checkbox
                                                        onChange={(e) => {
                                                            store.toggleComparePrevious(
                                                                id,
                                                                e.target
                                                                    .checked,
                                                            );
                                                        }}
                                                        checked={
                                                            store
                                                                .selectedComparePrevBools[
                                                                id
                                                            ] ?? false
                                                        }
                                                    />
                                                }
                                            />
                                        </Tooltip>
                                    )}
                                    {showPercentToggle && (
                                        <>
                                            {showSQLQueries && (
                                                <AcxViewSqlDialog
                                                    sqlStatements={[
                                                        props.sqlQuery,
                                                    ]}
                                                    title={`SQL Query for ${props.chartTitle}`}
                                                />
                                            )}
                                            <AcxToggleButton
                                                id={"signal-chart-toggle" + id}
                                                items={["%", "#"]}
                                                shrink
                                                onChange={(item) => {
                                                    if (!item) return;
                                                    store.toggleRenderMode(
                                                        item,
                                                        id,
                                                    );
                                                }}
                                                exclusive
                                                defaultValue={renderMode}
                                                style={{
                                                    height: "36px",
                                                    marginLeft:
                                                        theme.spacing(1),
                                                }}
                                                // alternateColor
                                            />
                                        </>
                                    )}
                                </Grid>
                            )}
                        </Grid>
                    )}

                <SignalsChart {...props} />

                {validData(data) && showSpotlight && (
                    <SignalsSpotlight chartId={id} />
                )}
                {showDataGridWarning && (
                    <Grid
                        item
                        container
                        style={{ color: theme.palette.error.main }}
                        alignSelf={"flex-start"}
                    >
                        No more than {store.maxSelectionCount} selections
                        allowed, data represents first {store.maxSelectionCount}{" "}
                        selections.
                    </Grid>
                )}
                {showDataGrid && dataGridStore && (
                    <Grid
                        container
                        className={classes.dataGridContainer}
                        id={"dg-" + id}
                    >
                        <AcxDataGrid dataGridStore={dataGridStore} />
                    </Grid>
                )}
            </Grid>
            <AcxMenu
                menuItems={menuItems}
                anchorElement={anchorEl}
                onMenuClose={handleMoreMenuClose}
            />
            <AcxDialog
                isOpen={showConfirmDelete}
                onClose={() => {
                    setshowConfirmDelete(false);
                }}
                title="Are you sure you want to delete this report?"
                subTitle="This action cannot be undone. Deleting this report will permanently remove all associated filters and selections."
                children={
                    <Grid container item xs={4}>
                        <AcxButton
                            fullWidth
                            // tooltip={drillEddySampleTooltip}
                            color="primary"
                            onClick={async () => {
                                await store.removeChartFromDashboard(id);
                            }}
                            containerStyle={{
                                width: "215px",
                            }}
                        >
                            Confirm
                        </AcxButton>
                    </Grid>
                }
            />
        </Paper>
    );
});

export default SignalsChartWrapper;
